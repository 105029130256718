import React from 'react'
import WeatherIndicatorImage from '../../../resources/weatherimage.png'
import SunImage from '../../../resources/sunimage.png'
import { ArrowRight, ChevronDown } from 'lucide-react'

const WeatherImageIndicator = () => {
  return (
    <div className='weather-cover'>
      <img src={WeatherIndicatorImage} className='weather-img' alt='weatherimage' />
      <div className='between absolute px-2 lg:px-5 py-3 '>
        <h1 className='center gap-1'>Weather for Lagos <ChevronDown size={20} /></h1>
        <h1>Today, 13:35PM</h1>
      </div>
      <div className='weathercontent'>
        <div className='center gap-2'>
          <img src={SunImage} className='w-[40px] h-[40px] ' alt='sunimage' />
          <div className='flex justify-center items-start flex-col'>
            <h1 className='text-2xl font-semibold'>31°C</h1>
            <p className='text-xs'>Sunny</p>
          </div>
        </div>
        <h1 className='center gap-1'>See full forecast<ArrowRight size={20} /></h1>
      </div>
    </div>
  )
}

export default WeatherImageIndicator
