import { ArrowUpDown, ChevronDown } from 'lucide-react'
import React from 'react'

const Filter = ({ data, title }) => {
  return (
    <div className='separator px-0 lg:px-3'>
      <h1 className='text-sm lg:text-lg w-[500px] font-semibold'>{title}</h1>
      {data.length === 0
        ? (
            ''
          )
        : (
          <div className='dropdown dropdown-hover p-0'>
            <div
              tabIndex={0}
              role='button'
              className='w-[150px] text-sm p-3 center gap-1'
            >
              <ArrowUpDown size={20} />
              Sort by <ChevronDown size={20} />
            </div>
            <div
              tabIndex={0}
              className='dropdown-content menu rounded-box sort shadow'
            >
              <h1 className='sort-hover'>New - Old</h1>
              <h1 className='sort-hover'>Old - New</h1>
              <h1 className='sort-hover'>Alphabetical</h1>
            </div>
          </div>
          )}
    </div>
  )
}

export default Filter
