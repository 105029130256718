import { Plus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import StockDataTable from '../../StockPage/StockDataTable/StockDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const StockPage = () => {
  const [data, setData] = useState([])
  const { user } = useAuth()

  const { mutate } = useBackendService(
    `/stock_records/parent/${user.farmId}`,
    'get',
    {
      onSuccess: data => {
        setData(data.data)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )
  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        title='Stock'
        btntext='Add new stock report'
        link='/stocks/new-stock'
        icon={<Plus size={20} />}
      />
      <StockDataTable data={data} />
    </div>
  )
}

export default StockPage
