import React, { useEffect, useMemo, useState } from 'react'
import Map, { Marker } from 'react-map-gl'
import { FarmProducts } from '../../../data/static'
import { Search, X } from 'lucide-react'
import Filter from '../Filter/Filter'
import PayWall from '../PayWall/PayWall'
import { useAuth } from '../../../../config/contexts/authProvider'

function RegularMap () {
  const { user } = useAuth()
  const [viewPort, setViewPort] = useState({
    latitude: 9,
    longitude: 8,
    zoom: 6
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [farmProducts, setFarmProducts] = useState(FarmProducts)

  const locations = FarmProducts.map(item => ({
    id: item,
    label: item.state
  }))

  const uniqueLocations = useMemo(() => {
    return [...new Set(locations)]
  }, [locations])

  const products = FarmProducts.map(item => ({
    id: item,
    label: item.product
  }))

  const uniqueProducts = useMemo(() => {
    return [...new Set(products)]
  }, [products])

  useEffect(() => {
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }

    setFarmProducts(prevProducts =>
      prevProducts.map(product => ({
        ...product,
        bg: getRandomColor()
      }))
    )
  }, [])

  return (
    <div className=''>
      {user.plan === 'Pro'
        ? (
          <div className='flex flex-col-reverse lg:flex-col gap-[30px] lg:px-5 py-5 lg:py-10 w-full h-full'>
            <div className='flex flex-col-reverse gap-[15px] px-3 lg:px-0 lg:flex-col lg:gap-30px '>
              <div className=' flex flex-row justify-between  '>
                <div className='flex flex-[1] items-center border rounded-lg gap-1 px-3'>
                  <Search size={20} color='#DDDDDD' />
                  <input
                    className=' bg-transparent appearance-none border-none p-2 placeholder:text-[12px] placeholder:lg:text-[16px] text-[12px] lg:text-[16px]  text-gray-900 placeholder-[#888888] focus:outline-none focus:ring-0'
                    type='search'
                    placeholder='Search Crops, Livestock, Location'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </div>
                <Filter location={uniqueLocations} products={uniqueProducts} />
              </div>
              <div className=' flex flex-col  gap-4 capitalize '>
                <p className=' text-black font-[700] text-[14px] lg:text-[16px] '>
                  Currently displayed
                </p>
                <div className='gap-5 grid grid-cols-4 lg:grid-cols-7'>
                  {farmProducts.map((product, index) => (
                    <div className=' flex gap-1 items-center ' key={index}>
                      <div
                        className=' w-[10px] h-[10px] rounded-full '
                        style={{ backgroundColor: product.bg }}
                      />
                      <p className=' text-black font-[500] text-[12px] lg:text-[16px] '>
                        {' '}
                        {product.product}{' '}
                      </p>
                      <X size={14} className=' text-black font-[500]' />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=' w-full h-[60%] lg:h-[600px] '>
              <Map
                style={{ width: '100%', height: '100%' }}
                initialViewState={viewPort}
                onMove={viewPort => {
                  setViewPort(viewPort)
                }}
                mapStyle='mapbox://styles/mapbox/streets-v9'
                mapboxAccessToken='pk.eyJ1IjoidGlkYWwtbGluayIsImEiOiJjbHo2cmJkd2UwMWxtMnNzNWdqcjd6MGdlIn0.dUx1-wpG4c5lc5F2eFz5zg'
              >
                {FarmProducts.map((product, index) => (
                  <Marker
                    key={index}
                    longitude={product.long}
                    latitude={product.lat}
                  >
                    <div
                      className=' w-[14px] h-[14px] rounded-full'
                      style={{ backgroundColor: product.bg }}
                    />
                  </Marker>
                ))}
              </Map>
            </div>
          </div>
          )
        : (
          <RegularView />
          )}
    </div>
  )
}

const RegularView = () => (
  <div className=' w-full h-[90vh] items-center justify-center'>
    <PayWall text='Upgrade to the Pro plan to access detailed packaging options, market trends and current market price' />
  </div>
)

export default RegularMap
