import React from 'react'
import './Index.css'

const Inventory = ({ borderColor, title, amount = 0 }) => {
  return (
    <div className='inventory-container w-[48%] bg-[#F9F9F9] p-4 rounded-lg '>
      <div className='inventory-content'>
        <div
          className=' h-[full] w-1 rounded-lg '
          style={{ backgroundColor: borderColor }}
        />
        <div className='flex flex-col gap-1 lg:gap-3 '>
          <p className=' text-[14px] lg:text-[18px] text-black font-[500 ] '>
            {title}
          </p>
          <p className=' text-[10px] hidden lg:flex lg:text-[16px] text-grey font-[400] '>
            All
          </p>
        </div>
      </div>
      <p className=' text-[12px] lg:text-[28px] text-black font-[500] pl-1 lg:pl-4 '>
        {amount}
      </p>
    </div>
  )
}

export default Inventory
