import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import { useNavigate } from 'react-router-dom'
import { Columns } from '../ProductsTableColumn/Columns'

function ProductsDataTable ({ data }) {
  const navigate = useNavigate()

  const handleRowClick = product => {
    navigate(`/admin-products/${product.id}`)
  }
  return (
    <div>
      <DataTable
        data={data}
        columns={Columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={require('../../../../resources/farmemptyimage.png')}
            text='No product yet'
            desc='Click the add product catalog button above to add product catalogs'
          />
        }
        rowClick={handleRowClick}
      />
    </div>
  )
}

export default ProductsDataTable
