import React from 'react'
import './Modal.css'
const Modal = ({ show, onClose, product }) => {
  if (!show) {
    return null
  }
  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <span className='close-icon text-red-400' onClick={onClose}>&times;</span>
        <div className='w-full  bg-white rounded-lg text-[#515151] ts'>
          <h1 className='font-semibold p-[2%]'>Care instructions:</h1>
          <hr />
          {product.careInstructions.map((item, index) => (
            <React.Fragment key={index}>
              <p className='p-[2%]'>{item}</p>
              <hr className='opacity-15' />
            </React.Fragment>
          ))}

        </div>
      </div>
    </div>
  )
}

export default Modal
