import { ChevronLeft, ChevronRight } from 'lucide-react'
import React from 'react'

const Pagination = ({ next, nextpagedisabled, previouspagedisabled, previous, totalPages, current }) => {
  return (

    <div className='separator'>

      <div className='pag-cover'>
        <button className='inline-flex size-8 items-center justify-center text-gray-900 rtl:rotate-180' onClick={previous} disabled={previouspagedisabled}>

          <ChevronLeft size={20} />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <h1
            key={index}
            className={`number ${index + 1 === current ? 'bg-darkgreen text-white' : ''}`}
          >
            {index + 1}
          </h1>
        ))}
        <button className='join-item w-6 h-6 text-xl center btn-disabled'>...</button>

        <button className='inline-flex size-8 items-center justify-center  text-gray-900 rtl:rotate-180' onClick={next} disabled={nextpagedisabled}>

          <ChevronRight size={20} />
        </button>

      </div>

      <div className='center text-sm text-black gap-1 lg:flex'>
        <h1>Page</h1>
        <h1 className='number-green'>
          {current}
        </h1>
        <h1>of</h1>
        <h1>{totalPages}</h1>

      </div>
    </div>

  )
}

export default Pagination
