const productDataTable = [
  {
    name: 'Cattle',
    type: 'Livestock',
    unit: 'Kg',
    manufacturer: '_ _ _',
    status: 'Active',
    stock: '78',
    action: '. . .'
  },
  {
    name: 'Chicken Feed',
    type: 'Feed',
    unit: 'Kg',
    manufacturer: 'Sheldon Farms',
    status: 'Active',
    stock: '34',
    action: '. . .'
  },
  {
    name: 'Yam',
    type: 'Supplies',
    unit: 'Kilo',
    manufacturer: 'Ross Farms',
    status: 'Active',
    stock: '28',
    action: '. . .'
  },
  {
    name: 'Rice',
    type: 'Crops',
    unit: 'Ton',
    manufacturer: '_ _ _',
    status: 'Active',
    stock: '34',
    action: '. . .'
  },
  {
    name: 'Rake',
    type: 'Equipment',
    unit: 'Ounce',
    manufacturer: '_ _ _',
    status: 'Inactive',
    stock: '10',
    action: '. . .'
  }
]

const productNameInput = [
  { value: 'apple', label: 'Apple' },
  { value: 'baboon', label: 'Baboon' },
  { value: 'chicken feed', label: 'Chicken feed' },
  { value: 'dawa', label: ' Dawa' },
  { value: 'fish', label: 'Fish' },
  { value: 'goat', label: 'Goat' }
]

const productUnitInput = [
  { value: 'liters', label: 'Liters' },
  { value: 'kg', label: 'kg' },
  { value: 'g', label: 'g' },
  { value: 'ml', label: 'ML' },
  { value: 'kilo', label: 'Kilo' },
  { value: 'ton', label: 'Ton' },
  { value: 'ounces', label: 'Ounces' }
]

const productInputStatus = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' }
]

const agribusinessProductTypes = [
  { value: 'livestock', label: 'Livestock' },
  { value: 'poultry', label: 'Poultry' },
  { value: 'aquaculture', label: 'Aquaculture' },
  { value: 'horticulture', label: 'Horticulture' },
  { value: 'cash_crops', label: 'Cash Crops' },
  { value: 'arable_crops', label: 'Arable Crops' },
  { value: 'dairy', label: 'Dairy' },
  { value: 'forestry', label: 'Forestry' },
  { value: 'agroprocessing', label: 'Agroprocessing' },
  { value: 'plantation', label: 'Plantation' },
  { value: 'apiary', label: 'Apiary (Beekeeping)' },
  { value: 'greenhouse_farming', label: 'Greenhouse Farming' },
  { value: 'hydroponics', label: 'Hydroponics' },
  { value: 'organic_farming', label: 'Organic Farming' },
  { value: 'agribusiness_services', label: 'Agribusiness Services' }
]

const RegularDashboardContent = [
  {
    name: 'home',
    icon: require('../resources/homeicon.png'),
    orange: require('../resources/homeactive.png'),
    link: '/home',
    text: 'Hello, welcome👋',
    subtext: 'Manage your agribusiness efficiently'
  },
  {
    name: 'catalog',
    icon: require('../resources/catalog.png'),
    orange: require('../resources/catalogactive.png'),
    link: '/catalog',
    text: 'Catalog',
    subtext:
      'See all agribusiness related products and how best to manage them.'
  },
  {
    name: 'invest',
    icon: require('../resources/invest.png'),
    orange: require('../resources/investactive.png'),
    link: '/invest',
    text: 'Invest',
    subtext: 'Check out investment plans for you'
  },

  {
    name: 'Agri news',
    icon: require('../resources/agrinews.png'),
    orange: require('../resources/agrinewsactive.png'),
    link: '/agrinews',
    text: 'Agri news',
    subtext: 'Keep up with the latest happenings in the agribusiness world'
  },

  {
    name: 'sales',
    icon: require('../resources/sales.png'),
    orange: require('../resources/salesactive.png'),
    link: '/sales',
    text: 'Sales & Supply management',
    subtext: 'For quick access to specific products information.'
  },

  {
    name: 'map',
    icon: require('../resources/map.png'),
    orange: require('../resources/mapactive.png'),
    link: '/map',
    text: 'Map',
    subtext: 'Check out what & where to plant or raise livestock'
  }
]

const RegularAdminDashboardContent = [
  {
    name: 'home',
    icon: require('../resources/homeicon.png'),
    orange: require('../resources/homeactive.png'),
    link: '/admin-home',
    text: 'Home'
  },
  {
    name: 'applications',
    icon: require('../resources/catalog.png'),
    orange: require('../resources/catalogactive.png'),
    link: '/admin-business-applications',
    text: 'Applications'
  },
  {
    name: 'users',
    icon: require('../resources/person.png'),
    orange: require('../resources/personactive.png'),
    link: '/admin-users',
    text: 'Users'
  },
  {
    name: 'investments',
    icon: require('../resources/invest.png'),
    orange: require('../resources/investactive.png'),
    link: '/admin-investments',
    text: 'Investments'
  },
  {
    name: 'products',
    icon: require('../resources/product.png'),
    orange: require('../resources/greenproducticon.png'),
    link: '/admin-products',
    text: 'Product Catalog'
  },
  {
    name: 'news',
    icon: require('../resources/agrinews.png'),
    orange: require('../resources/agrinewsactive.png'),
    link: '/admin-news',
    text: 'News'
  },
  {
    name: 'tips',
    icon: require('../resources/tips.png'),
    orange: require('../resources/tipsactive.png'),
    link: '/admin-tips',
    text: 'Tips'
  },

  {
    name: 'calender',
    orange: require('../resources/calender.png'),
    icon: require('../resources/notactivecalender.png'),
    link: '/admin-requests',
    text: 'Calender'
  }
  // {
  //   name: 'requests',
  //   icon: require('../resources/requests.png'),
  //   orange: require('../resources/tipsactive.png'),
  //   link: '/admin-requests',
  //   text: 'Requests'
  // },
  // {
  //   name: 'settings',
  //   icon: require('../resources/tips.png'),
  //   orange: require('../resources/tipsactive.png'),
  //   link: '/admin-tips',
  //   text: 'Tips'
  // }
]

const foryouproduct = [
  {
    id: 1,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 2,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-07-22T00:00:00.000Z'
  },
  {
    id: 3,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-07-22T00:00:00.000Z'
  },
  {
    id: 4,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-10-22T00:00:00.000Z'
  }
] // <--- Added missing comma here

const news = [
  {
    id: 1,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 2,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 3,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/corn.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 4,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/corn.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 5,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/cow.png'),
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    id: 6,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../resources/corn.png'),
    date: '2024-02-22T00:00:00.000Z'
  }
]
const agriproduct = {
  id: 1,
  title: 'The Growth of Billie the Cow',
  description:
    'Lorem ipsum dolor sit amet consectetur. Viverra elementum accumsan in feugiat fusce erat tortor ac Fames et cursus habitant urna convallis ultrices aliquet enim hendrerit. Viverra diam quam non gravida sed in. Adipiscing faucibus nisl consectetur et sit est. Enim risus habitant dignissim nec fringilla habitasse. Purus amet non massa odio mus quam fermentum. Sit sed sit nunc enim. Est sem aliquam gravida turpis. Pretium nunc urna ut dui ut diam vulputate justo. Libero facilisi turpis est lacus diam nulla. A turpis consectetur congue gravida arcu. Tempor lectus integer enim gravida. Ac pellentesque massa morbi magna mauris et et. Sapien cras sit faucibus feugiat eu parturient. Leo velit tristique tempor pharetra. Tincidunt tellus dis id ipsum duis fermentum luctus augue. Viverra massa volutpat elit leo ipsum eu tellus praesent. Adipiscing fringilla interdum eu pellentesque id nibh odio suscipit. Tellus sit ultrices aliquet ut. Fames et cursus habitant urna convallis ultrices aliquet enim hendrerit. Viverra diam quam non gravida sed in. Adipiscing faucibus nisl consectetur et sit est. Enim risus habitant dignissim nec fringilla habitasse. Purus amet non massa odio mus quam fermentum. Sit sed sit nunc enim.',
  img: require('../resources/cow.png'),
  date: '2024-10-22T00:00:00.000Z'
}

const hotnews = [
  {
    title: 'Trending',
    img: require('../resources/phonenews.png'),
    name: 'The Growth of Billie the Cow',
    date: '2024-02-22T00:00:00.000Z'
  },
  {
    title: 'Trending',
    img: require('../resources/phonenews.png'),
    name: 'The Growth of Billie the Cow',
    date: '2024-07-02T00:00:00.000Z'
  },
  {
    title: 'Livestock',
    img: require('../resources/phonenews.png'),
    name: 'The Growth of Billie the Cow',
    date: '2024-02-28T00:00:00.000Z'
  }
]

const InvestmentPageContent = {
  id: 1,
  img: require('../resources/cow.png'),
  product: 'Cattle Cattle',
  manufacturer: 'By Dangote Groups',
  amount: '10,000',
  amountPercentage: '58%',
  about:
    'Tomatoes are a popular and versatile vegetable, perfect for a wide range of culinary uses. They are rich in vitamins A and C, as well as antioxidants like lycopene, which can help reduce the risk of chronic diseases.',
  expextedReturns: '10.5% in 5 months',
  investmentType: 'Fixed Income',
  closingDate: '17th of March 2024',
  maturityDate: '17th of December 2024',
  payoutType: 'Capital + Profit',
  unitType: 'Unit call be sold at any time',
  repaymentPlan:
    'Water your crops early in the morning or late in the evening to minimize evaporation. Use drip irrigation systems to provide consistent moisture directly to the roots.',
  investmentRisk:
    'Water your crops early in the morning or late in the evening to minimize evaporation. Use drip irrigation systems to provide consistent moisture directly to the roots. Use a balanced fertilizer once a month, or incorporate compost into the soil for added nutrients.Remove the lower leaves and suckers (small shoots that grow between the stem and a branch) to improve air circulation and plant health.'
}
const TotalRevenueSeries = {
  labels: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  datasets: [
    {
      label: 'This year',
      data: [474, 585, 57, 506, 61, 58, 63, 60, 66, 2000, 1000, 500],
      backgroundColor: '#144618'
    }
  ]
}

const OtherSeries = {
  labels: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  datasets: [
    {
      label: 'This month',
      data: [760, 850, 1010, 980, 870, 1005, 910, 114, 94, 2000, 1000, 500],
      backgroundColor: '#144618'
    }
  ]
}
const salesproducts = [
  {
    id: 1,
    title: 'tomatoes',
    img: require('../resources/tomatoes.png')
  },
  {
    id: 2,
    title: 'corns',
    img: require('../resources/corn.png')
  }
]
const salesproductsdetails = {
  title: 'tomatoes',
  img: require('../resources/tomatoes.png'),
  place: ['Abuja', 'Ibadan', 'Ogbomosho', 'Ilorin', 'Kaduna'],
  sell: ['Benin', 'Awka', 'Enugu', 'Cross Rivers'],
  storage:
    'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.',
  marketprice:
    'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.',
  saleschannel:
    'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.',
  packagingmethod:
    'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.',
  logistic:
    'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.'
}
const FarmProducts = [
  {
    product: 'yam',
    state: 'Ondo',
    long: '5.5500',
    lat: '7.3333',
    bg: '#F76C6C'
  },
  {
    product: 'rice',
    state: 'Ebonyi',
    long: '8.0833',
    lat: '6.0333',
    bg: '#F9D67A'
  },
  {
    product: 'cassava',
    state: 'Imo',
    long: '7.0333',
    lat: '5.5667',
    bg: '#64FFDA'
  },
  {
    product: 'maize',
    state: 'Jigawa',
    long: '9.9667',
    lat: '11.7667',
    bg: '#A8CABA'
  },
  {
    product: 'millet',
    state: 'Plateau',
    long: '8.7667',
    lat: '9.2333',
    bg: '#615CFD'
  },
  {
    product: 'sorghum',
    state: 'Borno',
    long: '13.2333',
    lat: '11.9667',
    bg: '#FFE4E1'
  },
  {
    product: 'soybeans',
    state: 'Nasarawa',
    long: '8.7833',
    lat: '8.5833',
    bg: '#FFCE9E'
  },
  {
    product: 'cocoa',
    state: 'Oyo',
    long: '3.9167',
    lat: '8.4167',
    bg: '#FFAB91'
  },
  {
    product: 'palm',
    state: 'Akwa Ibom',
    long: '7.8333',
    lat: '5.0000',
    bg: '#FF8A65'
  },
  {
    product: 'banana',
    state: 'Edo',
    long: '6.3333',
    lat: '6.4667',
    bg: '#FF6A46'
  },
  {
    product: 'beans',
    state: 'Kogi',
    long: '7.2667',
    lat: '7.3333',
    bg: '#F36C60'
  },

  {
    product: 'tomato',
    state: 'Kaduna',
    long: '7.8833',
    lat: '10.2833',
    bg: '#F9A770'
  },

  {
    product: 'onion',
    state: 'Zamfara',
    long: '6.1333',
    lat: '12.2833',
    bg: '#F7E095'
  },

  {
    product: 'maize',
    state: 'Niger',
    long: '6.4833',
    lat: '9.3833',
    bg: '#F2F2F2'
  },

  {
    product: 'mango',
    state: 'Ogun',
    long: '3.3500',
    lat: '7.2167',
    bg: '#D9F7BE'
  },

  {
    product: 'pineapple',
    state: 'Abia',
    long: '7.9000',
    lat: '5.3667',
    bg: '#B0FEBF'
  }
]
const adminNewsData = [
  {
    id: 1,
    title: 'Article 1',
    author: 'John Doe',
    createdby: '2022-01-01',
    createdat: '2022-01-01',
    active: true
  },
  {
    id: 2,
    title: 'Article 3',
    author: 'John Doe',
    createdby: '2022-01-01',
    createdat: '2022-01-01',
    active: false
  }
]

const userData = [
  {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phonenumber: '1234567890',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Jane Doe',
    email: 'jane.doe@example.com',
    phonenumber: '0987654321',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Bob Smith',
    email: 'bob.smith@example.com',
    phonenumber: '5555555555',
    status: 'inactive',
    action: 'View'
  },
  {
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    phonenumber: '9999999999',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Tom Wilson',
    email: 'tom.wilson@example.com',
    phonenumber: '1111111111',
    status: 'inactive',
    action: 'View'
  },
  {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phonenumber: '1234567890',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Jane Doe',
    email: 'jane.doe@example.com',
    phonenumber: '0987654321',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Bob Smith',
    email: 'bob.smith@example.com',
    phonenumber: '5555555555',
    status: 'inactive',
    action: 'View'
  },
  {
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    phonenumber: '9999999999',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Tom Wilson',
    email: 'tom.wilson@example.com',
    phonenumber: '1111111111',
    status: 'inactive',
    action: 'View'
  },
  {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phonenumber: '1234567890',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Janen Doe',
    email: 'jane.doe@example.com',
    phonenumber: '0987654321',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Bob Smith',
    email: 'bob.smith@example.com',
    phonenumber: '5555555555',
    status: 'inactive',
    action: 'View'
  },
  {
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    phonenumber: '9999999999',
    status: 'active',
    action: 'View'
  },
  {
    name: 'Tom Wilson',
    email: 'tom.wilson@example.com',
    phonenumber: '1111111111',
    status: 'inactive',
    action: 'View'
  }
]

const investmentData = [
  {
    id: 1,
    title: 'Refined Cotton Fund',
    createdBy: 'John Doe',
    createdAt: '1/1/2022',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 2,
    title: 'Cocoa Trading',
    createdBy: 'Jane Doe',
    createdAt: '2/1/2022',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 3,
    title: 'Wheat Export Partners',
    createdBy: 'John Smith',
    createdAt: '3/1/2022',
    status: 'Cancelled',
    action: 'View'
  },
  {
    id: 4,
    name: 'Energy Infrastructure Trust',
    createdBy: 'Sara White',
    createdAt: '1/5/2022',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 5,
    name: 'Global Timber Fund',
    createdBy: 'Jack Wilson',
    createdAt: '1/15/2022',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 6,
    name: 'Fixed Income Opportunities LP',
    createdBy: 'Tom Brown',
    createdAt: '2/10/2022',
    status: 'Cancelled',
    action: 'View'
  },
  {
    id: 7,
    name: 'Diversified Commodities Basket',
    createdBy: 'Michael Scott',
    createdAt: '3/1/2022',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 8,
    name: 'Emerging Markets Equity Fund',
    createdBy: 'Pam Beesly',
    createdAt: '1/30/2022',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 9,
    name: 'Private Equity Secondaries LP',
    createdBy: 'Jim Halpert',
    createdAt: '2/15/2022',
    status: 'Cancelled',
    action: 'View'
  },
  {
    id: 10,
    name: 'Technology Venture Capital Fund',
    createdBy: 'Dwight Shrute',
    createdAt: '3/5/2022',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 11,
    name: 'Healthcare Real Estate Trust',
    createdBy: 'Andy Bernard',
    createdAt: '1/20/2022',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 12,
    name: 'Global Agriculture LP',
    createdBy: 'Ryan Howard',
    createdAt: '2/1/2022',
    status: 'Cancelled',
    action: 'View'
  }
]
const adminTipsData = [
  {
    id: 1,
    title: 'Tromp Group',
    createdby: 'Damilola Jegede',
    createdat: '2022-01-01',
    status: 'approved',
    active: true
  },
  {
    id: 2,
    title: 'Lemke - Yundt',
    createdby: 'Idris Garba',
    createdat: '2022-01-01',
    status: 'pending',
    active: false
  },
  {
    id: 3,
    title: 'Lemke - Yundt',
    createdby: 'Idris Garba',
    createdat: '2022-01-01',
    status: 'rejected',
    active: false
  }
]
const productData = [
  {
    id: 1,
    product: 'Banana',
    createdAt: '1/1/2022',
    createdBy: 'John Doe',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 2,
    product: 'Mango',
    createdAt: '2/15/2022',
    createdBy: 'Jane Doe',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 3,
    product: 'Apple',
    createdAt: '3/3/2022',
    createdBy: 'Bob Smith',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 4,
    product: 'Orange',
    createdAt: '4/11/2022',
    createdBy: 'Sara Williams',
    status: 'Cancelled',
    action: 'View'
  },
  {
    id: 5,
    product: 'Pineapple',
    createdAt: '5/20/2022',
    createdBy: 'John Doe',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 6,
    product: 'Grapes',
    createdAt: '6/1/2022',
    createdBy: 'Jane Doe',
    status: 'Pending',
    action: 'View'
  },
  {
    id: 7,
    product: 'Watermelon',
    createdAt: '7/15/2022',
    createdBy: 'Bob Smith',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 8,
    product: 'Cherry',
    createdAt: '8/21/2022',
    createdBy: 'Sara Williams',
    status: 'Cancelled',
    action: 'View'
  },
  {
    id: 9,
    product: 'Blueberry',
    createdAt: '9/10/2022',
    createdBy: 'John Doe',
    status: 'Approved',
    action: 'View'
  },
  {
    id: 10,
    product: 'Strawberry',
    createdAt: '10/31/2022',
    createdBy: 'Jane Doe',
    status: 'Pending',
    action: 'View'
  }
]
const staffData = [
  {
    name: 'John Doe',
    email: 'john@example.com',
    number: '09176320873',
    role: 'Manager',
    location: 'Lagos'
  },
  {
    name: 'Jane Smith',
    email: 'jane.smith@example.com',
    number: '09176320874',
    role: 'Assistant Manager',
    location: 'Abuja'
  },
  {
    name: 'Michael Johnson',
    email: 'michael.johnson@example.com',
    number: '09176320875',
    role: 'Supervisor',
    location: 'Port Harcourt'
  },
  {
    name: 'Emily Davis',
    email: 'emily.davis@example.com',
    number: '09176320876',
    role: 'Team Lead',
    location: 'Enugu'
  },
  {
    name: 'David Wilson',
    email: 'david.wilson@example.com',
    number: '09176320877',
    role: 'Senior Staff',
    location: 'Kano'
  },
  {
    name: 'Sophia Brown',
    email: 'sophia.brown@example.com',
    number: '09176320878',
    role: 'Junior Staff',
    location: 'Ikeja'
  }
]
const agriBusinessRoles = [
  { value: 'administrator', label: 'Administrator' },
  { value: 'stock-keeper', label: 'Stock-keeper' },
  { value: 'approver', label: 'Approver' },
  { value: 'farm_manager', label: 'Farm Manager' },
  { value: 'supervisor', label: 'Supervisor' },
  { value: 'agronomist', label: 'Agronomist' },
  { value: 'veterinarian', label: 'Veterinarian' },
  { value: 'production_manager', label: 'Production Manager' },
  { value: 'quality_control_officer', label: 'Quality Control Officer' },
  { value: 'logistics_coordinator', label: 'Logistics Coordinator' },
  { value: 'marketing_specialist', label: 'Marketing Specialist' },
  { value: 'procurement_officer', label: 'Procurement Officer' },
  { value: 'warehouse_manager', label: 'Warehouse Manager' },
  { value: 'accountant', label: 'Accountant' },
  { value: 'operations_manager', label: 'Operations Manager' },
  { value: 'irrigation_specialist', label: 'Irrigation Specialist' },
  { value: 'animal_health_technician', label: 'Animal Health Technician' },
  { value: 'tractor_operator', label: 'Tractor Operator' },
  { value: 'agricultural_engineer', label: 'Agricultural Engineer' },
  { value: 'farm_worker', label: 'Farm Worker' },
  { value: 'sales_representative', label: 'Sales Representative' },
  { value: 'environmental_officer', label: 'Environmental Officer' }
]

const staffLocationOptions = [
  { value: 'lagos', label: 'Lagos' },
  { value: 'abuja', label: 'Abuja' },
  { value: 'port_harcourt', label: 'Port Harcourt' },
  { value: 'enugu', label: 'Enugu' },
  { value: 'kano', label: 'Kano' },
  { value: 'ikeja', label: 'Ikeja' },
  { value: 'benin_city', label: 'Benin City' },
  { value: 'calabar', label: 'Calabar' },
  { value: 'warri', label: 'Warri' },
  { value: 'asaba', label: 'Asaba' }
]
const notificationMessage = [
  {
    id: 'bafe80ce-7090-40fe-9aa6-0087581cd586',
    user: 'Adewole Johnson',
    contentId: 'de18e480-18cc-4cd0-bd45-0ac2e9b79ff1',
    title: 'Request for chicken feed',
    type: 'request',
    read: false,
    createdAt: '2024-08-22T10:10:00Z'
  },
  {
    id: 'c927e5f1-8f23-4fbe-b52b-2fd1d9b45e9f',
    user: 'Ifeanyi Okoro',
    contentId: 'aab5e31c-ef23-4e62-bc7f-d7d5c74e24d2',
    title: 'New message from supplier',
    type: 'message',
    read: true,
    createdAt: '2024-08-21T15:45:00Z'
  },
  {
    id: 'd3f0f8e6-a0d8-47d2-9cb1-14a7b2f9a0fa',
    user: 'Fatima Yusuf',
    contentId: 'f87f3b8b-31b7-4045-bfeb-b5c8ec8170a2',
    title: 'Order confirmation for fertilizers',
    type: 'order',
    read: false,
    createdAt: '2024-08-22T08:30:00Z'
  },
  {
    id: 'e5a8e3c4-8b92-4cf9-86c8-f6fbd3f7e5f1',
    user: 'Chinedu Nwankwo',
    contentId: 'b6398c9d-8fae-4c6b-a6c8-8a5c7dfead82',
    title: 'Delivery status update',
    type: 'update',
    read: true,
    createdAt: '2024-08-20T12:00:00Z'
  },
  {
    id: 'f142a9b6-4f1e-4c25-a3b5-6c738c5d0d4c',
    user: 'Ngozi Onuoha',
    contentId: 'c2f3f6d7-7e68-4edc-9f94-32c5b9d5f4f3',
    title: 'Payment received for seeds',
    type: 'payment',
    read: false,
    createdAt: '2024-08-21T09:15:00Z'
  },
  {
    id: 'g27a1b85-d9c2-4386-b7f8-0a1b9b7e4f5e',
    user: 'Emeka Eze',
    contentId: 'd4c5f8d9-3d71-41fb-9fd4-5a7b5b8e9d4f',
    title: 'Review request for recent purchase',
    type: 'review',
    read: false,
    createdAt: '2024-08-22T11:05:00Z'
  }
]

const requestData = [
  {
    title: 'Farm Update',
    type: 'report',
    datetime: '21-04-24, 13:49 PM',
    assignedTo: 'John Doe',
    status: 'Acknowledged'
  },
  {
    title: 'Request for Equipment',
    type: 'request',
    datetime: '21-04-25, 10:30 AM',
    assignedTo: 'Jane Smith',
    status: 'Pending'
  },
  {
    title: 'Crop Yield Update',
    type: 'report',
    datetime: '21-04-26, 15:20 PM',
    assignedTo: 'Michael Brown',
    status: 'Approved'
  },
  {
    title: 'Irrigation Issue',
    type: 'request',
    datetime: '21-04-27, 08:15 AM',
    assignedTo: 'William Johnson',
    status: 'Pending'
  },
  {
    title: 'Fertilizer Delivery',
    type: 'request',
    datetime: '21-04-28, 01:05 PM',
    assignedTo: 'David Miller',
    status: 'Approved'
  },
  {
    title: 'Monthly Production Report',
    type: 'report',
    datetime: '21-04-29, 04:20 PM',
    assignedTo: 'James Wilson',
    status: 'Acknowledged'
  },
  {
    title: 'Tractor Maintenance',
    type: 'request',
    datetime: '21-04-30, 11:35 AM',
    assignedTo: 'Richard Smith',
    status: 'Rejected'
  },
  {
    title: 'Seeds Delivery',
    type: 'request',
    datetime: '21-05-01, 03:50 PM',
    assignedTo: 'Michael Johnson',
    status: 'Pending'
  },
  {
    title: 'Quality Audit',
    type: 'report',
    datetime: '21-05-02, 09:15 AM',
    assignedTo: 'William Adams',
    status: 'Approved'
  },
  {
    title: 'Fertilizer Pickup',
    type: 'request',
    datetime: '21-05-03, 05:20 PM',
    assignedTo: 'David Young',
    status: 'Acknowledged'
  }
]
const cardData = [
  {
    id: 1,
    cardNumber: 1234567890123456,
    bankName: 'Access Bank',
    cardHolder: 'Maxwell Philip',
    isDefault: true
  },
  {
    id: 2,
    cardNumber: 1234567890127890,
    bankName: 'Gtb Bank',
    cardHolder: 'John Doe',
    isDefault: false
  },
  {
    id: 3,
    cardNumber: 1234567890127890,
    bankName: 'Standard Bank',
    cardHolder: 'Jane Smith',
    isDefault: false
  }
]

const statesInNigeria = [
  { value: 'abia', label: 'Abia' },
  { value: 'adamawa', label: 'Adamawa' },
  { value: 'akwa_ibom', label: 'Akwa Ibom' },
  { value: 'anambra', label: 'Anambra' },
  { value: 'bauchi', label: 'Bauchi' },
  { value: 'bayelsa', label: 'Bayelsa' },
  { value: 'benue', label: 'Benue' },
  { value: 'borno', label: 'Borno' },
  { value: 'cross_river', label: 'Cross River' },
  { value: 'delta', label: 'Delta' },
  { value: 'ebonyi', label: 'Ebonyi' },
  { value: 'edo', label: 'Edo' },
  { value: 'ekiti', label: 'Ekiti' },
  { value: 'enugu', label: 'Enugu' },
  { value: 'gombe', label: 'Gombe' },
  { value: 'imo', label: 'Imo' },
  { value: 'jigawa', label: 'Jigawa' },
  { value: 'kaduna', label: 'Kaduna' },
  { value: 'kano', label: 'Kano' },
  { value: 'katsina', label: 'Katsina' },
  { value: 'kebbi', label: 'Kebbi' },
  { value: 'kogi', label: 'Kogi' },
  { value: 'kwara', label: 'Kwara' },
  { value: 'lagos', label: 'Lagos' },
  { value: 'nasarawa', label: 'Nasarawa' },
  { value: 'niger', label: 'Niger' },
  { value: 'ogun', label: 'Ogun' },
  { value: 'ondo', label: 'Ondo' },
  { value: 'osun', label: 'Osun' },
  { value: 'oyo', label: 'Oyo' },
  { value: 'plateau', label: 'Plateau' },
  { value: 'rivers', label: 'Rivers' },
  { value: 'sokoto', label: 'Sokoto' },
  { value: 'taraba', label: 'Taraba' },
  { value: 'yobe', label: 'Yobe' },
  { value: 'zamfara', label: 'Zamfara' },
  { value: 'fct', label: 'FCT' }
]

export {
  TotalRevenueSeries,
  OtherSeries,
  salesproducts,
  salesproductsdetails,
  productDataTable,
  news,
  hotnews,
  agriproduct,
  foryouproduct,
  productInputStatus,
  productNameInput,
  productUnitInput,
  agribusinessProductTypes,
  RegularDashboardContent,
  InvestmentPageContent,
  FarmProducts,
  adminNewsData,
  RegularAdminDashboardContent,
  userData,
  investmentData,
  productData,
  adminTipsData,
  staffData,
  agriBusinessRoles,
  staffLocationOptions,
  notificationMessage,
  requestData,
  cardData,
  statesInNigeria
}
