import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import { columns } from '../OperationsTableColumn/TableColumn'
import { OperationMobileColumns } from '../OperationsTableColumn/OperationMobileColumn/OperationMobileColumn'
import useWindowSize from '../../UseWindowSize/UseWindowSize'

function OperationsDataTable ({ data, title, nav }) {
  const { width } = useWindowSize()
  const columnsforoperation = width > 768 ? columns : OperationMobileColumns
  const onClick = data => {
    console.log(data)
  }

  return (
    <div>
      <DataTable
        data={data}
        columns={columnsforoperation}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={require('../../../../resources/farmemptyimage.png')}
            text='No operations yet'
            desc='Once an operation has been added, you will see an overview here.'
          />
        }
        onClick={onClick}
        title={title}
        nav={nav}
      />
    </div>
  )
}

export default OperationsDataTable
