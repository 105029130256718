export const columns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`
  },
  {
    accessorKey: 'email',
    header: 'Email'
  },
  {
    accessorKey: 'phone_number',
    header: 'Number'
  },
  {
    accessorKey: 'role',
    header: 'Role'
  },
  {
    accessorKey: 'location',
    header: 'Location'
  }
]
