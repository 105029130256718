import React, { useState } from 'react'
import { BellDot, Menu } from 'lucide-react'
import { RegularAdminDashboardContent } from '../../../data/static'
import NotificationDrawer from '../../SharedComponents/NotificationDrawer/NotificationDrawer'

const NavBar = ({ location, onClick }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { pathname } = location
  const navData = RegularAdminDashboardContent.find(
    item => item.link === pathname
  )

  const { text } = navData || {}

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <div className='pro-topnav-container'>
      <div className='flex flex-col gap-3 lg:gap-4 min-w-fit'>
        <p className='text-black lg:text-darkgreen font-bold lg:italic text-lg lg:text-xl'>
          {text}
        </p>
      </div>
      <div className='pronav-content-style'>
        <div className='center gap-2 text-gray-500'>
          <div className='bell-style cursor-pointer' onClick={toggleDrawer}>
            <BellDot size={20} />
          </div>
        </div>
      </div>
      <div className='flex lg:hidden' onClick={onClick}>
        <Menu size={24} />
      </div>

      {/* Drawer */}
      <NotificationDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </div>
  )
}

export default NavBar
