import React from 'react'
import { Check, TriangleAlert, X } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const Modal = ({ title, desc, imageicon, cardmultiplebtn, type, link }) => {
  const navigate = useNavigate()
  const icon = type === 'success' ? <Check className='text-darkGreen w-[80px] h-[80px]' size={40} /> : <TriangleAlert className='text-red-400 w-[80px] h-[80px]' size={40} />
  const noborder = type === 'success' ? 'border-solid border-darkgreen border-2' : 'border-none'
  const backgroundimage = type === 'success' ? 'bg-modalimg' : 'bg-errorbg'
  const btntitle = type === 'success' ? 'Continue' : 'Try Again'
  const handleRedirect = () => {
    if (link) {
      navigate(link)
    }
  }
  return (
    <>
      <dialog id='my_modal_5' className='modal modal-middle'>
        <div className='bg-white lg:w-[30%] h-[400px] w-[90%]'>
          <form method='dialog' className='dialog'>
            {/* up */}
            <div className={`w-full h-[50%] ${backgroundimage} p-4 bg-cover bg-center bg-screen`}>
              <button className='b-none'>
                <X
                  size={20}
                  className='text-red-600 w-6 h-6 rounded-full bg-white'
                />
              </button>
              <div className='w-[50%] mx-auto h-full center'>
                {imageicon
                  ? (
                    <div className='w-[30%] mx-auto h-full center'>
                      <img src={imageicon} alt='icon' />
                    </div>
                    )
                  : (
                    <div className={`${noborder} rounded-full p-2  w-[50%] center `}>
                      {icon}
                    </div>
                    )}
              </div>
            </div>
            {/* down */}
            <div className='w-full h-[50%] center flex-col p-5 text-center text-black'>
              <h1 className='font-semibold'>{title}</h1>
              <p className='ts'>{desc}</p>
              {cardmultiplebtn
                ? (
                  <div className='w-full center gap-2 ts text-white mt-3'>
                    <button className='w-[50%] h-[50px] bg-[#D92534] rounded-md ts'>No,Cancel</button>
                    <button className='w-[50%] h-[50px] bg-[#5CB85C] rounded-md ts'>Yes,Change</button>
                  </div>
                  )
                : (
                  <button className='btn-style ts' onClick={handleRedirect}>{btntitle}</button>
                  )}
            </div>
          </form>
        </div>
      </dialog>
    </>
  )
}

export default Modal
