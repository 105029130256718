import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

const colorSequence = [
  '#ADEE68',
  '#88BF0F',
  '#144618',
  '#03A696',
  '#F2BB13',
  '#F28F16',
  '#D95204',
  '#BF6B04',
  '#733620',
  '#826B29',
  '#9BB5CD',
  '#A6CFCD'
]

const getRandomColor = index => colorSequence[index % colorSequence.length]

const getTextColor = backgroundColor => {
  const r = parseInt(backgroundColor.substring(1, 3), 16)
  const g = parseInt(backgroundColor.substring(3, 5), 16)
  const b = parseInt(backgroundColor.substring(5, 7), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 125 ? 'black' : 'white'
}

const Chart = ({ data, filterType }) => {
  let chartData
  let options

  if (filterType === 'product') {
    const productNames = Object.keys(data)
    const farms = [
      ...new Set(
        productNames.flatMap(product =>
          data[product].map(farm => farm.farm_name)
        )
      )
    ]
    const datasets = productNames.map(product => {
      const backgroundColor = '#144618'

      return {
        label: product,

        data: farms.map(farmName => {
          const farm = data[product].find(f => f.farm_name === farmName)
          return farm ? farm.quantity : 0
        }),
        backgroundColor,
        borderColor: 'white',
        barThickness: 45,
        maxBarThickness: 45,
        borderRadius: 5,
        borderSkipped: false,
        datalabels: {
          display: false
        }
      }
    })

    chartData = {
      labels: farms,
      datasets
    }

    options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          display: true,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter: value => (value !== 0 ? value : ''),

          anchor: 'end',
          align: 'start'
        }
      },
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          ticks: {
            display: true,
            beginAtZero: true
          }
        }
      }
    }
  } else if (filterType === 'farm') {
    const labels = data[0].products.map(product => product.name)
    const dataValues = data[0].products.map(product => product.quantity)

    const backgroundColor = labels.map((_, index) => getRandomColor(index))

    chartData = {
      labels,
      datasets: [
        {
          label: data[0].farm_name,
          data: dataValues,
          backgroundColor,
          borderColor: 'white',
          barThickness: 45,
          maxBarThickness: 45,
          barPercentage: 0.9,
          categoryPercentage: 1,
          borderRadius: 5,
          borderSkipped: false,
          datalabels: {
            display: false
          }
        }
      ]
    }

    options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          display: true,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter: (value, context) => value,
          anchor: 'end',
          align: 'start'
        }
      },
      scales: {
        x: {
          stacked: false,
          categoryPercentage: 0.8,
          barPercentage: 0.9
        },
        y: {
          stacked: false,
          ticks: {
            display: true,
            beginAtZero: true
          }
        }
      }
    }
  } else {
    const farms = data.map(farm => farm.farm_name)
    const products = [
      ...new Set(
        data.flatMap(farm => farm.products.map(product => product.name))
      )
    ]

    const datasets = products.map((product, index) => {
      const backgroundColor = getRandomColor(index)
      const textColor = getTextColor(backgroundColor)
      return {
        label: product,
        data: data.map(farm => {
          const prod = farm.products.find(p => p.name === product)
          return prod ? 7 : null
        }),
        actualData: data.map(farm => {
          const prod = farm.products.find(p => p.name === product)
          return prod ? prod.quantity : null
        }),

        backgroundColor,
        borderColor: 'white',
        barThickness: 45,
        maxBarThickness: 45,
        barPercentage: 0.9,
        categoryPercentage: 1,
        borderRadius: 5,
        borderSkipped: false,
        datalabels: {
          color: textColor,
          anchor: 'center',
          align: 'center',
          formatter: (value, context) => {
            const index = context.dataIndex
            const datasetIndex = context.datasetIndex
            return chartData.datasets[datasetIndex].actualData[index]
          }
        }
      }
    })

    chartData = {
      labels: farms,
      datasets
    }

    options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        tooltip: {
          enabled: false
        },

        datalabels: {
          display: true,
          color: 'white',
          font: {
            weight: 'bold'
          },
          formatter: (value, context) => {
            const index = context.dataIndex
            const datasetIndex = context.datasetIndex
            return chartData.datasets[datasetIndex].actualData[index]
          },
          anchor: 'end',
          align: 'start'
        }
      },
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          ticks: {
            display: false
          }
        }
      }
    }
  }

  return <Bar data={chartData} options={options} />
}

export default Chart
