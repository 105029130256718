import { ChevronDown } from 'lucide-react'
import React from 'react'

function Accordion ({ text, subtext, onClick, flipped }) {
  return (
    <div className=' flex flex-row justify-between w-full '>
      <div className=' flex flex-col gap-2 lg:gap-5  '>
        <p className=' text-[12px] lg:text-[14px] text-black '>{text}</p>
        <p className=' text-[12px] lg:text-[14px] text-grey '>{subtext}</p>
      </div>
      <div
        onClick={onClick}
        className={`cursor-pointer transform transition duration-300 ${
          flipped ? 'rotate-180' : 'rotate-0'
        } `}
      >
        <ChevronDown size={32} />
      </div>
    </div>
  )
}

export default Accordion
