import React, { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../../resources/logo-white.png'
import DashboardIcon from '../../../resources/whitedashboard.png'
import Farm from '../../../resources/farm.png'
import Products from '../../../resources/product.png'
import Stocks from '../../../resources/stocks.png'
import Staffs from '../../../resources/staffs.png'
import Sidebar from '../SideBar/Sidebar'
import TopNav from '../NavBar/TopNav'
import GreenHomeIcon from '../../../resources/home.png'
import GreenFarmIcon from '../../../resources/greenfarmicon.png'
import GreenProductIcon from '../../../resources/greenproducticon.png'
import GreenStaffIcon from '../../../resources/greenstaff.png'
import GreenStockIcon from '../../../resources/greenstockicon.png'
import { LogOut, X } from 'lucide-react'
import { useDispatch } from 'react-redux'
import useBackendService from '../../../../config/services/backend-service'
import { logout } from '../../../../config/store/reducers/authReducer'
import { useAuth } from '../.././../../config/contexts/authProvider'
import MobileSidebar from '../MobileSideBar/MobileSideBar'

const LayoutWrapper = () => {
  const [visible, setVisible] = useState(false)
  const { user } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { mutate } = useBackendService('/auth/logout', 'post', {
    onSuccess: () => {
      dispatch(logout())
      navigate('/')
    },
    onError: error => {
      console.error('Logout failed:', error)
    }
  })

  const handleLogout = () => {
    mutate()
  }

  const handleClick = () => {
    setVisible(true)
  }

  const menus = [
    ...(user.role === 'administrator'
      ? [
          {
            name: 'dashboard',
            icon: DashboardIcon,
            orange: GreenHomeIcon,
            link: '/dashboard'
          }
        ]
      : []),
    ...(user.isAdmin
      ? [
          {
            name: 'branches',
            icon: Farm,
            orange: GreenFarmIcon,
            link: '/farms'
          }
        ]
      : []),
    {
      name: 'products',
      icon: Products,
      orange: GreenProductIcon,
      link: '/farm-products'
    },
    {
      name: 'stock',
      icon: Stocks,
      orange: GreenStockIcon,
      link: '/stocks'
    },
    ...(user.role === 'administrator'
      ? [
          {
            name: 'staffs',
            icon: Staffs,
            orange: GreenStaffIcon,
            link: '/staffs'
          }
        ]
      : []),
    {
      name: 'operations',
      icon: require('../../../resources/request.png'),
      orange: require('../../../resources/request-active.png'),
      link: '/operations'
    },
    {
      name: 'profile',
      icon: require('../../../resources/profile.png'),
      orange: require('../../../resources/greenprofile.png'),
      link: '/profile'
    }
  ]

  return (
    <div className='w-full flex max'>
      {visible === true && (
        <div className=' fixed h-full flex justify-end w-screen lg:hidden z-10 bg-black/30 left-0 top-0 '>
          <section className=' bg-white w-[280px] flex flex-col items-start  h-screen py-8 gap-8 z-10 '>
            <div className=' w-full flex px-[20px] flex-row justify-between items-center '>
              <div className='' onClick={() => setVisible(false)}>
                <img
                  src={require('../../../resources/logoleaf.png')}
                  alt=''
                  className=' w-[98px] '
                />
              </div>
              <div className='' onClick={() => setVisible(false)}>
                <X size={20} />
              </div>
            </div>
            <div className=' w-full gap-5 flex flex-col '>
              {menus.map((menu, index) => (
                <MobileSidebar
                  key={menu.link}
                  name={menu.name}
                  icon={menu.icon}
                  link={menu.link}
                  orange={menu.orange}
                />
              ))}
            </div>
            <div className='flex-[1] justify-end flex flex-col p-3 items-end '>
              <div
                className=' text-red-400 gap-2 flex  ml-3'
                onClick={handleLogout}
              >
                <LogOut color='red' size={20} />
                Logout
              </div>
            </div>
          </section>
        </div>
      )}
      <div className='sidebar-wrapper bg-darkGreen'>
        <div className='top-content-wrapper'>
          <div className='logo-cover pl-[50px] '>
            <img alt='logo' src={Logo} className=' w-[80px] lg:w-[142px]' />
          </div>
          <div className='mt-[20%] w-full'>
            {menus.map(menu => (
              <Sidebar
                key={menu.link}
                name={menu.name}
                icon={menu.icon}
                link={menu.link}
                orange={menu.orange}
              />
            ))}
          </div>
        </div>
        <button className='logout-button-style' onClick={handleLogout}>
          <LogOut />
          Logout
        </button>
      </div>

      <div className='topbar-wrapper  '>
        <TopNav onClick={handleClick} location={useLocation()} menus={menus} />
        <div className=' w-full '>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default LayoutWrapper
