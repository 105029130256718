import { Lock } from 'lucide-react'
import React from 'react'

function PayWall ({ text }) {
  return (
    <main className='bg-darkgreen flex flex-col w-full h-full justify-center items-center gap-[15px] lg:gap-[30px]'>
      <div className=' icon-wrapper '>
        <Lock size={30} color='#144618' />
      </div>
      <div className=''>
        <p className=' text-white font-[500] text-center text-[14px] lg:text-[16px] '>
          {text}
        </p>
      </div>
      <div className=' cursor-pointer w-full lg:w-[360px] px-5 py-3 bg-primary rounded-lg lg:px-5 lg:py-4 '>
        <p className='  font-[600] justify-center  flex text-[14px] lg:text-[16px] text-primary '>
          Upgrade plan
        </p>
      </div>
    </main>
  )
}

export default PayWall
