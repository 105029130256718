import { MoreHorizontal } from 'lucide-react'

export const MobileColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => {
      const value = context.getValue()
      return (
        <div className='w-[100px]'>{value}</div>
      )
    }
  },

  {
    accessorKey: 'address',
    header: 'Address',
    cell: context => {
      const value = context.getValue()
      return (
        <div className='w-[120px]'>{value}</div>
      )
    }
  },

  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
