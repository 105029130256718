import CreatePassword from '../../../SharedComponents/CreatePaasword/CreatePassword'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const EnterpriseAdminCreatePassword = ({ formData }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm()
  const navigate = useNavigate()

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  const { mutate: createAdminUser } = useBackendService(
    '/auth/create_admin',
    'post',
    {
      onSuccess: data => {
        toast.success(
          'Admin user succesfully created. \n A verification link has been sent to you to confirm your email'
        )
        navigate('/')
      },
      onError: error => {
        console.error('Failed to approve applications:', error)
      }
    }
  )

  // function to submitting the form data
  const onSubmit = async data => {
    const isValid = await trigger(['password', 'confirmpassword', 'email'])
    if (!isValid) return
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    createAdminUser({
      ...formData,
      ...data,
      isAdmin: true,
      plan: 'Enterprise',
      token,
      role: 'administrator'
    })
  }

  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Create Password'
        desc='Kindly fill in your details to setup your account'
      />
    </>
  )
}

export default EnterpriseAdminCreatePassword
