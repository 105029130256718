import { BadgePlus, CalendarClock, Timer } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import { Controller, useForm } from 'react-hook-form'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import { useParams, useNavigate } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'

function ReportDetails () {
  const { user } = useAuth()
  const { id } = useParams()
  const [data, setData] = useState({})
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset
  } = useForm()
  const navigate = useNavigate()

  const { mutate } = useBackendService(`/operations/${id}`, 'get', {
    onSuccess: response => {
      setData(response.data)
      reset(response.data)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    mutate()
  }, [mutate])

  const { mutate: updateStatus } = useBackendService(
    `/operations/${id}`,
    'put',
    {
      onSuccess: response => {
        navigate('/operations')
      },
      onError: error => {}
    }
  )

  const handleStatusUpdate = async () => {
    const status = getValues('status')

    updateStatus({ status })
  }

  const onSubmit = data => {
    console.log(data)
  }

  const status = [
    { value: 'Acknowledged', label: 'Acknowledged' },
    { value: 'Submitted', label: 'Submitted' }
  ]

  const isSender = data.created_by === user.id
  const textToShow = isSender ? 'Assigned to' : 'Created by'

  const createdAt = new Date(data.created_at)
  const date = createdAt.toLocaleDateString()
  const time = createdAt.toLocaleTimeString()

  const assignee =
    textToShow === 'Created by' ? user.email : data.assigned_to_email

  return (
    <section className='flex flex-col bg-white lg:p-5 p-3 gap-4 lg:gap-8'>
      <BackButton size={24} />
      <div className='flex flex-col gap-2'>
        <p className='text-black text-[16px] lg:text-[24px] font-[600]'>
          Report
        </p>
      </div>
      <div className='flex flex-row items-center gap-2 lg:gap-8 justify-center lg:justify-start'>
        <div className='flex flex-row gap-2'>
          <BadgePlus size={18} />
          <p className='text-grey text-[12px] lg:text-[16px]'>
            {textToShow}: {assignee}
          </p>
        </div>
        <div className='flex flex-row gap-2'>
          <CalendarClock size={18} />
          <p className='text-grey text-[12px] lg:text-[16px]'>Date: {date}</p>
        </div>
        <div className='flex flex-row gap-2'>
          <Timer size={18} />
          <p className='text-grey text-[12px] lg:text-[16px]'>Time: {time}</p>
        </div>
        {data.created_by !== user.id && (
          <div className='flex flex-row gap-2'>
            <Timer size={18} />
            <p className='text-grey text-[12px] lg:text-[16px]'>
              Status: {data.status}
            </p>
          </div>
        )}
      </div>
      <form
        className='flex min-w-fit flex-col gap-4 lg:gap-8'
        action=''
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex flex-col lg:flex-row gap-5 lg:gap-10 items-center'>
          <div className='w-full lg:w-[418px]'>
            <TextInput
              name='Type'
              register={register}
              errors={errors}
              text='Type'
              value={data.type}
              disabled
            />
          </div>
          <div className='w-full lg:w-[418px]'>
            <TextInput
              name='subject'
              register={register}
              errors={errors}
              text='Subject'
              value={data.title}
              disabled
            />
          </div>
        </div>
        <div className='w-full lg:w-[876px]'>
          <TextArea
            name='description'
            register={register}
            errors={errors}
            text='Description'
            placeholder='Type report description'
            value={data.description}
            disabled
          />
        </div>
        {data.created_by !== user.id && (
          <div className='w-full lg:w-[418px]'>
            <SelectInput
              control={control}
              Controller={Controller}
              data={status}
              title='Status'
              name='status'
              placeholder='Select who to send report to'
              defaultValue={data.status}
            />

            <button
              type='button'
              className='w-full mt-10 lg:w-[408px] h-[50px] proceed-btn bg-darkGreen'
              onClick={handleStatusUpdate}
            >
              Update Report
            </button>
          </div>
        )}
      </form>
    </section>
  )
}

export default ReportDetails
