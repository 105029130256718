import React from 'react'
import MapWeatherSection from './MapWeather/MapWeatherSection'
import CatalogSection from './CatalogSection/CatalogSection'
import SalesSection from './SalesSection/SalesSection'
import InvestmentSection from './InvestmentSection/InvestmentSection'
import NewsSection from './NewsSection/NewsSection'
import TipsSection from './TipsSection/TipsSection'
import MobileWeather from '../MobileWeather/MobileWeather'

function RegularHome () {
  return (
    <main className='flex flex-col gap-[30px] bg-white lg:gap-[50px] py-5 lg:py-[40px] '>
      <MobileWeather />
      <MapWeatherSection />
      <CatalogSection />
      <SalesSection
        title='Sales & Supply'
        titletwo='Chain Information'
        desc='Stay ahead of the market with our sales & supply chain information.'
      />
      <InvestmentSection />
      <NewsSection />
      <TipsSection />
    </main>
  )
}

export default RegularHome
