import React, { useEffect, useState } from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import { Plus } from 'lucide-react'
import StaffDataTable from './StaffDataTable/StaffDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const StaffPage = () => {
  const [staffData, setStaffData] = useState([])
  const { user } = useAuth()

  const { mutate: fetchStaff } = useBackendService(
    `/users?farm_id=${user.farmId}`,
    'get',
    {
      onSuccess: response => {
        setStaffData(response.data)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  useEffect(() => {
    fetchStaff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='farmwrapper'>
      <DashBoardHeader
        title='Staffs'
        btntext='Add new staff'
        link='/staffs/new-staff'
        icon={<Plus size={20} />}
      />
      <StaffDataTable data={staffData} />

    </div>
  )
}

export default StaffPage
