import React from 'react'
import DataTable from '../DataTable/DataTable'
import { columns } from '../ProductTableColumn/ProductTableColumn'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyFarmImage from '../../../../resources/farmemptyimage.png'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import useWindowSize from '../../UseWindowSize/UseWindowSize'
import { ProductColumns } from '../ProductTableColumn/ProductMobileColumn'
const ProductDataTable = ({ data }) => {
  const { width } = useWindowSize()
  const columnsforproduct = width > 768 ? columns : ProductColumns
  return (
    <div className='w-full bg-[#F9F9F9] rounded-md h-full px-[2%] py-[1%]'>
      <DataTable
        data={data}
        columns={columnsforproduct}
        productfilter='true'
        pagination={data.length > 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={EmptyFarmImage}
            text='No products yet'
            desc='Once you add products you would get product overview here.'
          />
        }
      />
    </div>
  )
}

export default ProductDataTable
