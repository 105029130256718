import React, { useEffect, useState } from 'react'
import { Plus, Trash2 } from 'lucide-react'
import CardLogo from '../../../../resources/cardlogo.avif'
import WarningIcon from '../../../../resources/error.png'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { toast } from 'react-toastify'

const ProfileCardDetails = () => {
  const { user } = useAuth()
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null)

  const { mutate: fetchCards } = useBackendService('/cards', 'get', {
    onSuccess: response => {
      setCards(response.data)
    },
    onError: error => {
      console.error('An error occurred.', error)
      toast.error('An error occurred retrieving data. Please try again.')
    }
  })
  const { mutate: deleteCard } = useBackendService('/cards', 'delete', {
    onSuccess: response => {
      setCards(prevCards =>
        prevCards.filter(card => card.id !== selectedCard.id)
      )
      toast.success('Card deleted successfully.')
    },
    onError: error => {
      console.error('An error occurred during deletion.', error)
      toast.error(
        'An error occurred while deleting the card. Please try again.'
      )
    }
  })

  useEffect(() => {
    if (user?.id) {
      fetchCards({ user_id: user.id })
    }
  }, [user, fetchCards])

  const formatCardNumber = number => {
    const strNumber = number.toString()
    return `**** **** **** ${strNumber.slice(-4)}`
  }

  const defaultCard = cards.find(card => card.is_default)
  const nonDefaultCards = cards.filter(card => !card.is_default)

  const handleCardDelete = card => {
    setSelectedCard(card)
    deleteCard({}, '/cards/123')

    document.getElementById('my_modal_5').showModal()
  }
  // const confirmCardDelete = () => {
  //   if (selectedCard) {
  //     deleteCard({}, `/cards/${selectedCard.id}`)
  //     document.getElementById('my_modal_5').close()
  //   }
  // }

  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <h1 className='font-medium p-3'>Saved Card</h1>
      <hr />
      <div className='w-[100%] p-3 space-y-4'>
        {/* Default Card */}
        {defaultCard && (
          <>
            <h1 className='font-light'>Default Card</h1>
            <div className='w-[80%] border border-gray-300 rounded-md p-3 flex justify-between items-start ts'>
              <div className='w-[60%] flex flex-col justify-center items-start'>
                <img src={CardLogo} alt='logo' className='lg:w-[60px]' />
                <h1 className='font-medium'>
                  {formatCardNumber(defaultCard.card_number)}
                </h1>
                <h1 className='text-gray-400'>
                  {defaultCard.card_holder_name}
                </h1>
              </div>
            </div>
          </>
        )}

        {/* Non-default cards */}
        {nonDefaultCards.length > 0 && (
          <>
            <hr />
            <h1 className='font-light'>Other Cards</h1>
            {nonDefaultCards.map(card => (
              <div
                key={card.id}
                className='w-[80%] border border-gray-300 rounded-md p-3 flex justify-between items-start ts'
              >
                <div className='w-[60%] flex flex-col justify-center items-start'>
                  <img src={CardLogo} alt='logo' className='lg:w-[60px]' />
                  <h1 className='font-medium'>
                    {formatCardNumber(card.card_number)}
                  </h1>
                  <h1 className='text-gray-400'>{card.card_holder_name}</h1>
                </div>
                <div className='flex justify-between items-center gap-7 flex-col h-full'>
                  <input
                    type='checkbox'
                    onChange={e => handleCardDelete(card, e.target.checked)}
                  />
                  <Trash2 className='text-red-500 cursor-pointer' />
                </div>
              </div>
            ))}
          </>
        )}

        {/* Add new Card */}
        <div className='w-full flex gap-2 justify-start items-center ts'>
          <Plus className='text-darkGreen' />
          <h1 className='text-darkGreen'>Add new card</h1>
        </div>
      </div>

      {/* Modal for card actions */}
      <Modal
        title='Default card change'
        desc={`Your default card will be changed to ${
          selectedCard ? selectedCard.bank_name : ''
        } ${
          selectedCard ? formatCardNumber(selectedCard.card_number) : ''
        } and will be used for subsequent payments.`}
        imageicon={WarningIcon}
        backgroundimage='bg-orangepattern'
        cardmultiplebtn='true'
      />
    </div>
  )
}

export default ProfileCardDetails
