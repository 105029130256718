import React from 'react'
import DataTable from '../../FarmPage/DataTable/DataTable'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import EmptyFarmImage from '../../../../resources/farmemptyimage.png'
import { columns } from '../StaffTableColumn/StaffTableColumn'
import useWindowSize from '../../UseWindowSize/UseWindowSize'
import { StaffMobileColumn } from '../StaffTableColumn/StaffMobileColumn/StaffMobileColumn'
const StaffDataTable = ({ data }) => {
  const { width } = useWindowSize()
  const columnsforstock = width > 768 ? columns : StaffMobileColumn
  return (
    <div className='w-full bg-[#F9F9F9] rounded-md h-full px-[2%] py-[1%]'>
      <DataTable
        data={data}
        columns={columnsforstock}
        stafffilter='true'
        pagination='true'
        emptydatacontent={
          <EmptyDataContent
            image={EmptyFarmImage}
            text='No staff yet'
            desc='Once a staff has been added, you will see a staff overview here.'
          />
      }
      />
    </div>
  )
}

export default StaffDataTable
