import React from 'react'
import { useLocation } from 'react-router-dom'
import './SideBar.css'

const SideBar = ({ name, icon, link, orange }) => {
  const location = useLocation()
  const path = location.pathname
  const isActive =
    (path === '/' && link === '/dashboard') || path.startsWith(link)

  return (
    <div className='w-full'>
      <a
        href={link}
        className={`w-full flex flex-row items-center justify-center ${
          isActive ? ' bg-white' : ''
        }`}
      >
        <div className='link-container'>
          {isActive
            ? (
              <img src={orange} alt='icons' className='w-[20px] h-[20px]' />
              )
            : (
              <img src={icon} alt='icons' className='w-[20px] h-[20px]' />
              )}
          <h1
            className={` font-medium ${
              isActive ? 'text-darkgreen font-semibold' : 'text-white'
            }`}
          >
            {name}
          </h1>
        </div>
      </a>
    </div>
  )
}

export default SideBar
