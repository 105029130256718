import React from 'react'
import { ArrowUpDown, ChevronDown } from 'lucide-react'

const StockFilter = ({ data, table }) => {
  const handleSortingChange = (direction, column) => {
    if (column === 'alphabetical') {
      table.setSorting([{ id: 'farmname', desc: false }]) // sort by farmname in ascending order
    } else {
      table.setSorting([{ id: 'farmname', desc: direction === 'desc' }]) // sort by name in asc or desc order
    }
  }

  return (
    <div className='separator px-0 lg:px-3'>
      <h1 className='text-sm lg:text-lg w-[500px] font-semibold'>Stock</h1>
      {data.length === 0
        ? (
            ''
          )
        : (
          <div className='dropdown dropdown-hover p-0'>
            <div
              tabIndex={0}
              role='button'
              className='w-[150px] text-sm p-3 center gap-1'
            >
              <ArrowUpDown size={20} />
              Sort by
              <ChevronDown size={20} />
            </div>
            <div
              tabIndex={0}
              className='dropdown-content menu rounded-box sort shadow'
            >
              <h1
                className='sort-hover'
                onClick={() => handleSortingChange('asc')}
              >
                New - Old
              </h1>
              <h1
                className='sort-hover'
                onClick={() => handleSortingChange('desc')}
              >
                Old - New
              </h1>
              <h1 className='sort-hover' onClick={() => handleSortingChange('asc', 'alphabetical')}>Alphabetical</h1>
            </div>
          </div>
          )}
    </div>
  )
}

export default StockFilter
