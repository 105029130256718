import { ArrowLeft } from 'lucide-react'
import React, { useState } from 'react'
import Products from './Products'
import Catalog from './Catalog'
import Sales from './Sales'
import NavigvationSteps from '../NavigationSteps/NavigvationSteps'
import { useForm } from 'react-hook-form'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'

function AddNewProductCatalog () {
  const [stage, setStage] = useState(1)
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })
  const [images, setImages] = useState([])
  const [location, setLocation] = useState([])

  const handleNextStage = () => {
    if (stage < 3) {
      setStage(stage + 1)
    }
  }
  const handlePrevStage = () => {
    if (stage > 1) {
      setStage(stage - 1)
    } else setStage(1)
  }
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid }
  } = useForm({ mode: 'onChange' })
  const { mutate: createProductCatalog } = useBackendService(
    '/product_catalogues',
    'post',
    {
      onSuccess: () => {
        setModalData({
          title: 'Product Catalog added',
          desc: 'Well done , you have successfully created a new product catalog',
          type: 'success',
          link: '/admin-products'
        })
        document.getElementById('my_modal_5').showModal()
      },
      onError: () => {
        setModalData({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to create a product catalog'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )
  const onSubmit = async data => {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('category', data.category)
    formData.append('description', data.description)
    formData.append(
      'nutritionMarketValue',
      JSON.stringify(data.nutritionMarketValue)
    )
    formData.append(
      'growingTips',
      JSON.stringify(
        Array.isArray(data.growingTips) ? data.growingTips : [data.growingTips]
      )
    )
    formData.append(
      'careInstructions',
      JSON.stringify(
        Array.isArray(data.careInstructions)
          ? data.careInstructions
          : [data.careInstructions]
      )
    )

    formData.append('storageSolutions', data.storageSolutions)
    formData.append('packagingMethods', data.packagingMethods)
    formData.append('marketPrices', data.marketPrices)
    formData.append('logisticsProviders', data.logisticsProviders)
    formData.append('salesChannels', data.salesChannels)
    location.forEach((location, index) => {
      formData.append(`locations[${index}]`, location)
    })
    images.forEach((image, index) => {
      formData.append(`images`, image.file)
    })
    createProductCatalog(formData)
  }

  return (
    <section className=' p-5 lg:p-10 flex flex-col gap-[15px] lg:gap-[30px] bg-white '>
      <div className=' mb-2 lg:mb-4 '>
        <a href='/admin-products'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2 '>
        <h1 className='text-[16px] lg:text-xl text-black'>
          Add product catalog
        </h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Enter product details to add
        </p>
      </div>
      <NavigvationSteps stage={stage} />
      <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
        {stage === 1 && (
          <Products
            onClick={handleNextStage}
            register={register}
            errors={errors}
            isValid={isValid}
            control={control}
            setImages={setImages}
            images={images}
            location={location}
            setLocation={setLocation}
          />
        )}
        {stage === 2 && (
          <Catalog
            handleNext={handleNextStage}
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
          />
        )}
        {stage === 3 && (
          <Sales
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
          />
        )}
      </form>
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />
    </section>
  )
}

export default AddNewProductCatalog
