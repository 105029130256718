import React from 'react'
import './Index.css'
import { ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'

const contentData = [
  {
    id: 1,
    title: 'Tomatoes',
    content:
      'Juicy red tomatoes perfect for salads, sauces, and cooking. Rich in vitamins A and C.',
    season: true,
    img: require('../../../../resources/tomatoes.png')
  },
  {
    id: 2,
    title: 'Broiler Chicken',
    content:
      'Fast-growing chickens ideal for meat production. High feed conversion efficiency.',
    img: require('../../../../resources/chicken.png')
  },
  {
    id: 3,
    title: 'Corn',
    content:
      'Sweet and tender corn, perfect for grilling or boiling. Harvested during summer.',
    season: true,
    img: require('../../../../resources/corn.png')
  }
]

function CatalogSection () {
  return (
    <section className=' flex flex-col gap-[15px] lg:gap-[30px] px-5 lg:px-10 '>
      <div className=' basic-section-header '>
        <div className=' flex flex-col gap-4 '>
          <p className=' text-[14px] lg:text-[18px] font-[600] text-black '>
            Catalog
          </p>
          <p className=' text-grey font-[400] hidden lg:flex text-[12px] lg:text-[14px] '>
            Learn more about crops & livestock
          </p>
        </div>
        <Link to='/catalog'>
          <div className=' basic-nav '>
            <p className=' text-[14px] lg:text-[18px] font-[700]  '>See more</p>
            <ChevronRight size={22} />
          </div>
        </Link>
      </div>
      <div className='lg:w-full overflow-x-auto flex flex-row lg:flex-1 gap-5 lg:gap-[45px] '>
        {contentData.map(item => (
          <div className=' basiccatalog-content-ctn' key={item.id}>
            <div className='flex flex-col gap-2 p-2 lg:p-5 lg:gap-5'>
              <img
                src={item.img}
                alt=''
                className=' w-[260px] lg:w-full h-[130px] lg:h-[200px] rounded-lg  '
              />
              <div className=' flex w-[260px] lg:w-full flex-row justify-between items-center '>
                <p className='text-black font-[600] text-[14px] lg:text-[18px] '>
                  {item.title}
                </p>
                {item.season && (
                  <p className=' text-primary hidden lg:flex font-[500] text-[14px] '>
                    In season
                  </p>
                )}
              </div>
              <div className=' font-[500] text-grey text-[12px] lg:text-[16px] '>
                <p>{item.content}</p>
              </div>
              <div className=' flex flex-1 justify-end flex-col '>
                <div className=' flex cursor-pointer items-center flex-row gap-[10px] text-primary'>
                  <p className=' text-[12px] lg:text-[18px] font-[700]  '>
                    Check out
                  </p>
                  <ChevronRight />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default CatalogSection
