import React, { useState, useEffect } from 'react'
import BusinessApplicationsTable from './BusinessApplicationsTable'
import useBackendService from '../../../../config/services/backend-service'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'
import { toast } from 'react-toastify'

function BusinessApplications () {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const { mutate: fetchFarms } = useBackendService(
    '/admin/non-activated-enterprise-farms',
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch applications:', error)
      }
    }
  )

  const { mutate: approveBusinessApplication } = useBackendService(
    '/farms/approve_application',
    'post',
    {
      onSuccess: response => {
        toast.success('Application approved')
      },
      onError: error => {
        console.error('Failed to approve applications:', error)
      }
    }
  )

  const { mutate: sendContinueMail } = useBackendService(
    '/admin/send-signup-email',
    'post',
    {
      onSuccess: response => {
        toast.success('Email sent to applicant')
        navigate(0)
      },
      onError: error => {
        console.error('Failed to sent continuation mail:', error)
      }
    }
  )

  const approveApplication = rowData => {
    approveBusinessApplication({ userId: user.id, applicationId: rowData.id })
    sendContinueMail({ email: rowData.email })
  }

  useEffect(() => {
    fetchFarms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='farmwrapper'>
      <BusinessApplicationsTable
        data={data}
        approveApplication={approveApplication}
      />
    </div>
  )
}

export default BusinessApplications
