import React from 'react'
import DashBoardHeader from './DashBoardHeader/DashBoardHeader'
import WeatherImageIndicator from '../WeatherImageIndicator/WeatherImageIndicator'
import DashBoardChart from './DashBoardChart/DashBoardChart'
import { Plus } from 'lucide-react'

function DashBoard () {
  return (
    <div className='w-full h-full  bg-white p-[3%] lg:p-[2%] space-y-5'>
      <div className='w-full flex lg:flex-col flex-col-reverse gap-2 items-center justify-center'>
        <DashBoardHeader
          title='Welcome'
          desc='Manage your organizations activities with ease and take charge'
          btntext='Add new branch'
          link='/farms/new-farm'
          icon={<Plus size={20} />}
        />
        <WeatherImageIndicator />
      </div>
      <DashBoardChart />

    </div>
  )
}

export default DashBoard
