import React, { useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import Pagination from '../../SharedComponents/Pagination/Pagination'
import Filter from '../../SharedComponents/Filter/Filter'
import { useNavigate } from 'react-router-dom'

const DataTable = ({
  data,
  columns,
  filter,
  pagination,
  emptydatacontent,
  nav,
  title,
  rowClick
}) => {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting
    }
  })

  const handleRowClick = row => {
    if (rowClick) {
      rowClick(row.original)
    }
    if (nav) {
      const type = row.original.type.toLowerCase()
      if (type.includes('request')) {
        navigate(`/operations/request/${row.original.id}`)
      } else if (type === 'report') {
        navigate(`/operations/report/${row.original.id}`)
      }
    }
  }

  return (
    <>
      {filter && <Filter data={data} table={table} />}
      {title && (
        <p className=' text-black font-[600] text-[16px] lg:text-[20px] '>
          {title} ({data ? data.length : ''})
        </p>
      )}
      <div className='w-full text-[10px] lg:text-sm overflow-x-auto mt-[2%] '>
        <table className='table table-sm lg:table-md '>
          {data.length === 0 ? (
            <div className='w-full h-[400px] '>{emptydatacontent}</div>
          ) : (
            <>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    className='border border-[#DDDDDD] overflow-hidden rounded-t-lg  h-[60px] text-[12px] lg:text-base '
                  >
                    {headerGroup.headers.map(header => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    className='border-b  border-[#DDDDDD] h-[60px] cursor-pointer'
                    onClick={() => handleRowClick(row)}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>

        {pagination && (
          <Pagination
            next={() => {
              table.nextPage()
            }}
            previous={() => {
              table.previousPage()
            }}
            nextpagedisabled={!table.getCanNextPage()}
            previouspagedisabled={!table.getCanPreviousPage()}
            totalPages={table.getPageCount()}
            current={table.getState().pagination.pageIndex + 1}
          />
        )}
      </div>
    </>
  )
}

export default DataTable
