import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
const AddTips = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onChange'
  })
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })

  const { mutate: submittip } = useBackendService('/tips', 'post', {
    onSuccess: () => {
      setModalData({
        title: 'Tips added',
        desc: 'Well done , you have successfully created a new tip',
        type: 'success',
        link: '/admin-tips'
      })
      document.getElementById('my_modal_5').showModal()
      reset()
    },
    onError: error => {
      setModalData({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to create a tip'
      })
      document.getElementById('my_modal_5').showModal()
    }
  })
  const onSubmit = async data => {
    submittip(data)
  }
  const buttonStyles =
    isDirty && isValid
      ? 'bg-darkGreen text-white'
      : 'bg-gray-200 text-gray-700 cursor-not-allowed'
  return (
    <>
      <div className='w-full flex flex-col lg:flex-row bg-white'>
        <div className='w-full lg:w-[70%] h-full p-[2%] space-y-3'>
          <BackButton />
          <h1 className='font-semibold w-full'>Add tips</h1>

          <form className='w-full space-y-5' onSubmit={handleSubmit(onSubmit)}>
            <div className='w-full lg:w-[50%]'>
              <TextInput
                register={register}
                errors={errors}
                text='Title'
                name='title'
                validation={{ required: 'Title is required' }}
              />
              {errors.title && (
                <p className='text-red-500'>{errors.title.message}</p>
              )}
            </div>
            <div className='grid w-full gap-1.5 ts'>
              <h1>News body</h1>
              <textarea
                className='textarea bg-gray-100 h-[200px] ts'
                placeholder='Start writing'
                {...register('tipsdetails', {
                  required: 'Tips description is required'
                })}
              />
              {errors.tipsdetails && (
                <p className='text-red-500'>{errors.tipsdetails.message}</p>
              )}
            </div>
            <button
              className={`w-full lg:w-[30%] ts rounded-md h-[50px] ${buttonStyles}`}
              disabled={!isDirty || !isValid}
            >
              Submit
            </button>
          </form>
          <Modal
            type={modalData.type}
            title={modalData.title}
            desc={modalData.desc}
            link={modalData.link}
          />
        </div>
      </div>
    </>
  )
}

export default AddTips
