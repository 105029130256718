import React from 'react'

const IndicatorSection = ({ data }) => {
  return (
    <div className='w-full bg-[#F9F9F9]  rounded-[12px] p-4 text-black text-sm'>
      <div className='w-full  flex-col lg:flex-row justify-start flex items-center gap-3 h-full'>
        {/* card start section */}
        <div className='w-[100%] lg:w-[25%]  flex  flex-col  gap-2 '>
          <div className='w-full flex gap-2 justify-start  items-center'>
            <div className='w-1 h-[40px]  bg-orange-500 rounded-2xl' />
            <div className='flex flex-col justify-center items-start'>
              <h1 className='font-semibold'>Total no of branches</h1>
              <h2>All</h2>
            </div>
          </div>
          <h1 className='font-semibold text-lg lg:text-2xl ml-2'>{data.totalFarms}</h1>
        </div>
        {/* card end section */}
        {/* card start section */}
        <div className='w-[100%] lg:w-[25%]  flex  flex-col h-full gap-2 '>
          <div className='w-full flex gap-2 justify-start  items-center'>
            <div className='w-1 h-[40px]  bg-green-500 rounded-2xl' />
            <div className='flex flex-col justify-center items-start'>
              <h1 className='font-semibold'>Total products</h1>
              <h2>All</h2>
            </div>
          </div>
          <h1 className='font-semibold text-lg lg:text-2xl ml-2'>
            {data.totalProductCount}
          </h1>
        </div>
        {/* card end section */}
        {/* card start section */}
        <div className='w-[100%] lg:w-[25%]  flex  flex-col h-full gap-2 '>
          <div className='w-full flex gap-2 justify-start  items-center'>
            <div className='w-1 h-[40px]  bg-[#733620] rounded-2xl' />
            <div className='flex flex-col justify-center items-start'>
              <h1 className='font-semibold'>Assigned staffs</h1>
              <h2>All</h2>
            </div>
          </div>
          <h1 className='font-semibold text-lg lg:text-2xl ml-2'>
            {data.totalStaffCount}
          </h1>
        </div>
      </div>
    </div>
  )
}

export default IndicatorSection
