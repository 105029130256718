import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import NoteComponent from '../../../SharedComponents/NoteComponent/NoteComponent'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import { Clock } from 'lucide-react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useBackendService from '../../../../../config/services/backend-service'
import Modal from '../../../SharedComponents/Modal/Modal'

const TipsDescription = () => {
  const { user } = useAuth()
  const { id } = useParams()
  const [data, setData] = useState({})
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const { mutate: fetchTips } = useBackendService(`/tips/${id}`, 'get', {
    onSuccess: response => {
      setData(response.data)
      setValue('title', response.data.title)
      setValue('tipsdetails', response.data.message)
    },
    onError: error => {
      console.error('Failed to fetch tip data:', error)
      toast.error('Failed to fetch tip data')
    }
  })

  useEffect(() => {
    fetchTips()
  }, [fetchTips])

  const { mutate: updateTip } = useBackendService(`/tips/${id}`, 'put', {
    onSuccess: () => {
      setModalData({
        title: 'Tip added',
        desc: 'Well done , you have successfully updated a tip',
        type: 'success',
        link: '/admin-tips'
      })
      document.getElementById('my_modal_5').showModal()
    },
    onError: error => {
      setModalData({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to update a tip'
      })
      document.getElementById('my_modal_5').showModal()
    }
  })

  const onSubmit = data => {
    updateTip(data)
  }
  const handleStatusChange = status => {
    updateTip(status)
  }
  return (
    <div className='w-full flex flex-col lg:flex-row'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full lg:w-[70%] h-full p-[2%] bg-white space-y-3'
      >
        <BackButton />
        <h1 className='font-semibold w-full'>Tips</h1>
        <h1 className='w-full flex justify-start items-center gap-2 ts'>
          <Clock size={15} /> Status:
          <span className=' text-[#F29339] '>{data.status}</span>{' '}
        </h1>
        <div className='w-full lg:w-[50%]'>
          <div className='grid w-full  items-center gap-1.5'>
            <TextInput
              name='title'
              register={register}
              errors={errors}
              text='Title'
              value={data.title ? data.title : ''}
              disabled={user.role === 'approver'}
            />
          </div>
        </div>
        <div className='grid w-full gap-1.5 ts'>
          <TextArea
            name='tipsdetails'
            register={register}
            errors={errors}
            text='Details'
            value={data.message ? data.message : ''}
            disabled={user.role === 'approver'}
          />
        </div>

        {data.status !== 'approved' &&
          (user.role === 'approver' ? (
            <div className='flex gap-5'>
              <button
                type='button'
                className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-green-500 text-white'
                onClick={() => handleStatusChange('approved')}
              >
                Approve
              </button>
              <button
                type='button'
                className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-red-500 text-white'
                onClick={() => handleStatusChange('rejected')}
              >
                Reject
              </button>
            </div>
          ) : (
            <button
              type='submit'
              className={`w-full lg:w-[408px] h-[50px] ${
                isValid && isDirty
                  ? 'proceed-btn bg-darkGreen'
                  : 'rounded-[8px] bg-darkash text-black'
              }`}
              disabled={!isValid}
            >
              Submit
            </button>
          ))}
      </form>
      {/* notes components */}
      {(user.role === 'approver' || user.id === data.created_by) && (
        <div className='flex-[0.3]'>
          <NoteComponent data={data} table={'tips'} />
        </div>
      )}
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />
    </div>
  )
}

export default TipsDescription
