import { CloudRain, Droplets, Wind } from 'lucide-react'
import React from 'react'

const bgImg = require('../../../resources/weatherimage.png')

function MobileWeather () {
  return (
    <section className=' lg:hidden flex p-5  '>
      <div
        className='w-full p-5 bg-cover flex flex-col gap-6 rounded-lg bg-center '
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className=' flex flex-row items-center gap-2 '>
          <div className=' flex flex-col gap-6  '>
            <p className='font-[500] text-[44px] text-white'>
              31°<span className=' text-[24px] '>c</span>
            </p>
            <p className='font-[500] text-[18px] text-white'> Sunny</p>
          </div>
          <img
            src={require('../../../resources/sun.png')}
            alt=''
            className='w-[60px] h-[42px] '
          />
        </div>
        <div className=' flex flex-row gap-[15px] text-white  '>
          <div className=' weather-icons '>
            <CloudRain size={24} />
            <p className=' font-[400] text-[18px] '>15%</p>
          </div>
          <div className=' weather-icons '>
            <Droplets size={24} />
            <p className=' font-[400] text-[18px] '>0 mm</p>
          </div>
          <div className=' weather-icons '>
            <Wind size={24} />
            <p className=' font-[400] text-[18px] '>15 km/h</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileWeather
