import React from 'react'
import './TrendingNews.css'
import { news } from '../../../../data/static'

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const TrendingNews = () => {
  return (
    <div className='w-full'>
      <h1 className='w-full font-semibold'>Trending news</h1>
      <div className='carousel rounded-box w-full flex gap-2'>
        {news.map((item) => (
          <div className='cover' key={item.id}>
            <div className='w-full h-[180px]'>
              <img src={item.img} alt={item.title} className='object-cover rounded-lg w-full h-full' />
            </div>
            <div className='w-full flex gap-2 justify-start items-center ts'>
              <div className='circle' />
              {formatDate(item.date)}
            </div>
            <h1 className='font-semibold'>{item.title}</h1>
            <p className='text-gray-500 text-sm'>
              {item.content}
              <div className='font-semibold italic text-darkGreen underline'>
                <a href={`/agrinews/${item.id}`}>Read More</a>
              </div>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TrendingNews
