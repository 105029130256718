import { X } from 'lucide-react'
import React, { useState, useEffect, useRef, useCallback } from 'react'

function RepeatedTextInput ({
  label,
  inputStyles,
  error = false,
  labelColor,
  subtext,
  icon,
  existingValues = [],
  onValuesChange,
  ...otherProps
}) {
  const [values, setValues] = useState(existingValues)
  const [currentValue, setCurrentValue] = useState('')

  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else if (existingValues !== values) {
      setValues(existingValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingValues])

  const handleAddValue = useCallback(() => {
    if (currentValue.trim() !== '') {
      const newValues = [...values, currentValue.trim()]
      setValues(newValues)
      onValuesChange(newValues)
      setCurrentValue('')
    }
  }, [currentValue, values, onValuesChange])

  const handleDelete = useCallback(
    index => {
      const newValues = values.filter((_, i) => i !== index)
      setValues(newValues)
      onValuesChange(newValues)
    },
    [values, onValuesChange]
  )

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddValue()
    }
  }

  return (
    <div className='flex gap-y-1 flex-col w-full font-medium text-xs md:text-sm'>
      {label && (
        <label
          style={{
            color: labelColor || '#00000080',
            fontSize: 16
          }}
        >
          {label}
        </label>
      )}
      {subtext && (
        <label
          style={{
            color: labelColor || '#5A5A5A',
            fontSize: 12
          }}
        >
          {subtext}
        </label>
      )}
      <div className='relative'>
        {icon && (
          <div className='absolute inset-y-0 left-3 flex items-center'>
            {icon}
          </div>
        )}
        <input
          className={`${'email-input' || ''} ${
            error ? 'border-red-500' : ''
          } px-3 w-full py-3.5 border-2 focus:outline-none rounded-lg text-sm text-appBlack truncate ${
            icon ? 'pl-10' : ''
          }`}
          value={currentValue}
          onChange={e => setCurrentValue(e.target.value)}
          onKeyDown={handleKeyPress}
          {...otherProps}
        />
      </div>
      <div className='flex gap-2 flex-wrap'>
        {values.map((value, i) => (
          <div
            key={i}
            className='p-2 flex rounded-lg items-center justify-center gap-4 border'
          >
            <p className='text-dark-blue text-black font-[400] text-[16px]'>
              {value}
            </p>
            <div
              className='size-4 rounded-full cursor-pointer flex items-center justify-center'
              onClick={() => handleDelete(i)}
            >
              <X className='text-[16px]' />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RepeatedTextInput
