import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import useSignupState from './subContexts/signUpcontext'
import CreateParentFarm from './subContexts/enterpriseSignUp'

const AuthContext = createContext(undefined)

const AuthProvider = ({ children }) => {
  const { isAuthenticated, token, user } = useSelector(state => state.auth)
  const { signupState, setSignupField } = useSignupState()
  const { farm, setparentFarmField } = CreateParentFarm()

  const value = {
    isAuthenticated,
    token,
    user,
    signupState,
    setSignupField,
    farm,
    setparentFarmField
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export default AuthProvider
