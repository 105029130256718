import React from 'react'
import DataTable from '../DataTable/DataTable'
import { columns } from '../../FarmPage/FarmTableColumn/FarmTableColumn'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyFarmImage from '../../../../resources/farmemptyimage.png'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import useWindowSize from '../../UseWindowSize/UseWindowSize'
import { MobileColumns } from '../FarmTableColumn/MobileColumn'
const FarmDataTable = ({ data }) => {
  const { width } = useWindowSize()

  // Determine the columns based on screen width
  const columnsforfarm = width > 768 ? columns : MobileColumns

  const farms = data?.farms || []

  return (
    <div className='w-full bg-[#F9F9F9] rounded-md h-full px-[2%] py-[1%]'>
      <DataTable
        data={farms}
        columns={columnsforfarm}
        farmfilter='true'
        emptydatacontent={
          <EmptyDataContent
            image={EmptyFarmImage}
            text='No branches yet'
            desc='Click the add new farm button above to add start adding farms'
          />
        }
        pagination={data.length > 0 && <Pagination />}
      />
    </div>
  )
}

export default FarmDataTable
