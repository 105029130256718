import { Plus, X } from 'lucide-react'
import React, { useState } from 'react'

const DynamicTextArea = ({
  errors,
  text,
  label,
  existingTextAreas,
  register,
  name,
  nextbtn
}) => {
  const [textAreas, setTextAreas] = useState(
    existingTextAreas || [{ value: '' }]
  )

  const handleTextAreaChange = (index, fieldValue) => {
    const updatedTextAreas = [...textAreas]
    updatedTextAreas[index].value = fieldValue
    setTextAreas(updatedTextAreas)
  }

  const addTextArea = () => {
    setTextAreas([...textAreas, { value: '' }])
  }

  const removeTextArea = index => {
    const updatedTextAreas = [...textAreas]
    updatedTextAreas.splice(index, 1)
    setTextAreas(updatedTextAreas)
  }

  return (
    <div className='grid w-full items-center gap-1.5'>
      {textAreas.map((area, index) => (
        <div key={index} className=' w-full '>
          {text && (
            <label htmlFor={`textarea-${index}`}>{label && label}</label>
          )}
          <textarea
            {...register(`${name}.${index}`)}
            placeholder='Write a short description'
            value={area.value}
            onChange={e => handleTextAreaChange(index, e.target.value)}
            className='textarea rounded-lg w-full overflow-hidden bg-[#F3F5F6] h-auto lg:h-[160px]'
          />
          {index > 0 && (
            <div className=' flex justify-end items-center gap-2.5 mt-1 '>
              <X className=' text-red-300 text-[10px] ' size={14} />
              <div
                className=' text-red-300 text-[12px] cursor-pointer '
                onClick={() => removeTextArea(index)}
              >
                Remove
              </div>
            </div>
          )}
          {errors?.[text]?.[index]?.value && (
            <p className='error-text'>{errors[text][index].value.message}</p>
          )}
        </div>
      ))}
      <div className=' items-center flex gap-1 '>
        <Plus className=' text-darkGreen ' />
        <div
          onClick={addTextArea}
          className=' text-[12px] text-darkGreen lg:text-[14px] cursor-pointer '
        >
          {nextbtn}
        </div>
      </div>
    </div>
  )
}

export default DynamicTextArea
