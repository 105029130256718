import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import EmailInput from '../../../SharedComponents/EmailInput/EmailInput'
import { agriBusinessRoles } from '../../../../data/static'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import PasswordInput from '../../../SharedComponents/PasswordInput/PasswordInput'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import Modal from '../../../SharedComponents/Modal/Modal'

const AddNewStaffPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [notAllowed, setNotAllowed] = useState(false)
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })

  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm()

  const { mutate: createStaff } = useBackendService('/users', 'post', {
    onSuccess: () => {
      setModalData({
        type: 'success',
        title: 'Staff added successfully',
        desc: 'Well done you have successfully submitted a new staff.',
        link: '/staffs'
      })
      document.getElementById('my_modal_5').showModal()
    },
    onError: () => {
      setModalData({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to submit a new staff at the moment.'
      })
      document.getElementById('my_modal_5').showModal()
    }
  })

  const onSubmit = async data => {
    data.plan = 'Enterprise'
    data.farmId = user.farmId
    data.directSupervisor = user.id
    createStaff(data)
  }
  useEffect(() => {
    if (user.role !== 'administrator') {
      setNotAllowed(true)
    }
  }, [user.role])

  if (notAllowed) {
    return (
      <div className='text-center mt-20'>
        <h1 className='text-2xl font-bold text-red-500'>
          You're not allowed to access this page
        </h1>
      </div>
    )
  }

  return (
    <section className='newProductwrapper'>
      <div className='mb-[30px]'>
        <a href='/staffs'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>Add new staff</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Fill in the details of your new staff
        </p>
      </div>
      <form className='productform' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex items-center flex-col lg:flex-row gap-[20px]'>
          <TextInput
            name='firstName'
            register={register}
            errors={errors}
            text='First name'
          />
          <TextInput
            name='lastName'
            register={register}
            errors={errors}
            text='Last name'
          />
        </div>
        <div className='w-full flex items-center flex-col lg:flex-row gap-[20px]'>
          <div className='grid w-full items-center gap-1.5'>
            <h1 className='ts'>Phone Number</h1>
            <input
              type='text'
              {...register('phonenumber', {
                required: 'Phone number is required',
                pattern: {
                  value: /^[0-9]{10,}$/,
                  message: 'Invalid phone number'
                }
              })}
              className='email-input'
            />
            {errors.phonenumber && (
              <p className='text-red-500 text-xs'>
                {errors.phonenumber.message}
              </p>
            )}
          </div>
          <EmailInput register={register} errors={errors} />
        </div>
        <div className='w-full lg:w-[50%]'>
          <PasswordInput
            errors={errors}
            register={register}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
            nolink='true'
            // eslint-disable-next-line react/style-prop-object
            style='ts'
          />
        </div>
        <div className='w-full lg:w-[50%]'>
          <SelectInput
            control={control}
            Controller={Controller}
            data={agriBusinessRoles}
            title='Assign Role'
            name='role'
            placeholder='Select role'
            // eslint-disable-next-line react/style-prop-object
            style='ts'
          />
        </div>
        <button
          type='submit'
          className={`w-full lg:w-[50%] h-[50px] ts ${
            isValid
              ? 'proceed-btn bg-darkGreen h-[50px]'
              : 'rounded-[8px] bg-darkash text-black h-[50px]'
          }`}
          disabled={!isValid}
        >
          Create new staff
        </button>
      </form>
      {/* Single Modal with dynamic content */}
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />

    </section>
  )
}

export default AddNewStaffPage
