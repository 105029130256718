import React from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useAuth } from '../../../../../config/contexts/authProvider'
import { toast } from 'react-toastify'
import useBackendService from '../../../../../config/services/backend-service'

const PersonalInformation = () => {
  const { user } = useAuth()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || ''
    }
  })

  const { mutate: updateinfo } = useBackendService(
    `/users/update_id/${user.id}`,
    'put',
    {
      onSuccess: response => {
        toast.success('Changes saved successfully!')
      },
      onError: error => {
        console.error('An error occurred.', error)
        toast.error('An error occurred. Please try again.')
      }
    }
  )

  const onSubmit = async data => {
    updateinfo(data)
  }

  const fullName = `${user.first_name} ${user.last_name}`.trim()
  const email = user.email
  const phoneNumber = user.phone_number

  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <h1 className='font-medium p-3'>Personal Information</h1>
      <hr />
      <form
        className='lg:w-[80%] p-3 space-y-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          text='First Name'
          name='firstName'
          register={register}
          value={user?.firstName || ''}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Last Name'
          name='lastName'
          register={register}
          value={fullName}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Email'
          name='email'
          register={register}
          value={email}
          errors={errors}
          edit='true'
        />
        <TextInput
          text='Phone Number'
          name='phoneNumber'
          register={register}
          value={phoneNumber}
          errors={errors}
          edit='true'
        />

        <button
          type='submit'
          className='w-full h-[50px] bg-darkGreen text-white rounded-md ts'
        >
          Save Changes
        </button>
      </form>
    </div>
  )
}

export default PersonalInformation
