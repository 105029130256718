import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeft } from 'lucide-react'

function BackButton ({ size }) {
  const navigate = useNavigate()

  return (
    <div>
      <ChevronLeft
        className='text-black cursor-pointer'
        onClick={() => navigate(-1)}
        size={size && size}
      />
    </div>
  )
}

export default BackButton
