import React from 'react'

function AdminHome () {
  return (
    <div className='w-full h-full'>
      Home
    </div>
  )
}

export default AdminHome
