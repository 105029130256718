import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import {
  BasicPlanPricingContent,
  EnterprisePricingContent,
  ProPlanPricingContent
} from '../../static'
import Header from '../../components/Header/Header'
import { useAuth } from '../../../config/contexts/authProvider'

const bgImg = require('../../assets/home-hero.png')

function Home () {
  const [currentCategory, setCurrentCategory] = useState('Monthly')
  const { setSignupField } = useAuth()
  const handleClick = (planType, e) => {
    // e.preventDefault()
    setSignupField('plan', planType)
  }

  return (
    <main>
      <div
        className=' lg:h-[900px] h-[400px] bg-cover bg-center '
        style={{
          backgroundImage: `url(${bgImg})`
        }}
      >
        <Header />
        <section className='relative lg:px-[100px] lg:py-[64px] px-5 py-5 flex flex-col lg:gap-4 gap-5'>
          <div className='flex justify-start gap-10'>
            <div className='flex flex-col gap-[24px] lg:gap-[60px] lg:items-start items-start'>
              <div className='flex flex-col gap-1 lg:gap-5 bg-black bg-opacity-45 p-3 rounded-md'>
                <p className='font-[600] text-white text-[18px] lg:text-[48px] text-left lg:leading-[64px]'>
                  Your One Stop Shop <br className='hidden lg:flex' /> For All Agricultural Matters
                </p>
                <p className='lg:font-[500] text-white text-left text-[12px] lg:text-[18px] leading-[21.6px]'>
                  Optimize Your Yields, Reduce Costs, and Ensure Sustainability
                  <br className='hidden lg:flex' /> with Our Cutting-Edge
                  Solution for your agricultural investments
                </p>
              </div>
              <div className='flex w-full justify-start items-center'>
                <Link to='about'>
                  <div className='lg:py-[10px] lg:mb-0 mb-20 p-2 lg:px-6 bg-primary rounded-md cursor-pointer'>
                    <p className='font-[500] text-[#232221] text-[12px] lg:text-[16px]'>
                      Get started with T-GAIMS
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className='lg:px-[100px] px-5 py-10 lg:py-[64px] gap-10 flex flex-col'>
        <div className='lg:w-[50%]'>
          <p className='text-primary font-[600] lg:font-[600] text-[24px]  lg:text-[40px] lg:text-start text-center '>
            Comprehensive tools designed to streamline your operations
          </p>
          <p className='lg:font-[400] text-[#666666] text-[12px] my-2 lg:text-[20px] lg:my-5 text-center lg:text-start '>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small scale entreprise), our features ensure you
            have everything you need to succeed
          </p>
        </div>

        <div className=' flex lg:flex-row flex-col-reverse gap-10 justify-between items-center '>
          <div className='flex flex-col gap-10 lg:gap-20 lg:w-[50%]  '>
            <div className=' flex flex-row gap-5 '>
              <div className=' flex flex-[0.5] flex-col gap-3  '>
                <img
                  src={require('../../assets/plug.png')}
                  alt=''
                  className=' w-[20px] lg:w-[37px] h-[19px] lg:h-[36px] '
                />
                <div className=' flex flex-col gap-2 '>
                  <p className='font-[500] text-[14px] lg:text-[20px] text-[#232221] '>
                    Expert support
                  </p>
                  <p className='text-grey font-[500] text-[12px] lg:text-[16px] leading-[24px] '>
                    Access dedicated support team and comprehensive resources to
                    help maximize the system’s potential.
                  </p>
                </div>
              </div>
              <div className=' flex flex-[0.5] flex-col gap-3  '>
                <img
                  src={require('../../assets/eff.png')}
                  alt=''
                  className=' w-[20px] lg:w-[37px] h-[19px] lg:h-[36px] '
                />
                <div className=' flex flex-col gap-2 '>
                  <p className='font-[500] text-[14px] lg:text-[20px] text-[#232221] '>
                    Efficient resources management
                  </p>
                  <p className='text-grey font-[500] text-[12px] lg:text-[16px] leading-[24px] '>
                    Optimize use of inputs like water, fertilizers and
                    pesticides with precise tool.
                  </p>
                </div>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <div className=' flex flex-[0.5] flex-col gap-3  '>
                <img
                  src={require('../../assets/battery.png')}
                  alt=''
                  className=' w-[20px] lg:w-[37px] h-[19px] lg:h-[36px] '
                />
                <div className=' flex flex-col gap-2 '>
                  <p className='font-[500] text-[14px] lg:text-[20px] text-[#232221] '>
                    Real-time data monitoring
                  </p>
                  <p className='text-grey font-[500] text-[12px] lg:text-[16px] leading-[24px] '>
                    Track your animal and crop health, soil moisture, and
                    weather conditions with precision sensors and satellite
                    imageries.
                  </p>
                </div>
              </div>
              <div className=' flex flex-[0.5] flex-col gap-3  '>
                <img
                  src={require('../../assets/laptop.png')}
                  alt=''
                  className=' w-[20px] lg:w-[37px] h-[19px] lg:h-[36px] '
                />
                <div className=' flex flex-col gap-2 '>
                  <p className='font-[500] text-[14px] lg:text-[20px] text-[#232221] '>
                    Customizable dashboard
                  </p>
                  <p className='text-grey font-[500] text-[12px] lg:text-[16px] leading-[24px] '>
                    Tailor dashboard to display relevant data for operations.
                    Use intuitive controls to visualize agri business metrics.
                  </p>
                </div>
              </div>
            </div>
            <div className=' flex flex-row gap-5 '>
              <div className=' flex flex-[0.5] flex-col gap-3  '>
                <img
                  src={require('../../assets/eff.png')}
                  alt=''
                  className=' w-[20px] lg:w-[37px] h-[19px] lg:h-[36px] '
                />
                <div className=' flex flex-col gap-2 '>
                  <p className='font-[500] text-[14px] lg:text-[20px] text-[#232221] '>
                    Investment platform management
                  </p>
                  <p className='text-grey font-[500] text-[12px] lg:text-[16px] leading-[24px] '>
                    Plan, manage and track your agricultural investment
                    portfolio and multiply your margins using our robust Agri
                    investment management tool.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=' lg:w-[500px] w-full '>
            <img
              src={require('../../assets/operations.jpg')}
              alt=''
              className=' w-full h-[470px] lg:h-[670px] '
            />
          </div>
        </div>
      </section>
      <section className=' lg:px-[100px] px-5 py-10 lg:py-[64px] gap-10 flex flex-col '>
        <div className=' flex flex-col text-center gap-3 '>
          <p className=' font-[800] lg:font-[800] text-[24px] lg:text-[48px] text-center text-primary '>
            How T-gaims Works
          </p>
          <p className=' text-grey lg:text-[20px] text-[14px] text-center '>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small scale entreprise),
            <br className=' hidden lg:flex ' />
            our features ensure you have everything you need to succeed.
          </p>
        </div>

        <div className=' flex flex-col lg:items-center lg:justify-center lg:flex-row lg:gap-40 gap-10  '>
          <div className=' w-full lg:w-[580px] '>
            <img
              src={require('../../assets/Rectangle 216.png')}
              alt=''
              className=' w-full h-[400px] lg:h-[788px]   '
            />
          </div>
          <div className=' flex flex-col gap-10 lg:gap-40 max-w-fit text-start  '>
            <div className=' flex flex-col gap-5  '>
              <div className=' flex flex-row gap-5 '>
                <img
                  src={require('../../assets/icon.png')}
                  alt=''
                  className=' lg:w-[40px] w-[25px] h-[25px] lg:h-[40px] '
                />
                <div className=' lg:w-[70%] gap-3 flex flex-col '>
                  <p className='font-[500] lg:text-[22px] text-black   '>
                    Sign Up
                  </p>
                  <p className=' font-[400] lg:text-[16px] text-grey '>
                    Create an account and set up your personal and business
                    profile. Enter details about operation.
                  </p>
                </div>
              </div>
              <div className=' flex flex-row gap-5 '>
                <img
                  src={require('../../assets/icon-1.png')}
                  alt=''
                  className=' lg:w-[40px] w-[25px] h-[25px] lg:h-[40px] '
                />
                <div className=' lg:w-[60%] gap-3 flex flex-col '>
                  <p className='font-[500] lg:text-[22px] text-black  '>
                    Monitor
                  </p>
                  <p className=' font-[400] lg:text-[16px] text-grey '>
                    Track real-time data from your farm. Use our intuitive
                    dashboard to visualize key metrics and receive alerts.
                  </p>
                </div>
              </div>
              <div className=' flex flex-row gap-5 '>
                <img
                  src={require('../../assets/icon-2.png')}
                  alt=''
                  className=' lg:w-[40px] w-[25px] h-[25px] lg:h-[40px] '
                />
                <div className=' lg:w-[60%] gap-3 flex flex-col '>
                  <p className='font-[500] lg:text-[22px] text-black '>Grow</p>
                  <p className=' font-[400] lg:text-[16px] text-grey '>
                    Watch your agri investments and business thrive with improved efficiency.
                    For lower cost and apply sustainable practices.
                  </p>
                </div>
              </div>
              <div className=' flex flex-row gap-5 '>
                <img
                  src={require('../../assets/icon-3.png')}
                  alt=''
                  className=' lg:w-[40px] w-[25px] h-[25px] lg:h-[40px] '
                />
                <div className=' lg:w-[70%] gap-3 flex flex-col '>
                  <p className='font-[500] lg:text-[22px] text-black '>
                    Integrate
                  </p>
                  <p className=' font-[400] lg:text-[16px] text-grey '>
                    Connect your existing tools and systems to our platform. Our
                    seamless integration process makes it easy.
                  </p>
                </div>
              </div>
              <div className=' flex flex-row gap-5 '>
                <img
                  src={require('../../assets/icon-4.png')}
                  alt=''
                  className=' lg:w-[40px] w-[25px] h-[25px] lg:h-[40px] '
                />
                <div className=' lg:w-[70%] gap-3 flex flex-col '>
                  <p className='font-[500] lg:text-[22px] text-black '>
                    Optimize
                  </p>
                  <p className=' font-[400] lg:text-[16px] text-grey '>
                    Use our advanced tools to optimize resource usage. Implement
                    sustainable practice and boost investment returns.
                  </p>
                </div>
              </div>
            </div>
            <Link to='/signup'>
              <div className=' rounded-lg lg:py-[21px] flex justify-center self-start lg:items-start items-center bg-primary p-3 lg:px-[20px] cursor-pointer '>
                <p className=' text-primary text-center lg:text-[18px] text-[12px] font-[600] '>
                  Get started now
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className='flex flex-col gap-5 lg:gap-10 items-center lg:px-[100px] px-5 py-3 lg:py-[64px]  '>
        <div className='flex flex-col gap-1 lg:gap-[10px] '>
          <p className=' font-[800] text-[24px] lg:text-[40px] text-primary text-center '>
            Pricing
          </p>
          <p className='font-[400] text-[20px] text-grey '>No surprise fees.</p>
        </div>
        <div>
          <Category
            category={currentCategory}
            onCategoryChange={setCurrentCategory}
          />
        </div>
        <div className=' flex flex-col lg:flex-row gap-5  lg:gap-10 items-center lg:items-end '>
          <div className='p-[25px] lg:p-[45px] lg:w-[400px]'>
            <div className='flex flex-col gap-3 lg:gap-4 text-black'>
              <p className='  text-[18px] lg:text-[36px] font-[600] text-primary '>
                Free
              </p>
              <div className=' flex flex-col gap-[1px] '>
                <p className=' text-[14px] lg:text-[28px] font-[600] text-black '>
                  Basic Plan
                </p>
                <p>Ideal for small scale farms</p>
              </div>
              <div className=' flex flex-col gap-1 lg:gap-2 '>
                {BasicPlanPricingContent.map(item => (
                  <div className='flex flex-row gap-1 ' key={item.id}>
                    <img
                      src={require('../../assets/tick-circle_2.png')}
                      alt=''
                      className='lg:w-5 lg:h-5 w-3 h-3 '
                    />
                    <p className=' text-[12px] lg:text-[16px] '>
                      {item.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <Link to='/signup/basicplan' onClick={e => handleClick('Basic', e)}>
              <div className=' w-[336px] bg-[#144618] cursor-pointer items-center justify-center py-2 rounded-lg mt-10 lg:mt-20  '>
                <p className='text-white text-center text-[15px] font-[600] '>
                  Choose plan
                </p>
              </div>
            </Link>
          </div>
          <div className=' flex flex-col bg-[#144618] rounded-lg p-[25px] lg:p-[30px] gap-3 lg:w-[400px]  '>
            <div className=' justify-end flex '>
              <div className='w-[121px] bg-white py-2 px-2 rounded-lg '>
                <p className='text-primary text-center text-[10px] font-[600] '>
                  MOST POPULAR
                </p>
              </div>
            </div>
            <p className='font-[700]  text-[18px] lg:text-[36px] text-white '>
              {currentCategory === 'Monthly' ? '₦5,000' : '₦60,000'}
              <span className=' text-[12px] lg:text-[17px]'>
                {currentCategory === 'Monthly' ? '/month' : 'yearly'}
              </span>
            </p>
            <div>
              <p className='font-[700] text-[16px] lg:text-[28px] text-white '>
                Pro Plan
              </p>
              <p className='font-[400] text-[12px] lg:text-[16px] text-white '>
                Perfect for Growing Farms
              </p>
            </div>
            <div className=' flex flex-col gap-1 lg:gap-1 '>
              {ProPlanPricingContent.map(item => (
                <div className='flex flex-row gap-2 w-[95%] ' key={item.id}>
                  <img
                    src={require('../../assets/check-circle-1.png')}
                    alt=''
                    className='lg:w-5 lg:h-5 w-3 h-3 '
                  />
                  <p className=' text-white text-[12px] lg:text-[16px] '>
                    {item.content}
                  </p>
                </div>
              ))}
            </div>
            <div className=' flex flex-row justify-center items-center '>
              <Link to='/signup/basicplan' onClick={e => handleClick('Pro', e)}>
                <div className=' w-[336px] self-center cursor-pointer bg-white mt-10 lg:mt-20 items-center justify-center py-2 rounded-lg '>
                  <p className='text-primary text-center text-[15px] font-[600] '>
                    Choose plan
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className=' p-[25px]  lg:p-[30px] lg:w-[400px] '>
            <div className='flex flex-col gap-3 lg:gap-4 text-black'>
              <p className='font-[700] text-[18px] lg:text-[36px] text-primary '>
                Get a quote
                <span className=' text-[12px] lg:text-[17px] font-[400]  text-black'>
                  <span className=' text-[12px] lg:text-[17px]' />
                </span>
              </p>
              <div>
                <p className='  text-[16px] lg:text-[28px] font-[600] '>
                  Enterprise Plan
                </p>
                <p className='font-[400] text-[12px] lg:text-[16px]  '>
                  Plan for Large-Scale Operations
                </p>
              </div>
              <div className=' flex flex-col gap-1 lg:gap-2 '>
                {EnterprisePricingContent.map(item => (
                  <div
                    className='flex flex-row gap-1 items-start '
                    key={item.id}
                  >
                    <img
                      src={require('../../assets/tick-circle_2.png')}
                      alt=''
                      className='lg:w-5 lg:h-5 w-3 h-3 '
                    />
                    <p className=' text-[12px] lg:text-[16px] '>
                      {item.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <Link
              to='/signup/enterpriseplan'
              onClick={e => handleClick('Enterprise', e)}
            >
              <div className=' w-[336px] bg-[#144618] cursor-pointer items-center justify-center py-2 rounded-lg mt-10 lg:mt-20  '>
                <p className='text-white text-center text-[15px] font-[600] '>
                  Choose plan
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className='flex flex-col gap-10 items-center lg:px-[100px] px-5 py-10 lg:py-[80px] '>
        <div className=' flex flex-col gap-3 text-center '>
          <p className=' font-[600] text-[30px] lg:text-[40px] text-primary '>
            Frequently Asked Questions
          </p>
          <p className=' text-grey lg:text-[20px] text-[14px] text-center '>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small <br className=' hidden lg:flex ' /> scale
            entreprise), our features ensure you have everything you need to
            succeed.
          </p>
        </div>
        <div className=' flex flex-col lg:flex-row  gap-10 '>
          <div className=' flex flex-col lg:flex-[0.5] min-h-full  gap-4 justify-between'>
            <div className=' flex flex-row w-[full]  lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/question.png')}
                className=' w-5 lg:w-9 h-5 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[14px] lg:text-[22px] text-black font-[500] '>
                  What if I don't have a farm can I use T-Gaims
                </p>
                <p className=' text-[12px] text-grey lg:leading-[30px] lg:text-[20px] '>
                  Absolutely! Engaging with our agricultural website offers a
                  multitude of benefits beyond farm ownership. Whether you're
                  interested in gardening at home, supporting local farmers,
                  learning about sustainable practices, or connecting with a
                  community passionate about agriculture, our platform provides
                  valuable resources, knowledge, and opportunities to enrich
                  your agricultural journey
                </p>
              </div>
            </div>
          </div>
          <div className=' flex flex-col lg:flex-[0.5] gap-4 justify-between'>
            <div className=' flex flex-row w-[full] lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/question.png')}
                className=' w-5 lg:w-9 h-5 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[14px] lg:text-[22px] text-black font-[500] '>
                  Why should I subscribe to T-Gaims?
                </p>
                <p className=' text-[12px]  text-grey lg:leading-[30px] lg:text-[20px] '>
                  Subscribing to TGAIM provides access to industry insights,
                  networking opportunities with professionals in agriculture,
                  educational resources to enhance skills, potential business
                  collaborations, and active community engagement. It's a
                  valuable resource for staying informed, expanding professional
                  networks, and exploring opportunities within the agricultural
                  sector and related fields.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=' flex flex-col lg:flex-row  gap-10 '>
          <div className=' flex flex-col lg:flex-[0.5] gap-4 justify-between min-h-ful '>
            <div className=' flex flex-row w-[full] min-h-full lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/question.png')}
                className=' w-5 lg:w-9 h-5 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[14px] lg:text-[22px] text-black font-[500] '>
                  How many of my businesses can I register on T-Gaims?
                </p>
                <p className=' text-[12px] ] lg:leading-[30px] lg:text-[20px] text-grey '>
                  As many Agribusinesses as you own. You shall always find
                  T-Gaims useful for all Agribusiness value chains and
                  Agricultural investments.
                </p>
              </div>
            </div>
          </div>
          <div className=' flex flex-col gap-4 min-h-full lg:flex-[0.5]  justify-between'>
            <div className=' flex flex-row w-[full] min-h-full lg:flex-[0.5] bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/question.png')}
                className=' w-5 lg:w-9 h-5 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[14px] lg:text-[22px] text-black font-[500] '>
                  Who can use T-Gaims
                </p>
                <p className=' text-[12px] text-grey  lg:leading-[30px] lg:text-[20px] '>
                  Whether you're overseeing a large-scale enterprise or a small
                  farm, our features ensure you have everything you need to
                  succeed.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=' flex flex-col lg:flex-row lg:justify-center justify-start  gap-10 '>
          <div className=' flex flex-col lg:flex-[0.5] h-[250px] gap-4 justify-between'>
            <div className=' flex flex-row w-[full] lg:flex-[0.5] min-h-full bg-[#F7F7F7] gap-4 rounded-lg p-[15px] lg:p-[30px]  '>
              <img
                src={require('../../assets/question.png')}
                className=' w-5 lg:w-9 h-5 lg:h-9 '
                alt=''
              />
              <div className=' flex flex-col gap-3 '>
                <p className=' text-[14px] lg:text-[22px] text-black font-[500] '>
                  What is T-Gaims?
                </p>
                <p className=' text-[12px] text-grey lg:leading-[30px] lg:text-[20px] '>
                  T-Gaims is your one-stop shop for all Agricultural matters
                  (Agri-investment, Agri-Management, Agri-Business, Agriculture
                  News, and Agri-games)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col gap-5  items-center lg:px-[100px] px-3 py-5 lg:py-[64px]   '>
        <p className=' text-primary font-[600] text-[30px] lg:text-[50px] text-center mb-2 lg:leading-[68px] '>
          Rapid setup, <br /> Easy deployment
        </p>
        <div className=' flex flex-row '>
          <div className='flex flex-row items-center gap-3 justify-center font-[500] text-[10px] lg:text-[16px] text-[#263238] '>
            <p> Enterprise grade security </p>
            <div className=' w-[6px] h-[6px] rounded-full bg-[#263238] ' />
            <p> Seamless onboarding </p>
            <div className=' w-[6px] h-[6px] rounded-full bg-[#263238] ' />
            <p> Timely reporting</p>
          </div>
        </div>
        <div className='flex flex-1 bg-[#F3F5F6] py-[7px] pl-5 pr-[1px] rounded-lg  flex-row lg:items-start items-center my-5 '>
          <input
            placeholder='Enter your email to get started'
            className=' appearance-none leading-tight text-[12px] mt-1 min-w-max lg:text-[16px] focus:outline-none focus:shadow-outline rounded-lg border-[0px] bg-[#F3F5F6] '
            type='text'
          />
          <div className=' bg-[#144618] text-white rounded-lg p-1 lg:px-5 lg:py-4  cursor-pointer '>
            <p className=' text-[10px] lg:text-[18px] font-[600] '>Subscribe</p>
          </div>
        </div>
      </section>
      <section>
        <img
          src={require('../../assets/Frame 428.png')}
          className=' w-full h-[100px] lg:h-[400px]  '
          alt=''
        />
      </section>
      <Footer />
    </main>
  )
}

const Category = ({ category, onCategoryChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(category)

  const handleCategoryChange = item => {
    setSelectedCategory(item)
    onCategoryChange(item)
    console.log(selectedCategory)
  }

  return (
    <div className=' flex flex-row items-center bg-[#EEEEEE] rounded-lg p-1 '>
      {['Monthly', 'Yearly'].map((item, index) => (
        <div
          key={index}
          className={
            selectedCategory === item
              ? 'bg-[#144618] rounded-lg cursor-pointer '
              : 'cursor-pointer'
          }
          onClick={() => handleCategoryChange(item)}
        >
          <div className=' px-4 lg:px-8 lg:py-4 py-2 '>
            <p
              className={
                selectedCategory === item
                  ? ' font-[800] text-white text-[12px] lg:text-[16px] '
                  : ' text-[#848199] text-[12px] lg:text-[16px] font-[800] '
              }
            >
              {item}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Home
