import React, { useState } from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-black.png'
import LogoWhite from '../../../resources/logo-white.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../config/contexts/authProvider'

// card data plan
const cardsData = [
  {
    id: 'basicplan',
    title: 'Basic Account',
    description:
      'Our Basic account are tailored for small farm owners, offering essential tools to efficiently manage smaller-scale farms.'

  },
  {
    id: 'proplan',
    title: 'Pro Account',
    description:
      'Our Pro account are tailored for small farm owners, offering essential tools to efficiently manage smaller-scale farms.'

  },
  {
    id: 'enterpriseplan',
    title: 'Enterprise Account',
    description:
      'This account is designed for large-scale farm owners, providing advanced tools for comprehensive farm management.'

  }

]

const Planhomepage = () => {
  const [selectedCard, setSelectedCard] = useState(null)
  const navigate = useNavigate()
  const { setSignupField } = useAuth()

  const handleCardClick = cardId => {
    setSelectedCard(cardId)
  }
  const handleSubmit = () => {
    if (selectedCard) {
      let planType
      switch (selectedCard) {
        case 'basicplan':
          planType = 'Basic'
          break
        case 'proplan':
          planType = 'Pro'
          break
        case 'enterpriseplan':
          planType = 'Enterprise'
          break
        default:
          planType = ''
      }

      setSignupField('plan', planType)
      navigate(`/signup/${selectedCard}`)
    }
  }

  return (
    <div className='wrapper'>
      {/* left content */}
      <div className='left-content'>
        <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
          <img alt='' src={LogoWhite} className='w-[80px] lg:w-[100px]' />
        </div>
        <img alt='' src={Image} className='object-contain' />
        <div className='image-text-wrapper'>
          <h1 className='text-2xl lg:text-5xl w-full'>
            Manage your <br /> agribusiness efficiently
          </h1>
          <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
            Building the best and more efficient platform for agribusiness
            management.
          </p>
        </div>
      </div>
      {/* right content */}
      <div className='right-content'>
        <div className='w-full center'>

          <img alt='' src={Logo} className=' w-[80px] lg:w-[100px]' />
        </div>

        <div className='w-full mt-[5%] space-y-1'>
          <h1 className='header-text'>How are you planning to use Tgaims?</h1>
          <p className='text-black text-sm'>
            We’ll streamline your setup experience accordingly
          </p>
        </div>

        {/* plan card container */}

        <div className='plancover'>
          {cardsData.map(card => (
            <div
              className={`w-full h-[120px] ${
                selectedCard === card.id ? 'activeplan' : 'unactiveplan'
              }`}
              key={card.id}
              onClick={() => handleCardClick(card.id)}
            >

              <h1 className='text-xl lg:text-2xl'>{card.title}</h1>
              <p className='text-xs lg:text-sm '>{card.description}</p>
            </div>
          ))}
        </div>

        <div className='w-full space-y-3 mt-[10%]'>
          <button onClick={handleSubmit} className='btnn'>
            Create account
          </button>
          <h1 className='login-text'>
            Have an account?{' '}
            <a href='/login' className='login-link'>
              Log in
            </a>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Planhomepage
