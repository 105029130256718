import React from 'react'
import InvestContainer from '../InvestContainer/InvestContainer'

const data = [
  {
    id: 1,
    title: 'Cattle Cattle',
    content: '8.5% returns in 6 months',
    img: require('../../../resources/cow.png'),
    amount: 113
  },
  {
    id: 2,
    title: 'Cattle decrease',
    content: '50% returns in 1 year',
    img: require('../../../resources/cattle.png'),
    amount: 113
  },
  {
    id: 3,
    title: 'Maize inflation',
    content: '5% returns in 12 months',
    img: require('../../../resources/maize.png'),
    amount: 113
  },
  {
    id: 11,
    title: 'Cattle Cattle',
    content: '8.5% returns in 6 months',
    img: require('../../../resources/cow.png'),
    amount: 113
  },
  {
    id: 21,
    title: 'Cattle decrease',
    content: '50% returns in 1 year',
    img: require('../../../resources/cattle.png'),
    amount: 113
  },
  {
    id: 31,
    title: 'Maize inflation',
    content: '5% returns in 12 months',
    img: require('../../../resources/maize.png'),
    amount: 113
  },
  {
    id: 12,
    title: 'Cattle Cattle',
    content: '8.5% returns in 6 months',
    img: require('../../../resources/cow.png'),
    amount: 113
  },
  {
    id: 22,
    title: 'Cattle decrease',
    content: '50% returns in 1 year',
    img: require('../../../resources/cattle.png'),
    amount: 113
  },
  {
    id: 32,
    title: 'Maize inflation',
    content: '5% returns in 12 months',
    img: require('../../../resources/maize.png'),
    amount: 113
  }
]

function RegularInvest () {
  return (
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-10 p-5 '>
      {data.map(item => (
        <div className=' lg:w-full w-[full] ' key={item.id}>
          <InvestContainer item={item} />
        </div>
      ))}
    </div>
  )
}

export default RegularInvest
