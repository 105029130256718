import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../store/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/authProvider'

const AutoLogout = () => {
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onlineCheckInterval = useRef(null)
  let inactivityTimer, awayTimer

  const resetTimers = () => {
    clearTimeout(inactivityTimer)
    clearTimeout(awayTimer)

    inactivityTimer = setTimeout(() => {
      handleLogout()
    }, 3 * 60 * 60 * 1000)

    awayTimer = setTimeout(() => {
      handleLogout()
    }, 3 * 60 * 60 * 1000)
  }

  const checkOnlineStatus = () => {
    if (navigator.onLine) {
      localStorage.setItem('lastOnline', Date.now().toString())
    } else {
      const lastOnline = parseInt(localStorage.getItem('lastOnline'), 10)
      const offlineDuration = Date.now() - lastOnline

      if (offlineDuration >= 3 * 60 * 60 * 1000) {
        handleLogout()
      }
    }
  }

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  useEffect(() => {
    if (isAuthenticated) {
      resetTimers()
      checkOnlineStatus()

      onlineCheckInterval.current = setInterval(checkOnlineStatus, 60 * 1000)

      window.addEventListener('mousemove', resetTimers)
      window.addEventListener('keydown', resetTimers)
      window.addEventListener('mousedown', resetTimers)
      window.addEventListener('touchstart', resetTimers)

      return () => {
        window.removeEventListener('mousemove', resetTimers)
        window.removeEventListener('keydown', resetTimers)
        window.removeEventListener('mousedown', resetTimers)
        window.removeEventListener('touchstart', resetTimers)

        clearTimeout(inactivityTimer)
        clearTimeout(awayTimer)
        if (onlineCheckInterval.current) {
          clearInterval(onlineCheckInterval.current)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return null
}

export default AutoLogout
