import React, { useState } from 'react'
import { ArrowLeft } from 'lucide-react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import './SalesDescription.css'
import { OtherSeries, TotalRevenueSeries, salesproductsdetails } from '../../../../data/static'
import SalesDropdown from './SalesDropdown/SalesDropdown'

// Register the components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

// Function to update datasets with random colors
const applyRandomColors = (series) => {
  return {
    ...series,
    datasets: series.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: getRandomColor(),
      borderColor: getRandomColor()
    }))
  }
}

const SalesDescription = () => {
  const [selectedSeries, setSelectedSeries] = useState(applyRandomColors(TotalRevenueSeries))
  const [selectedSeriesName, setSelectedSeriesName] = useState('TotalRevenueSeries')

  const handleSelectChange = (e) => {
    const seriesName = e.target.value
    setSelectedSeriesName(seriesName)
    const newSeries = seriesName === 'TotalRevenueSeries' ? TotalRevenueSeries : OtherSeries
    setSelectedSeries(applyRandomColors(newSeries))
  }

  return (
    <div className='w-full h-full p-[2%] bg-white'>
      <div className='w-[5%]'>
        <a href='/sales'>
          <ArrowLeft />
        </a>
      </div>
      <div className='w-full center gap-3 lg:flex-row flex-col mt-2'>
        {/* image */}
        <div className='w-full lg:w-[30%] bg-darkGreen text-white p-[4%] lg:p-[2%] ts rounded-md space-y-2'>
          <h1 className='font-semibold capitalize'>{salesproductsdetails.title}</h1>
          <div className='w-full h-[200px]'>
            <img src={salesproductsdetails.img} alt='product' className='w-full h-full object-cover rounded-lg' />
          </div>
          <div className='w-full flex flex-wrap'>
            <h1 className='font-bold'>Best place to plant:</h1>
            <div className='w-[100%] flex flex-wrap'>
              {salesproductsdetails.place.map((item, index) => (
                <p key={index}>
                  {item}{index < salesproductsdetails.place.length - 1 ? ', ' : ''}
                </p>
              ))}
            </div>
          </div>
          <div className='w-full flex flex-wrap'>
            <h1 className='font-bold'>Best place to sell:</h1>
            <div className='w-[100%] flex flex-wrap'>
              {salesproductsdetails.sell.map((item, index) => (
                <p key={index}>
                  {item}{index < salesproductsdetails.sell.length - 1 ? ', ' : ''}
                </p>
              ))}
            </div>
          </div>
        </div>
        {/* chart */}
        <div className='w-full lg:w-[70%]'>
          <div className='w-full flex justify-between items-center'>
            <h1 className='ts'>Market Price</h1>
            <select value={selectedSeriesName} onChange={handleSelectChange} className='ts border border-none outline-none'>
              <option value='TotalRevenueSeries'>This year</option>
              <option value='OtherSeries'>This month</option>
            </select>
          </div>
          <Bar data={selectedSeries} />
        </div>
      </div>
      <SalesDropdown salesproductsdetails={salesproductsdetails} />
    </div>
  )
}

export default SalesDescription
