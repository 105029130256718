import React from 'react'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'

function Sales ({ handlePrev, register, errors, isValid }) {
  return (
    <div
      className=' flex flex-col gap-5 lg:gap-10 max-w-fit  '
    >
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='storageSolutions'
          register={register}
          errors={errors}
          text='Storage Solutions'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='packagingMethods'
          register={register}
          errors={errors}
          text='Packaging Methods'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='marketPrices'
          register={register}
          errors={errors}
          text='Market Prices'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='logisticsProviders'
          register={register}
          errors={errors}
          text='Logistics Providers'
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='salesChannels'
          register={register}
          errors={errors}
          text='Sales Channels'
        />
      </div>
      <div className=' flex flex-row items-center gap-[15px] lg:gap-[30px] '>
        <div
          className={`w-full flex items-center justify-center  lg:w-[210px] h-[50px] ${
            isValid
              ? 'proceed-btn cursor-pointer bg-darkash text-primary border border-darkGreen'
              : 'rounded-[8px] bg-darkash text-primary border border-darkGreen '
          }`}
          onClick={handlePrev}
        >
          Previous
        </div>
        <button
          type='submit'
          className={`w-full lg:w-[210px] h-[50px] ${
            isValid
              ? 'proceed-btn cursor-pointer bg-darkGreen'
              : 'rounded-[8px] bg-darkash text-black'
          }`}
          disabled={!isValid}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default Sales
