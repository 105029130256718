import { useState } from 'react'

const CreateParentFarm = () => {
  const [farm, setFarm] = useState({
    email: '',
    phoneNumber: '',
    isParentFarm: true
  })

  const setparentFarmField = (field, value) => {
    setFarm(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  return { farm, setparentFarmField }
}

export default CreateParentFarm
