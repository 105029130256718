import { MoreHorizontal } from 'lucide-react'

export const columns = [
  {
    accessorKey: 'farm_name',
    header: 'Farm name'

  },
  {
    accessorKey: 'created_by',
    header: 'Created by'
  },

  {
    accessorKey: 'created_at',
    header: 'Date',
    cell: context => {
      const value = context.getValue()
      const formattedDate = new Date(value).toLocaleDateString()
      return <span>{formattedDate}</span>
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Time',
    cell: context => {
      const value = context.getValue()
      const formattedTime = new Date(value).toLocaleTimeString()
      return <span>{formattedTime}</span>
    }
  },

  {
    accessorKey: 'action',
    header: 'Action',

    enableHiding: false,
    cell: () => {
      return (
        <details className='dropdown'>
          <summary className='btn border-none shadow-none'>
            {' '}
            <MoreHorizontal className='h-4 w-4' />
          </summary>
          <ul className='menu dropdown-content bg-white rounded-md z-[1] right-0 w-40 p-2 shadow'>
            <h1 className='center'>View</h1>
          </ul>
        </details>
      )
    }
  }
]
