import React from 'react'
import { useForm } from 'react-hook-form'
import EmailInput from '../../SharedComponents/EmailInput/EmailInput'
import CreatePassword from '../../SharedComponents/CreatePaasword/CreatePassword'
import { useAuth } from '../../../../config/contexts/authProvider'

const BasicPlanAccountSignUp = ({ next }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    trigger
  } = useForm()
  const { setSignupField } = useAuth()

  const password = watch('password')
  const confirmPassword = watch('confirmpassword')

  const handlePasswordChange = async e => {
    await trigger('password')
  }

  // function to submitting the form data
  const onSubmit = async data => {
    const isValid = await trigger(['password', 'confirmpassword', 'email'])
    if (!isValid) return
    setSignupField('email', data.email)
    setSignupField('password', data.password)

    if (next) next()
  }
  return (
    <>
      <CreatePassword
        password={password}
        confirmPassword={confirmPassword}
        watch={watch}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isDirty={isDirty}
        isValid={isValid}
        register={register}
        onSubmit={onSubmit}
        title='Basic Account'
        desc='Kindly fill in your details to setup your account'
        EmailInput={EmailInput}
      />
    </>
  )
}

export default BasicPlanAccountSignUp
