import React from 'react'
import './ForYouSection.css'
import { foryouproduct } from '../../../../data/static'

const formatDate = (dateString) => {
  const date = new Date(dateString)

  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const ForYouSection = () => {
  return (
    <div className='w-full mt-[2%]'>
      <h1 className='w-full font-semibold'>For you</h1>
      <div className='carousel rounded-box w-full flex gap-2'>
        {/* card */}
        {foryouproduct.map((item) => (
          <div className='cover' key={item.id}>
            <div className='w-full  h-[180px]'>
              <img src={item.img} alt='cow' className='object-cover rounded-lg w-full h-full' />
            </div>
            <div className='w-full flex gap-2 justify-start items-center ts'>
              <div className='circle' />
              {formatDate(item.date)}
            </div>
            <h1 className='font-semibold'>{item.title}</h1>
            <p className='text-gray-500 text-sm'>{item.content}
              <div className='font-semibold italic text-darkGreen underline'>
                <a href={`/agrinews/${item.id}`}>Read More</a>
              </div>
            </p>
          </div>
        ))}
        {/* card end */}
      </div>
    </div>
  )
}

export default ForYouSection
