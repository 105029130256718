import { CircleAlert, CloudUpload, X } from 'lucide-react'
import React, { useRef } from 'react'

function ImageUpload ({ name, images, setImages }) {
  const inputRef = useRef(null)

  const selectFiles = () => {
    inputRef.current.click()
  }

  const onFilesSelect = event => {
    const files = event.target.files
    if (files.length === 0) return
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] !== 'image') continue
      if (!images.some(e => e.name === files[i].name)) {
        setImages(prevImages => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
            file: files[i]
          }
        ])
      }
    }
  }

  const onDragOver = event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
  }

  const onDragLeave = event => {
    event.preventDefault()
  }

  const onDrop = event => {
    event.preventDefault()
    const files = event.dataTransfer.files
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] !== 'image') continue
      if (!images.some(e => e.name === files[i].name)) {
        setImages(prevImages => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
            file: files[i]
          }
        ])
      }
    }
  }

  const deleteImage = index => {
    setImages(previousImages => previousImages.filter((_, i) => i !== index))
  }

  return (
    <div className=' flex flex-col gap-4 lg:gap-8 '>
      <div
        className='w-full center flex-col rounded-md border-dashed border ts h-full gap-2 p-4'
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <CloudUpload className='text-black rounded-full bg-[#F0F2F5] w-[50px] h-[50px]' />
        <h1 className='text-[#98A2B3]'>
          <span className='text-darkGreen cursor-pointer font-semibold'>
            Click to upload
          </span>{' '}
          or drag and drop
        </h1>
        <h1 className='text-[#98A2B3]'>SVG, PNG, JPG (max. 800x400px)</h1>
        <div className='center gap-2 w-full text-[#98A2B3]'>
          <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
          OR
          <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
        </div>
        <button
          type='button'
          className='bg-darkGreen text-white ts w-[200px]  rounded-md mx-auto h-[50px]'
          onClick={selectFiles}
        >
          Browse Files
        </button>
        <input
          type='file'
          accept='.svg, .png, .jpg, .jpeg'
          hidden
          name={name}
          multiple
          ref={inputRef}
          onChange={onFilesSelect}
        />
      </div>
      <div className=' flex flex-row  items-center gap-4 lg:gap-2 '>
        {images.map((images, index) => (
          <div
            className='size-[120px] relative rounded-lg overflow-hidden '
            key={index}
          >
            <div
              className='flex absolute bg-secondary top-[1px] right-[1px] rounded-full size-4 items-center justify-center cursor-pointer '
              onClick={() => deleteImage(index)}
            >
              <X className='text-darkGreen' size={12} />
            </div>
            <img
              src={images.url || images}
              alt={images.name}
              className=' w-full h-full object-cover '
            />
          </div>
        ))}
      </div>
      <div className=' flex items-center gap-1 '>
        <CircleAlert
          size={20}
          className={`${images.length === 0 ? ' text-grey' : 'text-[#e35b67]'}`}
        />
        {images.length < 4 && (
          <p
            className={`font-[500] text-[12px] lg:text-[12px] ${
              images.length === 0 ? ' text-grey' : 'text-[#e35b67]'
            } `}
          >
            Upload {images.length === 0 ? 4 : 4 - images.length} more images to
            continue
          </p>
        )}
      </div>
    </div>
  )
}

export default ImageUpload
