import React from 'react'
import './Pricing.css'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { FaArrowRight } from 'react-icons/fa'

function Pricing () {
  return (
    <main className='main'>
      <Header />
      <section className='hero  '>
        <div className='content '>
          <div className='left'>
            <p className='hero_text'>
              Transform Your Farm with Our Comprehensive Enterprise Solutions
            </p>
            <p className='hero_subtext '>
              This is our top tier offering, ultimate farm management system
              designed for large-scale operations. Our advanced tools and
              features are crafted to meet the complex needs of extensive
              agricultural enterprises.
            </p>
            <div className='flex flex-col gap-2'>
              <input type='text' placeholder='Kindly enter your email' />
              <div className='flex flex-row gap-2 items-center text-white rounded-lg justify-center bg-[#144618] w-full lg:w-[407px] py-3 px-2  '>
                <p>Get in touch</p>
              </div>
            </div>
          </div>
          <div className='right_img' />
        </div>
      </section>
      <section className='first'>
        <p className=' text-[24px] lg:text-[32px] '>
          The robust system your farm requires
        </p>
        <div className='flex items-center gap-3 lg:gap-40'>
          <div className='flex items-center flex-col gap-1 lg:gap-5'>
            <img
              src={require('../../assets/tick-circle.png')}
              className=' w-6 h-6 lg:w-[60px] lg:h-[60px] '
              alt=''
            />
            <p className=' text-[8px] font-[400] '>
              Seamlessly blend technology <br /> and nature to make profit
            </p>
          </div>
          <div className='flex items-center flex-col gap-1 lg:gap-5'>
            <img
              src={require('../../assets/tick-circle.png')}
              className=' w-6 h-6 lg:w-[60px] lg:h-[60px] '
              alt=''
            />
            <p className=' text-[8px] font-[400] '>
              Stay on top of your farm <br /> trends and reports
            </p>
          </div>
          <div className='flex items-center flex-col gap-1 lg:gap-5'>
            <img
              src={require('../../assets/tick-circle.png')}
              className=' w-6 h-6 lg:w-[60px] lg:h-[60px] '
              alt=''
            />
            <p className=' text-[8px] font-[400] '>
              Onboard and personalize <br /> your member experiences
            </p>
          </div>
        </div>
      </section>
      <section className='lg:py-[72px] py-10 px-5 lg:px-[100px] l gap-10 g:gap-40 flex flex-col '>
        <div className='content_second'>
          <div className='left_text'>
            <p className=' text-[24px] lg:text-[48px] font-[700] text-primary '>
              Why use many tools when you can just use one?
            </p>
            <p className=' font-[400] text-[16px] '>
              We offer a robust and scalable solution tailored for large farms.
              Our suite integrates cutting-edge technology with user-friendly
              design to ensure you have complete control over every aspect of
              your operation.
            </p>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>Track and manage extensive inventories with precision.</p>
              </div>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>
                  Automated alerts for low stock levels, ensuring you never run
                  out of essential supplies..
                </p>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <p className='text-secondary'>Get in touch</p>
              <FaArrowRight color='#4AD10B' />
            </div>
          </div>
          <div className='right_img' />
        </div>
        <div className='content_second'>
          <div className='left_text'>
            <p className=' text-[24px] lg:text-[48px] font-[700] text-primary '>
              Comprehensive Reporting and Analytics
            </p>
            <p className=' font-[400] text-[16px] '>
              We offer and create an efficient system for you to have up to date
              analysis on the way your farm runs.
            </p>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>
                  Generate detailed reports on crop health, financial
                  performance, and operational efficiency.
                </p>
              </div>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>Gain valuable insights to make data-driven decisions</p>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <p className='text-secondary'>Get in touch</p>
              <FaArrowRight color='#4AD10B' />
            </div>
          </div>
          <div className='right_img' />
        </div>
        <div className='content_second'>
          <div className='left_text'>
            <p className=' text-[24px] lg:text-[48px] font-[700] text-primary '>
              Farm Market Insights and Forecasting:
            </p>
            <p className=' font-[400] text-[16px] '>
              Insights into how the crop and livestock market is going? we got
              you all can be done on our platform
            </p>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>Access up-to-date market trends and pricing information.</p>
              </div>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>
                  Forecast demand and plan your production cycles accordingly to
                  maximize profits.
                </p>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <p className='text-secondary'>Get in touch</p>
              <FaArrowRight color='#4AD10B' />
            </div>
          </div>
          <div className='right_img' />
        </div>
        <div className='content_second'>
          <div className='left_text'>
            <p className=' text-[24px] lg:text-[48px] font-[700] text-primary '>
              Multi-User and Role-Based Access:
            </p>
            <p className=' font-[400] text-[16px] '>
              Onboarding all your farm staffs and admin personnel to one system
            </p>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>
                  Allow multiple users to access the system simultaneously with
                  role-based permissions..
                </p>
              </div>
              <div className='flex gap-3 items-center'>
                <img
                  src={require('../../assets/tick-circle_2.png')}
                  alt=''
                  className=' w-[34px] h-[34px] '
                />
                <p>
                  Delegate tasks effectively and collaborate seamlessly with
                  your team.
                </p>
              </div>
            </div>
            <div className='flex items-center gap-3'>
              <p className='text-secondary'>Get in touch</p>
              <FaArrowRight color='#4AD10B' />
            </div>
          </div>
          <div className='right_img' />
        </div>
      </section>
      {/*  <section className=' px-5 py-10 lg:px-[100px] lg:py-[72px] '>
        <Faqs />
      </section> */}
      <section className='first'>
        <p className=' text-[48px] lg:text-[78px] '>
          Begin your journey <br className=' hidden lg:flex  ' /> with us
        </p>
        <div className='w-[50%] flex items-center justify-center '>
          <input type='text' placeholder='Enter your email' />
        </div>
      </section>
      <Footer />
    </main>
  )
}

export default Pricing
