import React from 'react'
import { Eye, EyeOff } from 'lucide-react'

const PasswordInput = ({
  register,
  errors,
  setShowPassword,
  showPassword,
  nolink,
  style,
  nobottomlink,
  name = 'password',
  watch,
  compareWith,
  currentPassword,
  disableCheck // The same prop to disable both minLength and character checks
}) => {
  return (
    <div className='input-container'>
      <label htmlFor={name} className={`${style}`}>
        {nolink ? 'Create ' : ''}
        {nobottomlink || 'Password'}
      </label>
      <div className='input-cover mt-2'>
        <input
          type={showPassword ? 'text' : 'password'}
          {...register(name, {
            required: `${name} is required`,
            ...(disableCheck
              ? {} // Omit minLength check if disableMinLengthCheck is true
              : {
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters'
                  }
                }),
            validate: (value) => {
              if (currentPassword && value === currentPassword) {
                return `${nobottomlink} should not be the same as the current password`
              }
              if (compareWith) {
                const compareValue = watch(compareWith)
                return value === compareValue || `${nobottomlink} does not match`
              }
              if (!disableCheck) {
                return (
                  [/[a-z]/, /[A-Z]/, /[^a-zA-Z0-9]/].every((pattern) =>
                    pattern.test(value)
                  ) || 'must include lower, upper, and special character'
                )
              }
              return true // No character validation if disableMinLengthCheck is true
            }
          })}
          name={name} // Use the name variable here
          className='email-input'
        />
        <div onClick={() => setShowPassword(!showPassword)} className='show-btn'>
          {showPassword ? <EyeOff /> : <Eye />}
        </div>
      </div>
      {errors[name] && (
        <p className='error-text text-red-400 ts'>{errors[name].message}</p>
      )}
      {!nolink && !nobottomlink && (
        <a href='/forgotpassword' className='link'>Forgot Password?</a>
      )}
    </div>
  )
}

export default PasswordInput
