import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  ArrowLeft,
  BadgePlus,
  CalendarClock,
  Search,
  Timer
} from 'lucide-react'
import useBackendService from '../../../../config/services/backend-service'
import '../AddNewStockPage/tables.css'
import { useAuth } from '../../../../config/contexts/authProvider'
import Modal from '../../SharedComponents/Modal/Modal'

const StockDetailsPage = () => {
  const location = useLocation()
  const { stockData } = location.state || {}

  const { id } = useParams()
  const { user } = useAuth()
  const [products, setProducts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])
  const [quantities, setQuantities] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [modal, setModal] = useState({})

  const pageSize = 10

  const { mutate: fetchStockDetails } = useBackendService(
    `/stock_records/${id}`,
    'get',
    {
      onSuccess: response => {
        const stockRecord = response.data[0] || {}
        setQuantities(stockRecord.stock || {})
        fetchProducts()
      },
      onError: error => {
        console.error('Failed to fetch stock details:', error)
      }
    }
  )

  const { mutate: fetchProducts } = useBackendService('/products', 'get', {
    onSuccess: response => {
      setProducts(response.data)
      setFilteredProducts(response.data)
    },
    onError: error => {
      console.error('Failed to fetch products:', error)
    }
  })

  const { mutate: updateStocks } = useBackendService(
    `/stock_records/${id}`,
    'put',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Stock updated successfully',
          desc: 'Well done, you have successfully updated stock.'
        })
        document.getElementById('my_modal_5').showModal()
      },
      onError: (error) => {
        setModal({
          type: 'error',
          title: 'Unable to update stock',
          desc: `${error}`
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  useEffect(() => {
    fetchStockDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const filtered = products.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredProducts(filtered)
  }, [searchTerm, products])

  const handleQuantityChange = (productId, value) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: Number(value)
    }))
  }

  const handlePageChange = page => {
    if (page < 1 || page > totalPages) return
    setCurrentPage(page)
  }

  const onSubmit = async event => {
    event.preventDefault()

    const stockData = filteredProducts
      .filter(item => quantities[item.id] !== undefined)
      .map(item => ({
        sku: item.id,
        stock: quantities[item.id]
      }))

    const submittedData = {
      farm: user.farmId,
      stock: stockData
    }
    updateStocks(submittedData)
  }

  const renderTables = () => {
    const tables = []
    const startIndex = (currentPage - 1) * 2 * pageSize
    const endIndex = startIndex + 2 * pageSize
    const currentPageData = filteredProducts.slice(startIndex, endIndex)

    const leftSideData = currentPageData.slice(0, pageSize)
    const rightSideData = currentPageData.slice(pageSize)

    tables.push(
      <div className='flex w-full' key={currentPage}>
        <div className='w-1/2 pr-2'>
          {leftSideData.length > 0 && (
            <table className='stock-table'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {leftSideData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.id] || 0}
                        onChange={e =>
                          handleQuantityChange(item.id, e.target.value)}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className='w-1/2 pl-2'>
          {rightSideData.length > 0 && (
            <table className='stock-table'>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Product name</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                {rightSideData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{startIndex + pageSize + index + 1}</td>
                    <td>{item.name}</td>
                    <td>
                      <input
                        type='number'
                        value={quantities[item.id] || 0}
                        onChange={e =>
                          handleQuantityChange(item.id, e.target.value)}
                        min='0'
                        className='inputborder w-full border-gray-300 p-1'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    )

    return tables
  }

  const totalPages = Math.ceil(filteredProducts.length / (2 * pageSize))

  const { farmName, createdBy, createdAt } = stockData
  const dateObject = new Date(createdAt)

  const date = dateObject.toLocaleDateString()
  const time = dateObject.toLocaleTimeString()

  return (
    <div className='farmwrapper'>
      <a href='/stocks' className='w-full'>
        <ArrowLeft size={20} />
      </a>
      <div className='header-cover'>
        <div className=''>
          <div className='space-y-0 lg:space-y-1'>
            <h1 className='lg:text-3xl text-xl text-black'>
              {farmName || 'Loading...'}
            </h1>
            <div className='text-[#666666] text-xs lg:text-sm center gap-3 '>
              <h1 className='center gap-1'>
                <BadgePlus size={20} /> Created by: {createdBy || 'Loading...'}
              </h1>
              <h1 className='center gap-1'>
                <CalendarClock size={20} />
                Date: {date || 'Loading...'}
              </h1>
              <h1 className='center gap-1'>
                <Timer size={20} />
                Time: {time || 'Loading...'}
              </h1>
            </div>
          </div>
        </div>
        <div className='w-full lg:w-[400px] h-[48px] relative flex items-center'>
          <input
            type='text'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className='w-full h-full border-black pl-[10%]'
            placeholder='Search product'
          />
          <Search
            size={20}
            className='absolute left-3 transform -translate-y-1/2'
            style={{ top: '50%' }}
          />
        </div>
      </div>
      <form
        onSubmit={onSubmit}
        className='w-full flex justify-start items-center gap-12 flex-wrap'
      >
        <div className='tables-container w-full'>{renderTables()}</div>
        <div className='w-full flex-col center gap-3'>
          <div className='w-full flex items-center'>
            <div className='pag-cover'>
              <div
                className='inline-flex size-8 items-center justify-center text-gray-900 rtl:rotate-180'
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  width='100'
                  height='100'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='20'
                    cy='20'
                    r='19.5'
                    fill='#F3F5F3'
                    stroke='#144618'
                  />
                  <path
                    d='M23 26L17 20L23 14'
                    stroke='#144618'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <div
                className='inline-flex size-8 ml-3 items-center justify-center text-gray-900 rtl:rotate-180'
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  width='100'
                  height='100'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='20'
                    cy='20'
                    r='19.5'
                    fill='#F3F5F3'
                    stroke='#144618'
                  />
                  <path
                    d='M17 26L23 20L17 14'
                    stroke='#144618'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <div className='center text-sm text-black gap-1 hidden lg:flex'>
              <h1>Page</h1>
              <h1 className='number-green'>{currentPage}</h1>
              <h1>of</h1>
              <h1>{totalPages}</h1>
            </div>
          </div>
          <div className='w-full flex justify-end items-center'>
            <button
              type='submit'
              className='btn btn-outline border-darkGreen w-[15%] bg-darkGreen text-white'
            >
              Update stock record
            </button>
            {/* Conditionally render the modal */}
            {modal.type && (
              <Modal
                type={modal.type}
                title={modal.title}
                desc={modal.desc}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default StockDetailsPage
