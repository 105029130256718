import React, { useRef, useState } from 'react'
import BackButton from '../../../SharedComponents/BackButton/BackButton'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import { CloudUpload, Pencil } from 'lucide-react'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../../SharedComponents/Modal/Modal'
import useBackendService from '../../../../../config/services/backend-service'

const AddNewArticle = () => {
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })
  const imageInput = useRef(null)

  const {
    register,
    handleSubmit,
    setValue,
    reset,

    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onChange'
  })

  const handleImageChange = e => {
    const file = e.target.files?.[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPreview(url)
      setValue('newsimage', file)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const { mutate: submitArticle } = useBackendService('/articles', 'post', {
    onSuccess: () => {
      setModalData({
        title: 'News added',
        desc: 'Well done , you have successfully created a news article',
        type: 'success',
        link: '/admin-news'
      })
      document.getElementById('my_modal_5').showModal()
      reset()
      setPreview(null)
      setImageUploaded(false)
    },
    onError: error => {
      setModalData({
        type: 'error',
        title: 'Submission failed',
        desc: 'You are unable to create a news article'
      })
      document.getElementById('my_modal_5').showModal()
    }
  })

  const onSubmit = async data => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('body', data.newsdescription)

    if (data.newsimage) {
      formData.append('image', data.newsimage)
    }

    submitArticle(formData)
  }

  const buttonStyles =
    isDirty && isValid && imageUploaded
      ? 'bg-darkGreen text-white'
      : 'bg-gray-200 text-gray-700 cursor-not-allowed'

  return (
    <>
      <div className='w-full flex flex-col lg:flex-row bg-white'>
        <div className='w-full lg:w-[70%] h-full p-[2%] space-y-3'>
          <BackButton />
          <h1 className='font-semibold w-full'>Add news</h1>

          <form className='w-full space-y-5' onSubmit={handleSubmit(onSubmit)}>
            <div className='w-full lg:w-[50%]'>
              <TextInput
                register={register}
                errors={errors}
                text='Title'
                name='title'
                validation={{ required: 'Title is required' }}
              />
              {errors.title && (
                <p className='text-red-500'>{errors.title.message}</p>
              )}
            </div>
            <div className='grid w-full gap-1.5 ts'>
              <h1>Upload cover image</h1>
              <div className='w-full h-[300px] relative'>
                {preview && (
                  <button
                    type='button'
                    className='absolute right-3 top-4 z-50 bg-[#ADEE68] rounded-full w-9 h-9 center'
                    onClick={handlePencilClick}
                  >
                    <Pencil size={20} />
                  </button>
                )}
                {preview ? (
                  <img
                    src={preview}
                    alt='img'
                    className='w-full h-full absolute object-cover rounded-md'
                    onClick={handlePencilClick}
                  />
                ) : (
                  <div className='w-full center flex-col rounded-md border-dashed border ts h-full gap-2 p-2'>
                    <CloudUpload className='text-black rounded-full bg-[#F0F2F5] p-3 w-[50px] h-[50px]' />
                    <h1 className='text-[#98A2B3]'>
                      <span
                        onClick={() => imageInput.current?.click()}
                        className='text-darkGreen cursor-pointer font-semibold'
                      >
                        Click to upload
                      </span>{' '}
                      or drag and drop
                    </h1>
                    <h1 className='text-[#98A2B3]'>
                      SVG, PNG, JPG (max. 800x400px)
                    </h1>
                    <div className='center gap-2 w-full text-[#98A2B3]'>
                      <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
                      OR
                      <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
                    </div>
                    <button
                      type='button'
                      onClick={() => imageInput.current?.click()}
                      className='bg-darkGreen text-white ts w-full lg:w-[20%] rounded-md mx-auto h-[50px]'
                    >
                      Browse Files
                    </button>
                  </div>
                )}
                <input
                  type='file'
                  accept='.svg, .png, .jpg, .jpeg'
                  onChange={handleImageChange}
                  hidden
                  ref={imageInput}
                  name='newsimage'
                />
                {errors.newsimage && (
                  <p className='text-red-500'>{errors.newsimage.message}</p>
                )}
              </div>
            </div>
            <div className='grid w-full gap-1.5 ts'>
              <h1>News body</h1>
              <textarea
                className='textarea bg-gray-100 h-[200px] ts'
                placeholder='Start writing'
                {...register('newsdescription', {
                  required: 'News description is required'
                })}
              />
              {errors.newsdescription && (
                <p className='text-red-500'>{errors.newsdescription.message}</p>
              )}
            </div>
            <button
              className={`w-full lg:w-[30%] ts rounded-md h-[50px] ${buttonStyles}`}
              disabled={!isDirty || !isValid || !imageUploaded}
            >
              Submit
            </button>
          </form>
          <Modal
            type={modalData.type}
            title={modalData.title}
            desc={modalData.desc}
            link={modalData.link}
          />
        </div>
      </div>
    </>
  )
}

export default AddNewArticle
