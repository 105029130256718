import React, { useState } from 'react'
import { ChevronRight, Search } from 'lucide-react'
import './RegularCatalog.css'
import { useAuth } from '../.././../../config/contexts/authProvider'

function RegularCatalog () {
  const [activeButton, setActiveButton] = useState('All')
  const { user } = useAuth()

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const contentData = [
    {
      id: 1,
      title: 'Tomatoes',
      content:
        'Juicy red tomatoes perfect for salads, sauces, and cooking. Rich in vitamins A and C.',
      season: true,
      img: require('../../../resources/tomatoes.png')
    },
    {
      id: 2,
      title: 'Broiler Chicken',
      content:
        'Fast-growing chickens ideal for meat production. High feed conversion efficiency.',
      img: require('../../../resources/chicken.png')
    },
    {
      id: 3,
      title: 'Corn',
      content:
        'Sweet and tender corn, perfect for grilling or boiling. Harvested during summer.',
      season: true,
      img: require('../../../resources/corn.png')
    },
    {
      id: 4,
      title: 'Broiler Chicken',
      content:
        'Fast-growing chickens ideal for meat production. High feed conversion efficiency.',
      img: require('../../../resources/chicken.png')
    },
    {
      id: 5,
      title: 'Broiler Chicken',
      content:
        'Fast-growing chickens ideal for meat production. High feed conversion efficiency.',
      img: require('../../../resources/chicken.png')
    }
  ]
  return (
    <div className='w-full h-full p-[2%] bg-white'>
      <div className='filter'>
        {/* category buttons */}
        <div className='center gap-2'>
          {['All', 'Crops', 'Livestocks', 'Tools'].map(buttonName => (
            <button
              key={buttonName}
              className={`btn border-[#888888] border ts ${
                activeButton === buttonName
                  ? 'bg-darkGreen text-white hover:text-[#888888]'
                  : 'bg-white text-[#888888]'
              }`}
              onClick={() => handleButtonClick(buttonName)}
            >
              {buttonName}
            </button>
          ))}
        </div>
        <div className='w-full lg:w-[400px] h-[48px] relative  flex items-center '>
          <input
            type='text'
            className='w-full h-full border-black pl-[10%] ts'
            placeholder='Search product'
          />
          <Search
            size={20}
            className='absolute left-3 transform -translate-y-1/2'
            style={{ top: '50%' }}
          />
        </div>
      </div>
      {/* catalog cards */}
      <div className='w-full flex flex-wrap justify-start gap-4 items-center mt-[2%]'>
        {contentData.map(item => (
          <div className='catalogcard' key={item.id}>
            <a href={`/catalog/${item.id}`}>
              <img src={item.img} className='object-cover' alt='product' />
            </a>
            <div className='w-full flex justify-between items-center mt-2'>
              <h1>{item.title}</h1>
              {item.season && <h1 className='text-darkGreen ts'>In season</h1>}
            </div>
            <p className='ts mt-2'>{item.content}</p>
            {user.plan === 'Pro'
              ? (
                <h1 className='upgrade'>
                  Check Out
                  <ChevronRight />
                </h1>
                )
              : (
                <h1 className='upgrade'>
                  Upgrade to see more <ChevronRight />
                </h1>
                )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RegularCatalog
