import { BellRing, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { isToday, isYesterday, formatDistanceToNow } from 'date-fns'
import { getMessaging, onMessage } from 'firebase/messaging'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'

const NotificationDrawer = ({ isDrawerOpen, toggleDrawer }) => {
  const { user } = useAuth()
  const [notifications, setNotifications] = useState([])

  const { mutate } = useBackendService(`/notifications/${user.id}`, 'get', {
    onSuccess: response => {
      setNotifications(response.data)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    mutate()
    const messaging = getMessaging()
    onMessage(messaging, payload => {
      const newNotification = {
        id: payload.data.id,
        senderId: payload.data.sender_id,
        recipientId: payload.data.recipient_id,
        content: payload.data.content,
        type: payload.data.type,
        createdAt: new Date(payload.data.created_at),
        updatedAt: new Date(payload.data.updated_at),
        read: payload.data.read === 'true',
        clickUrl: payload.data.click_url
      }

      setNotifications(prevNotifications => [
        newNotification,
        ...prevNotifications
      ])
    })
  }, [mutate, user.id])

  // helper function to categorize the notifications
  const categorizeNotifications = notifications => {
    const today = []
    const yesterday = []
    const older = []

    notifications.forEach(notification => {
      const notificationDate = new Date(notification.created_at)

      if (isToday(notificationDate)) {
        today.push(notification)
      } else if (isYesterday(notificationDate)) {
        yesterday.push(notification)
      } else {
        older.push(notification)
      }
    })

    return { today, yesterday, older }
  }
  const { today, yesterday, older } = categorizeNotifications(notifications)

  const renderNotifications = notifications => {
    return notifications.map(item => (
      <div
        key={item.id}
        className='w-full flex justify-start items-start gap-2 mt-4'
      >
        <div className='bell-style cursor-pointer'>
          <BellRing size={20} />
        </div>
        <div className='flex flex-col justify-center items-start ts w-[80%]'>
          <h1>
            <span className='font-semibold'>{item.sender}</span> {item.content}
            <span className='text-darkGreen underline font-semibold cursor-pointer'>
              <a href={item.click_url}> View now</a>
            </span>
          </h1>
          <p>{formatDistanceToNow(new Date(item.created_at))} ago</p>
        </div>
        {!item.read && <div className='w-3 h-3 rounded-full bg-[#ADEE68]' />}
      </div>
    ))
  }

  return (
    <div
      className={`fixed top-0 right-0 lg:w-[30%] w-full h-screen shadow-lg p-3 bg-white z-50 transform transition-transform duration-300 ${
        isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className='flex justify-between items-center font-semibold p-4'>
        <h1>Notifications</h1>
        <X className='cursor-pointer' size={24} onClick={toggleDrawer} />
      </div>

      {today.length > 0 && (
        <>
          <div className='w-full center gap-2 ts text-gray-500'>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
            <h1>Today</h1>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
          </div>
          {renderNotifications(today)}
        </>
      )}

      {yesterday.length > 0 && (
        <>
          <div className='w-full center gap-2 ts text-gray-500 mt-4'>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
            <h1>Yesterday</h1>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
          </div>
          {renderNotifications(yesterday)}
        </>
      )}

      {older.length > 0 && (
        <>
          <div className='w-full center gap-2 ts text-gray-500 mt-4'>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
            <h1>Older</h1>
            <div className='bg-gray-300 h-[1px] w-[50%]' />
          </div>
          {renderNotifications(older)}
        </>
      )}
    </div>
  )
}

export default NotificationDrawer
