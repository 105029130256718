import React from 'react'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'

function Sales ({ register, errors, user }) {
  return (
    <form action='' className=' flex flex-col gap-5 lg:gap-10 max-w-fit  '>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='storageSolutions'
          register={register}
          errors={errors}
          text='Storage Solutions'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='packagingMethods'
          register={register}
          errors={errors}
          text='Packaging Methods'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='marketPrices'
          register={register}
          errors={errors}
          text='Market Prices'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='logisticsProviders'
          register={register}
          errors={errors}
          text='Logistics Providers'
          disabled={user.role === 'approver'}
        />
      </div>
      <div className=' w-full lg:w-[876px] '>
        <TextArea
          name='salesChannels'
          register={register}
          errors={errors}
          text='Sales Channels'
          disabled={user.role === 'approver'}
        />
      </div>
    </form>
  )
}

export default Sales
