import React from 'react'
import './NavBar.css'
import { BellDot, Menu } from 'lucide-react'
import { RegularDashboardContent } from '../../../data/static'

const NavBar = ({ location, onClick }) => {
  const { pathname } = location
  const navData = RegularDashboardContent.find(item => item.link === pathname)

  const { text, subtext } = navData || {}
  return (
    <div className='pro-topnav-container'>
      <div className='flex flex-col gap-3 lg:gap-[15px] min-w-fit'>
        <p className=' text-black lg:text-darkgreen font-[700] lg:italic text-[18px] lg:text-[22px] '>
          {text}
        </p>
        <p className=' text-[#444444] lg:text-darkgreen font-[400] text-[12px] lg:text-[14px] '>
          {subtext}
        </p>
      </div>
      <div className='pronav-content-style'>
        <div className='center gap-2 text-gray-500'>
          <div className='bell-style'>
            <BellDot size={20} />
          </div>
        </div>
      </div>
      <div className=' flex lg:hidden ' onClick={onClick}>
        <Menu size={24} />
      </div>
    </div>
  )
}

export default NavBar
