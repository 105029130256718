export const columns = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      const firstName = row.original.first_name
      const lastName = row.original.last_name
      return `${firstName} ${lastName}`
    }
  },
  {
    accessorKey: 'email',
    header: 'Email'
  },
  {
    accessorKey: 'phone_number',
    header: 'Phone Number'
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span style={{ color: value === 'inactive' ? 'red' : 'green' }}>
          {value === 'active' ? 'Active' : 'Inactive'}
        </span>
      )
    }
  },
  {
    accessorKey: 'action',
    header: 'Action',
    enableHiding: false,
    cell: () => {
      return <p className=' font-[500] text-[16px] cursor-pointer '>View</p>
    }
  }
]
