import React from 'react'

const TextInput = ({
  name,
  register,
  errors,
  text,
  subtext,
  value,
  disabled,
  onChange,
  edit,
  type = 'text'
}) => {
  return (
    <div className='grid w-full  items-center gap-1.5 ts'>
      <label htmlFor='email' className=' text-black text-[16px] font-[400]   '>
        {text}
      </label>
      {subtext && (
        <p className='text-[#888888] text-[16px] font-[400] '>{subtext}</p>
      )}
      <div className='w-full relative'>
        <input
          type={type}
          name={name || text}
          {...register(`${name || text}`, {
            required: `${text} is required`
          })}
          placeholder={text}
          className='email-input bg-gray-100 '
          defaultValue={value || ''}
          disabled={disabled}
          onChange={onChange}
        />
        {edit && (
          <h1 className='text-darkGreen absolute right-3 top-4 bottom-2 font-medium'>
            EDIT
          </h1>
        )}
      </div>
      {errors[name] && <p className='error-text text-red-500 ts'>{errors[name]?.message}</p>}
    </div>
  )
}

export default TextInput
