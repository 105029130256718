import React from 'react'
import './index.css'
import Inventory from '../../Inventory/Inventory'

function InventoryCount ({ data }) {
  // Calculate counts
  const activeCount = data.filter(product => product.active).length
  const inactiveCount = data.filter(product => !product.active).length
  const totalAmount = data.length
  const stockNumber = data.reduce(
    (acc, product) => acc + product.minimum_threshold,
    0
  )

  return (
    <section className='inventory-wrapper'>
      <Inventory
        borderColor='#F28F16'
        title='Total Amount'
        amount={totalAmount}
      />
      <Inventory
        borderColor='#5CB85C'
        title='Active product'
        amount={activeCount}
      />
      <Inventory
        borderColor='#D92534'
        title='Inactive product'
        amount={inactiveCount}
      />
      <Inventory
        borderColor='#733620'
        title='Stock number'
        amount={stockNumber}
      />
    </section>
  )
}

export default InventoryCount
