import { ArrowLeft, Timer } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import TextArea from '../../../SharedComponents/TextAreaInput/TextAreaInput'
import ImageInput from '../../../SharedComponents/ImageInput/ImageInput'
import { useParams } from 'react-router-dom'
import NoteComponent from '../../../SharedComponents/NoteComponent/NoteComponent'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../../../SharedComponents/Modal/Modal'

function InvestmentDetails () {
  const { user } = useAuth()
  const { id } = useParams()
  const [preview, setPreview] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [data, setData] = useState({})
  const imageInput = useRef(null)
  const [modalData, setModalData] = useState({
    type: '',
    title: '',
    desc: ''
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const { mutate: fetchInvestment } = useBackendService(
    `/investments/${id}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data)
        setValue('title', response.data.title)
        setValue('about', response.data.about)
        setValue('price', response.data.price)
        setValue('availableSlots', response.data.available_slot)
        setValue('startDate', response.data.start_date)
        setValue('endDate', response.data.end_date)
        setValue('investmentType', response.data.investment_type)
        setValue('payoutDate', response.data.payout_date)
        setValue('repaymentPlan', response.data.repayment_plan)
        setValue('investmentRisk', response.data.investment_risk)
        setPreview(response.data.image_url)
      },
      onError: error => {
        console.error('Failed to fetch investment data:', error)
        toast.error('Failed to fetch investment data')
      }
    }
  )

  useEffect(() => {
    fetchInvestment()
  }, [fetchInvestment])

  const { mutate: updateInvestment } = useBackendService(
    `/investments/${id}`,
    'put',
    {
      onSuccess: () => {
        setModalData({
          title: 'Investment added',
          desc: 'Well done , you have successfully updated an investment',
          type: 'success',
          link: '/admin-investments'
        })
        document.getElementById('my_modal_5').showModal()
      },
      onError: error => {
        setModalData({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to update an investment'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  const handleImageChange = e => {
    const file = e.target.files?.[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPreview(url)
      setValue('newsimage', url)
      setImageUploaded(true)
    }
  }

  const handlePencilClick = () => {
    imageInput.current?.click()
  }

  const onSubmit = data => {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('about', data.about)
    formData.append('price', data.price)
    formData.append('availableSlots', data.availableSlots)
    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('investmentType', data.investmentType)
    formData.append('payoutDate', data.payoutDate)
    formData.append('repaymentPlan', data.repaymentPlan)
    formData.append('investmentRisk', data.investmentRisk)
    if (data.newsimage) formData.append('img', data.newsimage)

    updateInvestment(formData)
  }
  const handleStatusChange = status => {
    const formData = new FormData()
    formData.append('status', status)

    updateInvestment(formData)
  }

  const textToShow = user.role === 'approver' ? 'Image' : 'Upload cover image'

  return (
    <div className='flex lg:flex-row flex-col lg:gap-0 gap-3 px-3 lg:px-5 bg-white'>
      <div className='flex flex-col flex-[0.7] lg:mr-3 lg:py-5 py-3 lg:gap-10 gap-5'>
        <div className='flex flex-col'>
          <a href='/admin-investments'>
            <ArrowLeft size={24} />
          </a>
        </div>
        <div className='space-y-1 lg:space-y-2'>
          <h1 className='lg:text-xl text-[12px] text-black'>Investment</h1>
          <div className='flex items-center gap-1.5'>
            <div className='hidden lg:flex'>
              <Timer size={18} />
            </div>
            <div className='flex lg:hidden'>
              <Timer size={12} />
            </div>
            <p className='text-grey font-[400] text-[12px] lg:text-[16px]'>
              Status <span className='text-[#F29339]'>{data.status}</span>
            </p>
          </div>
        </div>
        <form
          action=''
          className='flex flex-col gap-5 lg:gap-10 w-full'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='lg:w-[406px] w-full'>
            <TextInput
              name='title'
              register={register}
              errors={errors}
              text='Title'
              disabled={user.role === 'approver'}
            />
          </div>
          <div className='grid w-full gap-1.5 ts'>
            <h1>{textToShow}</h1>
            {user.role !== 'approver' && (
              <ImageInput
                preview={preview}
                imageInput={imageInput}
                handleImageChange={handleImageChange}
                handlePencilClick={handlePencilClick}
              />
            )}
            {errors.newsimage && (
              <p className='text-red-500'>{errors.newsimage.message}</p>
            )}
          </div>

          <TextArea
            name='about'
            register={register}
            errors={errors}
            text='About'
            disabled={user.role === 'approver'}
          />
          <div className='flex flex-1 gap-5 lg:gap-[60px] items-center'>
            <TextInput
              name='price'
              register={register}
              errors={errors}
              text='Price'
              disabled={user.role === 'approver'}
            />
            <TextInput
              name='availableSlots'
              register={register}
              errors={errors}
              text='Available Slots'
              disabled={user.role === 'approver'}
            />
          </div>
          <TextArea
            name='repaymentPlan'
            register={register}
            errors={errors}
            text='Repayment Plan'
            disabled={user.role === 'approver'}
          />
          <TextArea
            name='investmentRisk'
            register={register}
            errors={errors}
            text='Investment Risk'
            disabled={user.role === 'approver'}
          />
          {data.status !== 'approved' &&
            (user.role === 'approver' ? (
              <div className='flex gap-5'>
                <button
                  type='button'
                  className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-green-500 text-white'
                  onClick={() => handleStatusChange('approved')}
                >
                  Approve
                </button>
                <button
                  type='button'
                  className='w-full lg:w-[200px] h-[50px] rounded-[8px] bg-red-500 text-white'
                  onClick={() => handleStatusChange('rejected')}
                >
                  Reject
                </button>
              </div>
            ) : (
              <button
                type='submit'
                className={`w-full lg:w-[408px] h-[50px] ${
                  isValid && isDirty && imageUploaded
                    ? 'proceed-btn bg-darkGreen'
                    : 'rounded-[8px] bg-darkash text-black'
                }`}
                disabled={!isValid}
              >
                Submit
              </button>
            ))}
        </form>
      </div>
      {(user.role === 'approver' || user.id === data.created_by) && (
        <div className='flex-[0.3]'>
          <NoteComponent data={data} table={'investment'} />
        </div>
      )}
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />
    </div>
  )
}

export default InvestmentDetails
