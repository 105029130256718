import React from 'react'
import DataTable from '../../../SharedComponents/DataTable/DataTable'
import Pagination from '../../../SharedComponents/Pagination/Pagination'
import EmptyDataContent from '../../../SharedComponents/EmptyDataContent/EmptyDataContent'
import { columns } from '../InvestmentTableColumn/TableColumns'
import { useNavigate } from 'react-router-dom'

function InvestmentDataTable ({ data }) {
  const navigate = useNavigate()

  const handleRowClick = user => {
    navigate(`/admin-investments/${user.id}`)
  }

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={require('../../../../resources/farmemptyimage.png')}
            text='No investment details yet'
            desc='Click the add new investment button above to start adding'
          />
        }
        rowClick={handleRowClick}
      />
    </div>
  )
}

export default InvestmentDataTable
