import React from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-white.png'
import { ChevronLeft } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const ProPlanPayment = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()
  const onSubmit = async data => {
    navigate('/signup/proplansignup')
    console.log(data)
  }
  return (
    <div className='wrapper'>
      {/* left content */}
      <div className='left-content '>

        <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
          <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
        </div>
        <img alt='' src={Image} className='object-contain' />
        <div className='image-text-wrapper'>
          <h1 className='text-2xl lg:text-5xl w-full'>
            Manage your <br /> agribusiness efficiently
          </h1>
          <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
            Building the best and more efficient platform for agribusiness
            management.
          </p>
        </div>
      </div>
      {/* right content */}
      <div className='right-content'>
        <div className='cheveron-wrapper'>
          <a href='/signup'>
            <ChevronLeft className='text-darkgreen cursor-pointer' />
          </a>
          <h1>Pro plan payment</h1>
        </div>
        <p className='w-[90%] mx-auto mt-2 text-center text-xs lg:text-sm text-black'>You are about to make payment for the pro plan with a sum of ₦10,500. Kindly input your card details to initiate transaction.</p>

        <form onSubmit={handleSubmit(onSubmit)} className='form-payment'>
          <div className='grid w-full  items-center gap-1.5 '>
            <label htmlFor='email' className=''>Card number</label>
            <input
              type='number'
              name='cardnumber'
              placeholder='0000 0000 0000 0000'
              {...register('cardnumber', {
                required: 'Card number is required',
                minLength: {
                  value: 16,
                  message: 'Card number must be 16 digits'
                },
                maxLength: {
                  value: 16,
                  message: 'Card number must be 16 digits'
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Card number must be a number'
                }
              })}
              className='email-input'
            />
            {errors.cardnumber && <p>{errors.cardnumber.message}</p>}
          </div>
          <div className='w-full center gap-4'>
            <div className='grid w-[50%]  items-center gap-1.5'>
              <label htmlFor='email'>Expiry Date</label>
              <input
                type='text'
                name='expirydate'
                placeholder='MM/YY'
                {...register('expirydate', {
                  required: 'Expiry date is required',
                  pattern: {
                    value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                    message: 'Invalid expiry date format (MM/YY)'
                  }
                })}
                className='email-input'
              />
            </div>
            <div className='grid w-[50%]  items-center gap-1.5'>
              <label htmlFor='email'>CVV</label>
              <input
                type='text'
                name='cvv'
                placeholder='123'
                maxLength={3}
                {...register('cvv', {
                  required: 'CVV is required',
                  pattern: {
                    value: /^[0-9]{3}$/,
                    message: 'Invalid CVV format (3 digits)'
                  }
                })}
                className='email-input'
              />
            </div>
          </div>
          <div className='w-full pt-[5%]'>
            <button
              type='submit'
              className={`${
                isDirty && isValid
                  ? 'activebutton'
                  : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
              }`}
              disabled={!(isDirty && isValid)}
            >
              Make Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProPlanPayment
