import React, { useEffect, useState } from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-white.png'
import Check from '../../../resources/check.png'
import useBackendService from '../../../../config/services/backend-service'

const UserPasswordResetSuccess = ({
  title = 'Well done, your new password is set',
  message = 'You have successfully reset your password. Kindly click the button below to take you to the login page.'
}) => {
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const { mutate, isLoading } = useBackendService('/auth/verify', 'post', {
    onSuccess: data => {
      setIsSuccess(true)
      setIsError(false)
    },
    onError: error => {
      setIsError(true)
      setIsSuccess(false)
      console.log(error)
    }
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')

    if (token) {
      setIsSuccess(false)
      mutate({ token })
    }
  }, [mutate])

  return (
    <div className='wrapper'>
      {/* left content */}
      <div className='lg:flex  justify-center items-center w-full lg:w-[50%] h-[50vh] lg:h-screen relative'>
        <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
          <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
        </div>
        <img alt='Background' src={Image} className='object-contain' />
        <div className='image-text-wrapper'>
          <h1 className='text-2xl lg:text-5xl w-full'>
            Manage your <br /> agribusiness efficiently
          </h1>
          <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
            Building the best and more efficient platform for agribusiness
            management.
          </p>
        </div>
      </div>
      {/* right content */}
      <div className='right-content'>
        <div className='w-full lg:w-[80%] m-auto center flex-col space-y-3 lg:mt-[23%] mt-[20%] text-center h-[40vh]'>
          {/* Show loading while verifying */}
          {isLoading && <p>Verifying your account...</p>}

          {isSuccess && !isLoading && (
            <>
              <img src={Check} alt='Check' className='w-[80px]' />
              <h1 className='text-2xl lg:text-3xl text-darkgreen font-semibold'>
                {title}
              </h1>
              <p className='text-sm lg:text-base text-black w-[100%] mx-auto'>
                {message}
              </p>
              <a href='/login' className='proceed-btn center'>
                Go to Login
              </a>
            </>
          )}

          {isError && (
            <>
              <h1 className='text-2xl lg:text-3xl text-red-600 font-semibold'>
                Verification Failed
              </h1>
              <p className='text-sm lg:text-base text-black w-[100%] mx-auto'>
                There was an issue verifying your account. Please try again
                later or contact support.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserPasswordResetSuccess
