import React from 'react'
import Hourglass from '../../../resources/hourglass.png'
const EnterpriseSignUpConfirmation = () => {
  return (
    <div className='job-wrapper'>
      <img src={Hourglass} alt='hourglass' className='w-[80px]' />
      <h1 className='text-3xl text-darkgreen font-semibold'>Good Job!</h1>
      <p className='text-sm text-black w-[70%] mx-auto'>An internal representative will contact you shortly to confirm your details and provide further instructions. Please stay alert.</p>
      <a href='/' className='proceed-btn center'>Go to website</a>
    </div>
  )
}

export default EnterpriseSignUpConfirmation
