export const StaffMobileColumn = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: context => {
      const { first_name, last_name } = context.row.original
      const fullName = `${first_name} ${last_name}`
      return <div className='w-[100px] ts'>{fullName}</div>
    }
  },

  {
    accessorKey: 'role',
    header: 'Role',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{value}</div>
    }
  },
  {
    accessorKey: 'location',
    header: 'Location',
    cell: context => {
      const value = context.getValue()
      return <div className='w-[100px] ts'>{value}</div>
    }
  }
]
