import React from 'react'
import Image from '../../../resources/wateryplant.png'
import Logo from '../../../resources/logo-white.png'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../SharedComponents/BackButton/BackButton'
import useBackendService from '../../../../config/services/backend-service'

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const navigate = useNavigate()
  const { mutate: requestPasswordReset, isLoading } = useBackendService(
    '/forgot_password',
    'post',
    {
      onSuccess: () => navigate('/forgotpassword/otp'),
      onError: error =>
        console.error(error.response?.data?.message || 'Error occurred')
    }
  )
  const onSubmit = data => {
    requestPasswordReset(data)
  }

  return (
    <div className='wrapper'>
      {/* left content */}
      <div className='left-content '>
        <div className='absolute top-[5%] left-1/2 lg:left-[10%] transform -translate-x-1/2 -translate-y-1/2 center mx-auto'>
          <img alt='' src={Logo} className='w-[80px] lg:w-[100px]' />
        </div>
        <img src={Image} className='object-contain' alt='waterplant' />
        <div className='image-text-wrapper'>
          <h1 className='text-2xl lg:text-5xl w-full'>
            Manage your <br /> agribusiness efficiently
          </h1>
          <p className='w-[100%] lg:w-[70%] lg:text-lg leading-5 text-sm'>
            Building the best and more efficient platform for agribusiness
            management.
          </p>
        </div>
      </div>
      {/* right content */}
      <div className='right-content'>
        <BackButton />
        <div className='w-full mt-[5%] space-y-1'>
          <h1 className='header-text'>Forgot Password?</h1>
          <p className='text-black text-sm '>
            Kindly fill in the email/phone number you registered with to reset
            password.
          </p>
        </div>
        <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
          <div className='grid w-full  items-center gap-1.5'>
            <label htmlFor='email'>Email/Phone number</label>
            <input
              type='text'
              name='email'
              {...register('email', {
                required: 'Email or Phone number is required'
              })}
              placeholder='Email/Phonenumber'
              className='emailinput input-style'
            />
            {errors.email && (
              <p className='error-text'>{errors.email.message}</p>
            )}
          </div>

          <div className='mt-[10%] w-full'>
            <button type='submit' className='proceed-btn' disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Continue'}
            </button>
          </div>
        </form>
        <p className='login-text'>
          Remember password?<span className='login-link'>Log in</span>
        </p>
      </div>
    </div>
  )
}

export default ForgotPassword
