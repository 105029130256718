import React, { useState } from 'react'
import { ChevronRight, Search } from 'lucide-react'
import './RegularSales.css'
import { useAuth } from '../../../../config/contexts/authProvider'
import PayWall from '../PayWall/PayWall'
import { salesproducts } from '../../../data/static'

const RegularSales = () => {
  const { user } = useAuth()
  const [activeButton, setActiveButton] = useState('All')

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }

  return (
    <>
      {user.plan === 'Pro'
        ? (
          <div className='w-full h-full p-[2%] bg-white'>
            <div className='filter'>
              <div className='center gap-2'>
                {['All', 'Crops', 'Livestocks', 'Tools'].map((buttonName) => (
                  <button
                    key={buttonName}
                    className={`btn border-[#888888] border ts ${
                  activeButton === buttonName
                    ? 'bg-darkGreen text-white hover:text-[#888888]'
                    : 'bg-white text-[#888888]'
                }`}
                    onClick={() => handleButtonClick(buttonName)}
                  >
                    {buttonName}
                  </button>
                ))}
              </div>
              <div className='w-full lg:w-[400px] h-[48px] relative flex items-center'>
                <input
                  type='text'
                  className='w-full h-full border-black pl-[10%] ts'
                  placeholder='Search product'
                />
                <Search
                  size={20}
                  className='absolute left-3 transform -translate-y-1/2'
                  style={{ top: '50%' }}
                />
              </div>
            </div>
            <div className='w-full flex flex-wrap justify-start gap-4 items-center mt-[2%]'>
              {salesproducts.map((item) => (
                <div className='salescard ts' key={item.id}>
                  <h1 className='text-black font-semibold capitalize'>
                    {item.title}
                  </h1>
                  <div className='w-full h-[100px] lg:h-[150px]'>
                    <img
                      src={item.img}
                      alt='product'
                      className='w-full h-full object-cover rounded-lg'
                    />
                  </div>
                  <a
                    href={`/sales/${item.title}`}
                    className='text-black font-semibold flex justify-start items-center'
                  >
                    Check out <ChevronRight size={20} />
                  </a>
                </div>
              ))}
            </div>
          </div>
          )
        : (
          <div className='w-full h-screen'>
            <PayWall />
          </div>
          )}
    </>
  )
}

export default RegularSales
