import React from 'react'
import './Index.css'
import { ChevronRight } from 'lucide-react'

const contentData = [
  {
    id: 1,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../../../../resources/cow.png'),
    date: '24 May 2024'
  },
  {
    id: 2,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../../../../resources/cow.png'),
    date: '24 May 2024'
  },
  {
    id: 3,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../../../../resources/cow.png'),
    date: '24 May 2024'
  },
  {
    id: 4,
    title: 'The Growth of Billie the Cow',
    content:
      'Following the unexpected miracle in the little town off Orient express...',
    img: require('../../../../resources/cow.png'),
    date: '24 May 2024'
  }
]

function NewsSection () {
  return (
    <section className=' flex flex-col gap-[15px] lg:gap-[30px] px-5 lg:px-10 '>
      <div className=' section-header '>
        <div className=' flex flex-col gap-4 '>
          <p className='  text-[14px] lg:text-[18px] font-[600] text-black '>
            News & Articles
          </p>
          <p className=' hidden lg:flex text-grey font-[400] text-[14px] '>
            Keep up with the latest happenings in the agribusiness world
          </p>
        </div>
        <div className=' news-nav '>
          <p className=' text-[18px] font-[700]  '>Upgrade to see more</p>
          <ChevronRight size={22} />
        </div>
        <p className=' flex lg:hidden font-[500] text-primary text-[12px] '>
          Upgrade to view
        </p>
      </div>
      <div className=' flex flex-row overflow-x-auto lg:flex-1 gap-5 lg:gap-[20px] '>
        {contentData.map(item => (
          <div className=' basic-news-content-ctn ' key={item.id}>
            <div className='flex w-[260px] lg:w-full flex-col p-[15px] lg:p-5 gap-2 lg:gap-5'>
              <img
                src={item.img}
                alt=''
                className='  lg:w-full h-[140px] lg:h-[200px] rounded-lg overflow-hidden '
              />
              <div className=' flex flex-row gap-2 items-center '>
                <div className='w-[8px] lg:w-[10px] h-[8px] lg:h-[10px] rounded-full bg-darkGreen ' />
                <p className=' text-grey text-[12px] lg:text-[14px] font-[500] '>
                  {item.date}
                </p>
              </div>
              <div>
                <p className='text-black font-[600] text-[14px] lg:text-[18px] '>
                  {item.title}
                </p>
              </div>
              <div className=' font-[500] text-grey text-[12px] lg:text-[16px] '>
                <p>
                  {item.content}{' '}
                  <span className=' italic underline text-primary font-[700] cursor-pointer '>
                    ...Read More
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default NewsSection
