import React from 'react'
import Header from '../../components/Header/Header'
import './Products.css'
import {
  BasicPlanProductContent,
  EnterpriseProductsContent,
  ProPlanProductContent
} from '../../static'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'

function Products () {
  return (
    <main className='flex flex-col gap-[20px] '>
      <Header />
      <section className='products_hero'>
        <div className=' text-center flex flex-col gap-[10px] p-5  '>
          <p className=' text-[32px] lg:text-[48px] text-black  font-[700] '>
            Discover the perfect plan to meet your <br /> agribusiness needs
          </p>
          <p className=' text-grey font-[500] text-[14px] lg:text-[20px] '>
            From essential tools for small farm owners to comprehensive
            solutions for large- <br />
            scale operations, we offer a range of options designed to help you
            manage your <br /> farm efficiently.
          </p>
        </div>
        <div className=' flex justify-center lg:p-0 p-5 '>
          <img
            src={require('../../assets/product-hero.png')}
            className=' w-full lg:w-[723px] lg:h-[430px] '
            alt=''
          />
        </div>
      </section>
      <section className='products_pricing'>
        <div className=' flex flex-col gap-[14px] text-start lg:p-[80px] p-5 '>
          <p className=' text-primary text-[16px] lg:text-[40px] font-[700] '>
            Learn more about the right plan <br /> for your interest
          </p>
          <p className=' text-grey font-[400] lg:w-[40%]  text-[12px] lg:text-[20px] '>
            Whether you're overseeing agriculture investment portfolios, farms
            (large, medium or small scale entreprise) our features ensure you
            have everything you need to succeed.
          </p>
        </div>
        <div className=' plans '>
          <div className=' pricing_headers'>
            <div className=' pricing-header_content '>
              <p className=' font-[400] lg:font-[500] text-[10px] lg:text-[22px] text-white '>
                Perfect for small farm owners, the Basic Plan offers essential
                tools <br /> for efficient farm management.
              </p>
              <Link to='/signup/basicplan'>
                {' '}
                <div className=' pricing-header_button '>
                  <p className=' text-primary font-[500] text-[9px] lg:text-[16px] '>
                    Choose plan
                  </p>
                </div>
              </Link>
            </div>
            <div className=' pricimg-header_amount '>
              <p>Basic Plan</p>
              <p className='flex items-center text-center justify-end lg:justify-center'>
                Free
              </p>
            </div>
          </div>
          <div className='pricing-content'>
            <div className=' grid grid-cols-3 gap-2 lg:gap-10'>
              {BasicPlanProductContent.map((item, index) => (
                <div className=' flex flex-col gap-2 lg:gap-5 ' key={index}>
                  <img
                    src={item.img}
                    className=' w-5 lg:w-9 h-5 lg:h-9 '
                    alt=''
                  />

                  <p className=' text-black text-[10px] lg:text-[20px] font-[600] '>
                    {item.title}
                  </p>
                  <p className=' text-grey font-[400] text-[10px] lg:text-[16px] '>
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=' plans '>
          <div className=' pricing_headers'>
            <div className=' pricing-header_content '>
              <p className=' font-[400] lg:font-[500] text-[10px] lg:text-[22px] text-white '>
                Enjoy enhanced analytics, detailed reporting, and better
                resource <br /> management to boost productivity and streamline
                operations.
              </p>
              <Link to='/signup/basicplan'>
                <div className=' pricing-header_button '>
                  <p className=' text-primary font-[500] text-[9px] lg:text-[16px] '>
                    Choose plan
                  </p>
                </div>
              </Link>
            </div>
            <div className=' pricimg-header_amount '>
              <p>Pro Plan</p>
              <p className='flex items-center justify-end lg:justify-center'>
                ₦5000/{' '}
                <span>
                  <p className=' text-[8px] lg:text-[20px] '>month</p>
                </span>{' '}
              </p>
            </div>
          </div>
          <div className='pricing-content'>
            <div className=' grid grid-cols-3 gap-2 lg:gap-10'>
              {ProPlanProductContent.map((item, index) => (
                <div className=' flex flex-col gap-2 lg:gap-5 ' key={index}>
                  <img
                    src={item.img}
                    className=' w-5 lg:w-9 h-5 lg:h-9 '
                    alt=''
                  />

                  <p className=' text-black text-[10px] lg:text-[20px] font-[600] '>
                    {item.title}
                  </p>
                  <p className=' text-grey font-[400] text-[10px] lg:text-[16px] '>
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=' plans '>
          <div className=' pricing_headers'>
            <div className=' pricing-header_content '>
              <p className=' font-[400] lg:font-[500] text-[10px] lg:text-[22px] text-white '>
                Designed for large-scale farms and livestock operations, the
                Enterprise Plan provides comprehensive solutions for complex
                management needs
              </p>
              <Link to='/signup/enterpriseplan'>
                {' '}
                <div className=' pricing-header_button '>
                  <p className=' text-primary font-[500] text-[9px] lg:text-[16px] '>
                    Choose plan
                  </p>
                </div>
              </Link>
            </div>
            <div className=' pricimg-header_amount '>
              <p>Enterprise</p>
              <p className='flex items-center justify-end lg:justify-center'>
                Get a quote{' '}
              </p>
            </div>
          </div>
          <div className='pricing-content'>
            <div className=' grid grid-cols-3 gap-2 lg:gap-10'>
              {EnterpriseProductsContent.map((item, index) => (
                <div className=' flex flex-col gap-2 lg:gap-5 ' key={index}>
                  <img
                    src={item.img}
                    className=' w-5 lg:w-9 h-5 lg:h-9 '
                    alt=''
                  />

                  <p className=' text-black text-[10px] lg:text-[20px] font-[600] '>
                    {item.title}
                  </p>
                  <p className=' text-grey font-[400] text-[10px] lg:text-[16px] '>
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className=' py-5 '>
        <img
          src={require('../../assets/Frame 428.png')}
          className=' w-full h-[100px] lg:h-[400px]  '
          alt=''
        />
      </section>
      <Footer />
    </main>
  )
}

export default Products
