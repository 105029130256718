import React, { useState } from 'react'
import { ArrowLeft, CircleAlert } from 'lucide-react'
import Tomato from '../../../../resources/tomatoes.png'
import Corn from '../../../../resources/corn.png'
import Cow from '../../../../resources/cow.png'
import SalesSection from '../../Home/SalesSection/SalesSection'
import { useAuth } from '../../../../../config/contexts/authProvider'
import Modal from '../Modal/Modal'
import './CatalogDescription.css'
import { Link } from 'react-router-dom'
const CatalogDescription = () => {
  const { user } = useAuth()

  const product =
    {
      title: 'Tomatoes',
      img: [Tomato, Corn, Cow, Tomato],
      description:
        'Tomatoes are a versatile vegetable, known for their delicious flavor and rich color. They are grown in a variety of regions, from South America to Asia, and are used in various cuisines around the world.',
      nutritionalValue: [
        {
          title: 'calories',
          value: '22 per medium tomato (123g)'
        },
        {
          title: 'vitamin A',
          value: '22 per medium tomato (123g)'
        },
        {
          title: 'Vitamin C',
          value: '22 per medium tomato (123g)'

        },
        {
          title: 'pottassuim',
          value: '₦100 per bowl (prices may vary based on region and season)'

        }
      ],
      careInstructions: [
        'Water your crops early in the morning or late in the evening to minimize evaporation. Use drip irrigation systems to provide consistent moisture directly to the roots.',
        'Tomatoes thrive in well-drained, nutrient-rich soil with a pH level between 6.0 and 6.8.',
        'Keep the soil consistently moist but not waterlogged. Water at the base of the plant to avoid wetting the foliage.',
        'Tomatoes require full sun, at least 6-8 hours of direct sunlight per day.',
        'Use a balanced fertilizer once a month, or incorporate compost into the soil for added nutrients.',
        'Remove the lower leaves and suckers (small shoots that grow between the stem and a branch) to improve air circulation and plant health.'
      ],
      growthTips: [
        'Start seeds indoors 6-8 weeks before the last frost date. Transplant seedlings outdoors after the danger of frost has passed.',
        'Use stakes, cages, or trellises to support the tomato plants as they grow, preventing them from sprawling on the ground.',
        'Monitor for common pests such as aphids, hornworms, and white-flies. Use organic pesticides or introduce beneficial insects like ladybugs to control pest populations.',
        'Pick tomatoes when they are firm and fully colored. For the best flavor, harvest them when they are slightly soft to the touch.'
      ],
      Prosales: [
        {
          title: 'Storage Solutions:',
          desc: 'Store tomatoes at room temperature out of direct sunlight. Do not refrigerate unless they are fully ripe and need to be stored for more than a few days.'
        },
        {
          title: 'Packaging Methods:',
          desc: 'Use breathable packaging materials such as cardboard boxes with ventilation holes. Avoid stacking too many layers to prevent bruising.'
        },
        {
          title: 'Market Prices:',
          desc: 'Use online agricultural marketplaces or contact local buyers to stay updated on market trends.'
        },
        {
          title: 'Logistics Providers:',
          desc: 'Consider local couriers for short distances and specialized agricultural logistics firms for longer hauls.'
        },
        {
          title: 'Sales Channels:',
          desc: 'Explore e-commerce platforms to reach a wider audience and increase sales opportunities.'
        }
      ]
    }
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  const [selectedImg, setSelectedImg] = useState(0)

  return (
    <div className='w-full h-full p-[2%]'>
      <div className='w-[5%]'>
        <Link href='/catalog'>
          <ArrowLeft />
        </Link>
      </div>
      <h1 className='mt-2 font-semibold text-darkGreen'>{product.title}</h1>
      <div className='w-full center gap-2 flex-col lg:flex-row mt-2 '>
        <div className='lg:w-[50%] w-full h-screen'>
          <div className='w-full h-[60vh] center gap-2 flex-col '>
            {/* big image gallery */}
            <div className='w-full h-[40vh] bg-black rounded-md'>
              <img src={product.img[selectedImg]} alt='product' className='rounded-lg w-full h-full object-cover' />
            </div>
            <div className='w-full h-[20vh] flex gap-2'>
              {product.img.map((img, index) => (
                <div key={index} className='w-[25%] h-full rounded-md'>
                  <img
                    src={img}
                    alt='product'
                    className='smallcard'
                    onClick={() => setSelectedImg(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='w-full h-[40vh] pt-4'>
            <h1 className='text-darkGreen font-semibold'>Description:</h1>
            <p className='text-black ts'>
              {product.description}
            </p>
            <div className='w-full ts space-y-1 mt-4'>
              <h1 className='text-darkGreen font-semibold'>Nutritional/Market value:</h1>
              {product.nutritionalValue.map((item, index) => (
                <React.Fragment key={index}>
                  <p><span className='font-semibold capitalize'>{item.title}:</span>{item.value}</p>
                </React.Fragment>
              ))}

            </div>
          </div>
        </div>
        <div className='lg:w-[50%] w-full h-screen'>
          <div className='w-full h-[40vh]'>
            <h1 className='text-darkGreen font-semibold'>Growth tips:</h1>
            <ul className='ts'>
              {product.growthTips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
          </div>
          <div className='w-full  bg-darkGreen rounded-lg text-white ts' onClick={openModal}>
            <h1 className='font-semibold p-[2%]'>Care instructions:</h1>
            <hr className='opacity-15' />
            {product.careInstructions.map((item, index) => (
              <React.Fragment key={index}>
                <p className='p-[2%]'>{item}</p>
                <hr className='opacity-15' />
              </React.Fragment>
            ))}

          </div>
          {/* modal */}
          <Modal show={isModalOpen} onClose={closeModal} product={product} />
        </div>
      </div>
      {user.plan === 'pro'
        ? (
          <div className='w-full flex flex-col justify-center items-start'>
            <h1 className='text-darkGreen font-semibold'>Sales & Supply Chain Information🎉</h1>
            <p className='text-[#515151] ts'>Stay ahead of the market with our sales & supply chain information.</p>
            <div className='w-full flex flex-wrap justify-start gap-4 items-center mt-6 '>
              {product.Prosales.map((item) => (
                <div className='lg:w-[32%] w-full flex justify-center items-start gap-2' key={item.title}>
                  <div className='w-[10%] center'>
                    <CircleAlert className='text-darkGreen' />
                  </div>
                  <div className='w-[90%] ts'>
                    <h1 className='text-darkGreen font-semibold'>{item.title}</h1>
                    <p className='text-[#515151]'>{item.desc}</p>
                  </div>
                </div>
              ))}

            </div>

          </div>
          )
        : (
          <SalesSection
            title='Sales & Supply'
            titletwo='Chain Information🎉'
            desc='Stay ahead of the market with our sales & supply chain information.'
            me='px-0'
          />
          )}

    </div>
  )
}

export default CatalogDescription
