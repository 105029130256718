import React, { useState } from 'react'
import { AlignJustify } from 'lucide-react'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <div className='lg:py-[28px] lg:px-[70px] px-5 py-3 w-full justify-between flex flex-row items-center'>
      <div className='flex flex-row gap-16 items-center'>
        <img
          src={require('../../assets/logo.png')}
          alt=''
          className=' h-[15px] lg:h-[30px]'
        />
        <div className='hidden lg:flex items-center gap-5'>
          <div className='cursor-pointer'>
            <NavLink
              to='/'
              className={({ isActive }) =>
                isActive
                  ? 'text-primary font-[600] text-[18px] hover:text-[19px] underline'
                  : 'hover:text-[19px] font-[400] text-[18px] text-[#232221] '}
            >
              <p className=''>Home</p>
            </NavLink>
          </div>{' '}
          <div className='cursor-pointer'>
            <NavLink
              to='/about'
              className={({ isActive }) =>
                isActive
                  ? 'text-primary font-[600] text-[18px] hover:text-[19px] underline'
                  : 'hover:text-[19px] font-[400] text-[18px] text-[#232221] '}
            >
              <p>About</p>
            </NavLink>
          </div>
          <div className='cursor-pointer'>
            <NavLink
              to='/products'
              className={({ isActive }) =>
                isActive
                  ? 'text-primary font-[600] text-[18px] hover:text-[19px] underline'
                  : 'hover:text-[19px] font-[400] text-[18px] text-[#232221] '}
            >
              <p>Products</p>
            </NavLink>
          </div>
        </div>
      </div>
      <div className='hidden lg:flex flex-row gap-5 items-center'>
        <Link to='/login'>
          <div className='border border-black py-2 px-6 rounded-md cursor-pointer'>
            <p className='font-[500]  text-black text-[16px]'>Log in</p>
          </div>
        </Link>
        <Link to='/signup'>
          <div className='py-[10px] px-6 bg-primary rounded-md cursor-pointer'>
            <p className='font-[500] text-[#232221] text-[16px]'>
              Create an Account
            </p>
          </div>
        </Link>
      </div>
      <div className='sm:hidden' onClick={toggleDropdown}>
        <AlignJustify color='#000' />
        {dropdownVisible && (
          <div className='absolute z-10 right-0 mr-1 mt-2 w-48 rounded-md shadow-lg bg-secondary'>
            <div className='rounded-md ring-1 ring-black ring-opacity-5 overflow-hidden'>
              <div className='py-1 text-base font-medium text-gray-700'>
                <Link to='/'>
                  <p className='block font-[600] px-4 py-2 hover:bg-gray-100 cursor-pointer text-primary'>
                    Home
                  </p>
                </Link>
                <Link to='/about'>
                  {' '}
                  <p className='block font-[600] px-4 py-2 hover:bg-gray-100 cursor-pointer text-primary'>
                    About
                  </p>
                </Link>
                <Link to='/products'>
                  <p className='block font-[600] px-4 py-2 hover:bg-gray-100 cursor-pointer text-primary'>
                    Product
                  </p>
                </Link>
                <Link to='/login'>
                  <p className='block font-[600] px-4 py-2  hover:bg-gray-100 cursor-pointer text-primary'>
                    Log in
                  </p>
                </Link>
                <Link to='/signup'>
                  {' '}
                  <p className='block font-[600] px-4 py-2 hover:bg-gray-100 cursor-pointer text-primary'>
                    Create an Account
                  </p>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
