import { MoveRight } from 'lucide-react'
import React from 'react'

function NavigvationSteps ({ stage }) {
  return (
    <div className=' text-[12px] items-center lg:text-[12px]  gap-4 lg:max-w-fit w-full lg:justify-start justify-between flex flex-row '>
      <p
        className={` ${
          stage >= 1 ? 'text-darkGreen ' : ' text-[#888888] '
        } font-[700] `}
      >
        Product
      </p>
      <MoveRight
        className={` ${stage >= 2 ? 'text-darkGreen ' : ' text-[#888888] '} `}
      />
      <p
        className={` ${
          stage >= 2 ? 'text-darkGreen  ' : ' text-[#888888] '
        } font-[700] `}
      >
        Catalog
      </p>
      <MoveRight
        className={` ${stage >= 3 ? 'text-darkGreen ' : ' text-[#888888] '} `}
      />
      <p
        className={` ${
          stage === 3 ? 'text-darkGreen  ' : ' text-[#888888] '
        } font-[700] `}
      >
        Sales & Supply
      </p>
    </div>
  )
}

export default NavigvationSteps
