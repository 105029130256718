import React from 'react'
import { ChevronRight } from 'lucide-react'
import './Index.css'

function InvestContainer ({ item }) {
  return (
    <div className=' basic-investment-content-ctn w-full ' key={item.id}>
      <div
        className=' w-full h-[130px] lg:h-[200px] p-5 flex justify-end rounded-lg overflow-hidden bg-cover bg-center'
        style={{ backgroundImage: `url(${item.img})` }}
      >
        <div className='investment'>
          <p>investment: {item.amount}</p>
        </div>
      </div>
      <div className=' flex flex-row justify-between items-center '>
        <div className=' flex flex-col-reverse lg:flex-col '>
          <p className='text-black font-[600] text-[14px] lg:text-[18px] '>
            {item.title}
          </p>
          <div className=' font-[500] text-grey text-[12px] lg:text-[16px] '>
            <p>{item.content}</p>
          </div>
        </div>
        <div className=' hidden lg:flex  cursor-pointer items-center flex-row gap-[10px] text-primary'>
          <p className=' text-[16px] font-[700]  '>Check out</p>
          <ChevronRight size={22} />
        </div>
      </div>
    </div>
  )
}

export default InvestContainer
