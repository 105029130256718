import { MoreHorizontal } from 'lucide-react'

export const columns = [
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'type',
    header: 'Type'
  },
  {
    accessorKey: 'unit_type',
    header: 'Unit'
  },
  {
    accessorKey: 'manufacturer',
    header: 'Manufacturer'
  },
  {
    accessorKey: 'active',
    header: 'Status',
    cell: context => {
      const value = context.getValue()
      return (
        <span style={{ color: value === false ? 'red' : 'green' }}>
          {value === true ? 'Active' : 'Inactive'}
        </span>
      )
    }
  },
  {
    accessorKey: 'unit_value',
    header: 'Stock'
  },

  {
    accessorKey: 'action',
    header: 'Action',

    enableHiding: false,
    cell: () => {
      return <MoreHorizontal className='h-4 w-4' />
    }
  }
]
