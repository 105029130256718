import React from 'react'
import { useForm } from 'react-hook-form'
import EnterpriseSignUpConfirmation from './EnterpriseSignUpConfirmation'
import { useAuth } from '../../../../config/contexts/authProvider'
import useBackendService from '../../../../config/services/backend-service'
const EnterpriseFarmRegistration = ({ submitted, setSubmitted }) => {
  const { farm } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm()
  const { mutate } = useBackendService('/auth/farm', 'post', {
    onSuccess: data => {
      setSubmitted(true)
    },
    onError: error => {
      console.error('failed:', error)
    }
  })

  const onSubmit = async data => {
    const payload = {
      ...farm,
      name: data.farmname,
      state: data.location,
      additionalData: {
        staffs: data.staffs,
        branches: data.branch,
        type: data.type
      }
    }
    mutate(payload)
  }
  return (
    <>
      {submitted
        ? (
          <EnterpriseSignUpConfirmation />
          )
        : (
          <div className='content-cover'>
            <div className='w-full  space-y-1'>
              <h1 className='header-text'>Business Details</h1>
              <p className='text-black '>
                Kindly fill in your details to setup your account
              </p>
            </div>

            <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
              <div className='grid w-full  items-center gap-1.5'>
                <label htmlFor='text'>Business name</label>
                <input
                  type='text'
                  name='text'
                  {...register('farmname', { required: 'Farmname is required' })}
                  className='email-input input-style'
                  placeholder='Branch'
                />
                {errors.farmname && (
                  <p className='error-text'>{errors.farmname.message}</p>
                )}
              </div>

              <div className='grid w-full  items-center gap-1.5'>
                <label htmlFor='text'>Type of Business</label>
                <input
                  type='text'
                  name='text'
                  {...register('type', { required: 'Name is required' })}
                  className='email-input input-style'
                  placeholder='Tell us about your business'
                />
                {errors.type && (
                  <p className='error-text'>{errors.type.message}</p>
                )}
              </div>
              <div className='grid w-full  items-center gap-1.5'>
                <label htmlFor='text'>Location(s)</label>
                <input
                  type='text'
                  name='text'
                  {...register('location', { required: 'Name is required' })}
                  className='email-input input-style'
                  placeholder='Taraba'
                />
                {errors.location && (
                  <p className='error-text'>{errors.location.message}</p>
                )}
              </div>
              <div className='w-full center gap-3'>
                <div className='grid w-[50%]  items-center gap-1.5'>
                  <label htmlFor='text'>Number of staffs</label>
                  <input
                    type='number'
                    name='text'
                    {...register('staffs', { required: 'Name is required' })}
                    className='email-input input-style'
                    placeholder='0'
                  />
                  {errors.staffs && (
                    <p className='error-text'>{errors.staffs.message}</p>
                  )}
                </div>

                <div className='grid w-[50%]  items-center gap-1.5'>
                  <label htmlFor='text'>Number of branch</label>
                  <input
                    type='number'
                    name='text'
                    {...register('branch', { required: 'Name is required' })}
                    className='email-input input-style'
                    placeholder='0'
                  />
                  {errors.branch && (
                    <p className='error-text'>{errors.branch.message}</p>
                  )}
                </div>
              </div>

              <button
                type='submit'
                className={`${isDirty && isValid ? 'activebutton' : 'notactive'}`}
                disabled={!(isDirty && isValid)}
              >
                Proceed
              </button>
            </form>

            <p className='login-text'>
              Have an account?{' '}
              <a href='/login' className='login-link'>
                Log in
              </a>
            </p>
          </div>
          )}
    </>
  )
}

export default EnterpriseFarmRegistration
