import React from 'react'
import DataTable from '../../SharedComponents/DataTable/DataTable'
import Pagination from '../../SharedComponents/Pagination/Pagination'
import EmptyDataContent from '../../SharedComponents/EmptyDataContent/EmptyDataContent'

function BusinessApplicationsTable ({ data, approveApplication }) {
  const handleButtonClick = (rowData) => {
    approveApplication(rowData)
  }

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: context => {
        const value = context.getValue()
        return (
          <div className='w-[100px] ts'>{value}</div>
        )
      }
    },
    {
      accessorKey: 'state',
      header: () => <div className=''>State</div>,
      cell: context => {
        const value = context.getValue()
        return (
          <div className='ts'>{value}</div>
        )
      }
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: context => {
        const value = context.getValue()
        return (
          <div className='ts'>{value}</div>
        )
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: context => {
        const value = context.getValue()
        return (
          <div className='ts'>{value}</div>
        )
      }
    },
    {
      accessorKey: 'phoneNumber',
      header: 'Phone Number',
      cell: context => {
        const value = context.getValue()
        return (
          <div className='ts'>{value}</div>
        )
      }
    },
    {
      accessorKey: 'staffs',
      header: 'Staffs',
      cell: context => {
        const value = context.getValue()
        return <span className='ts'>{value}</span>
      }
    },
    {
      accessorKey: 'branches',
      header: 'Branches',
      cell: context => {
        const value = context.getValue()
        return <span className='ts'>{value}</span>
      }
    },
    {
      accessorKey: 'action',
      header: 'Action',
      enableHiding: false,
      cell: ({ row }) => (
        <button
          type='submit'
          className={`w-full h-[50px] ${'proceed-btn bg-darkGreen'}`}
          onClick={(e) => {
            e.stopPropagation() // Prevent row click if needed
            handleButtonClick(row.original) // Pass row id when button is clicked
          }}
        >
          Approve
        </button>
      )
    }
  ]

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        filter={false}
        pagination={data.length !== 0 && <Pagination />}
        emptydatacontent={
          <EmptyDataContent
            image={require('../../../resources/farmemptyimage.png')}
            text='No applications yet'
            desc=''
          />
        }
      />
    </div>
  )
}

export default BusinessApplicationsTable
