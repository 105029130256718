import React from 'react'
import { ArrowLeft } from 'lucide-react'
import './NewsDescriptionPage.css'
import { agriproduct, hotnews } from '../../../../data/static'

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

const NewsDescriptionPage = () => {
  return (
    <div className='w-full h-full p-[2%] bg-white'>
      <div className='w-[5%]'>
        <a href='/agrinews'>
          <ArrowLeft />
        </a>
      </div>
      <div className='w-full flex justify-center items-start lg:flex-row flex-col gap-2 mt-4'>
        <div className='w-full lg:w-[70%] h-full space-y-4'>
          <h1 className='text-black font-semibold'>{agriproduct.title}</h1>
          <div className='w-full'>
            <img src={agriproduct.img} alt='Billie the Cow' className='object-cover' />
          </div>
          <div className='w-full flex gap-2 justify-start items-center ts'>
            <div className='circle' />{formatDate(agriproduct.date)}
          </div>
          <p className='ts'>
            {agriproduct.description}
          </p>
        </div>
        <div className='w-full lg:w-[30%] h-full'>
          <h1 className='text-black font-semibold'>More news</h1>
          <div className='mt-4 w-full space-y-3'>
            {/* news card start */}
            {hotnews.map((item, index) => (
              <div className='w-full rounded-md bg-[#f9f9f9] p-2 flex gap-2 justify-start items-center' key={index}>
                <img src={item.img} alt='phone' className='object-cover w-[100px]' />
                <div className='flex flex-col justify-start items-start gap-1 ts'>
                  <h1 className='text-red-500 capitalize'>{item.title}🔥</h1>
                  <p>{item.name}</p>
                  <div className='w-full flex gap-2 justify-start items-center ts'>
                    <div className='circle' />{formatDate(item.date)}
                  </div>
                </div>
              </div>
            ))}
            {/* news card end */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsDescriptionPage
