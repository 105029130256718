import React from 'react'
import { useForm } from 'react-hook-form'
const EnterpriseAdminSignUp = ({ next, setFormData }) => {
  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm()
  // function to submitting the form data
  const onSubmit = async (data) => {
    setFormData(data)
    next()
  }
  return (
    <div className='content-cover'>
      <div className='w-full  space-y-1'>
        <h1 className='header-text'>Welcome back</h1>
        <p className='text-black '>Kindly fill in your details to setup your admin account</p>
      </div>

      <form className='form-wrapper' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='email'>Email</label>
          <input type='email' name='email' {...register('email', { required: 'Email is required' })} placeholder='Email' className='bg-[#F3F5F6] h-[48px] email-input flex w-full   rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-darkgreen file:border-0 file:bg-darkgreen file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none  focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50' />
          {errors.email && (
            <p className='error-text'>{errors.email.message}</p>
          )}
        </div>

        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='text'>Firstname</label>
          <input type='text' name='number' {...register('firstName', { required: 'firstname is required' })} placeholder='Firstname' className='input-style email-input' />
          {errors.firstname && (
            <p className='error-text'>{errors.firstname.message}</p>
          )}
        </div>
        <div className='grid w-full  items-center gap-1.5'>
          <label htmlFor='text'>Lastname</label>
          <input type='text' name='number' {...register('lastName', { required: 'lastname is required' })} placeholder='Lastname' className='input-style email-input' />
          {errors.lastname && (
            <p className='error-text'>{errors.lastname.message}</p>
          )}
        </div>
        <button
          type='submit'
          className={`w-full ${isDirty && isValid ? 'proceed-btn' : 'h-[50px] w-full  rounded-[8px] bg-darkash text-black'}`}
          disabled={!(isDirty && isValid)}
        >
          Proceed
        </button>

      </form>

      <p className='login-text'>Have an account?  <a href='/login' className='login-link'>Log in</a></p>
    </div>
  )
}

export default EnterpriseAdminSignUp
