import { CloudUpload, Pencil } from 'lucide-react'

function ImageInput ({
  preview,
  imageInput,
  handleImageChange,
  handlePencilClick
}) {
  return (
    <div className='w-full h-[300px] relative overflow-hidden'>
      {preview
        ? (
          <img
            src={preview}
            alt='selected'
            className='w-full h-full object-cover rounded-lg'
          />
          )
        : null}

      {preview && (
        <button
          type='button'
          className='absolute right-3 top-4 z-50 bg-[#ADEE68] rounded-full w-9 h-9 center'
          onClick={handlePencilClick}
        >
          <Pencil size={20} />
        </button>
      )}

      {!preview && (
        <div className='w-full center flex-col rounded-md border-dashed border ts h-full gap-2 p-2'>
          <CloudUpload className='text-black rounded-full bg-[#F0F2F5] p-3 w-[50px] h-[50px]' />

          <h1 className='text-[#98A2B3]'>
            <span
              onClick={() => imageInput.current?.click()}
              className='text-darkGreen cursor-pointer font-semibold'
            >
              Click to upload
            </span>{' '}
            or drag and drop
          </h1>

          <h1 className='text-[#98A2B3]'>SVG, PNG, JPG (max. 800x400px)</h1>
          <div className='center gap-2 w-full text-[#98A2B3]'>
            <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
            OR
            <div className='w-[50%] lg:w-[40%] bg-[#F0F2F5] h-[1px]' />
          </div>
          <button
            type='button'
            onClick={() => imageInput.current?.click()}
            className='bg-darkGreen text-white ts w-full lg:w-[20%] rounded-md mx-auto h-[50px]'
          >
            Browse Files
          </button>
        </div>
      )}

      <input
        type='file'
        accept='.svg, .png, .jpg, .jpeg'
        onChange={handleImageChange}
        hidden
        ref={imageInput}
        name='newsimage'
        multiple
      />
    </div>
  )
}

export default ImageInput
