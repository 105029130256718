import React, { useEffect, useState } from 'react'
import DashBoardHeader from '../DashBoard/DashBoardHeader/DashBoardHeader'
import { Plus } from 'lucide-react'
import IndicatorSection from './IndicatorSection/IndicatorSection'
import FarmDataTable from './FarmDataTable/FarmDataTable'
import useBackendService from '../../../../config/services/backend-service'
import { useAuth } from '../../../../config/contexts/authProvider'
const FarmPage = () => {
  const [data, setData] = useState([])
  const { user } = useAuth()

  const { mutate: fetchFarms } = useBackendService(
    `/farms/list_sub_farms/${user.farmId}`,
    'get',
    {
      onSuccess: response => {
        setData(response.data)
      },
      onError: error => {
        console.error('Failed to fetch products:', error)
      }
    }
  )

  useEffect(() => {
    fetchFarms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='farmwrapper'>
      <div className='w-full flex flex-col-reverse lg:flex-col justify-center items-center gap-2'>
        <DashBoardHeader
          title='Welcome'
          desc='Manage your organizations activities with ease and take charge'
          btntext='Add new branch'
          link='/farms/new-farm'
          icon={<Plus size={20} />}
        />
        <IndicatorSection data={data} />
      </div>
      <FarmDataTable data={data} />
    </div>
  )
}

export default FarmPage
