import { Plus } from 'lucide-react'
import React from 'react'

function AddNewButton ({ title, onClick, color = true }) {
  return (
    <div
      onClick={onClick}
      className={`lg:px-5 p-2 cursor-pointer lg:py-[15px] ${
        color ? 'bg-darkGreen' : 'bg-primary'
      } flex flex-row gap-[5px] lg:gap-[10px] rounded-lg items-center justify-center `}
    >
      <div className=' hidden lg:flex '>
        <Plus size={24} color={color === true ? '#fff' : '#144618'} />
      </div>
      <div className=' flex lg:hidden '>
        <Plus size={18} color={color === true ? '#fff' : '#144618'} />
      </div>
      <p
        className={`  font-[600] text-[10px] lg:text-[14px] ${
          color ? 'text-[#fff]' : 'text-[#144618]'
        } `}
      >
        {title}
      </p>
    </div>
  )
}

export default AddNewButton
