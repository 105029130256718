import React from 'react'
import TrendingNews from './TrendingNews/TrendingNews'
import ForYouSection from './ForYouSection/ForYouSection'
const RegularAgriNews = () => {
  return (
    <div className='w-full h-full bg-white p-[2%]'>
      <TrendingNews />
      <ForYouSection />
    </div>
  )
}

export default RegularAgriNews
