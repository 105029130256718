import axios from 'axios'
import { useMutation } from 'react-query'

const getBaseURL = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return 'https://tgaims.com/api/v1'
  } else if (process.env.REACT_APP_NODE_ENV === 'development') {
    return 'https://dev.tgaims.com/api/v1'
  } else if (process.env.REACT_APP_NODE_ENV === 'local') {
    return 'http://localhost:5000/api/v1'
  }
}

const baseURL = getBaseURL()

const useBackendService = (endpoint, method, options) => {
  const url = baseURL + endpoint

  const backendService = async (payload, config = {}) => {
    const lowerCaseMethod = method.toLowerCase()
    const headers = config.headers || {}
    if (payload instanceof FormData) {
      config.headers = {
        ...headers,
        'Content-Type': 'multipart/form-data'
      }
    }
    switch (lowerCaseMethod) {
      case 'get':
        return axios
          .get(url, { params: payload, ...config })
          .then(res => res.data)
      case 'post':
        return axios.post(url, payload, config).then(res => res.data)
      case 'put':
        return axios.put(url, payload, config).then(res => res.data)
      case 'delete':
        return axios
          .delete(url, { data: payload, ...config })
          .then(res => res.data)
      default:
        throw new Error(`Unsupported method: ${method}`)
    }
  }

  return useMutation(backendService, options)
}

export default useBackendService
