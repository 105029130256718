import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeft } from 'lucide-react'
import TextInput from '../../../SharedComponents/TextInput/TextInput'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import './Index.css'
import { useParams } from 'react-router-dom'
import useBackendService from '../../../../../config/services/backend-service'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../../SharedComponents/Modal/Modal'

function UserDetails () {
  const { id } = useParams()
  const [modalData, setModalData] = useState({ type: '', title: '', desc: '' })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange'
  })

  const [userData, setUserData] = useState(null)

  const { mutate: fetchUser } = useBackendService(
    `/users/getone_id/${id}`,
    'get',
    {
      onSuccess: response => {
        const user = response.data
        setUserData(user)
        setValue('firstName', user.first_name)
        setValue('lastName', user.last_name)
        setValue('phoneNumber', user.phone_number)
        setValue('roleInput', user.role)
      },
      onError: error => {
        console.error('Failed to fetch user data:', error)
        toast.error('Failed to fetch user data.')
      }
    }
  )

  const { mutate: updateUser } = useBackendService(
    `/users/update_id/${id}`,
    'put',
    {
      onSuccess: () => {
        setModalData({
          title: 'User details updated',
          desc: 'Well done , you have successfully updated User details',
          type: 'success',
          link: '/admin-users'
        })
        document.getElementById('my_modal_5').showModal()
      },
      onError: error => {
        setModalData({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to update the user'
        })
        document.getElementById('my_modal_5').showModal()
      }
    }
  )

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  const onSubmit = data => {
    const updatedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      role: data.roleInput,
      password: data.password
    }
    updateUser(updatedData)
  }

  const handleDeactivate = () => {
    const newStatus = userData.status === 'active' ? 'inactive' : 'active'
    const updatedStatus = {
      status: newStatus
    }
    updateUser(updatedStatus)
    setUserData(prevState => ({
      ...prevState,
      status: newStatus
    }))

    setModalData({
      title: 'User details updated',
      desc: `Well done , you have successfully ${
        newStatus === 'active' ? 'activated' : 'deactivated'
      } the User`,
      type: 'success',
      link: '/admin-users'
    })
    document.getElementById('my_modal_5').showModal()
  }

  return (
    <section className='newUserCtn'>
      <div className='mb-[15px] lg:mb-[30px]'>
        <a href='/admin-users'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2'>
        <h1 className='lg:text-3xl text-xl text-black'>User Details</h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Edit user details asides email
        </p>
      </div>
      <form className='newuserform' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-[60px]'>
          <TextInput
            name='firstName'
            register={register}
            errors={errors}
            text='First Name'
          />
          <TextInput
            name='lastName'
            register={register}
            errors={errors}
            text='Last Name'
          />
        </div>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-[60px]'>
          <div className='w-full flex flex-col rounded-lg gap-1.5 bg-[#F3F5F6] p-5'>
            <p className='text-black text-[14px] font-[400]'>
              {userData?.email || 'Loading...'}
            </p>
          </div>
          <TextInput
            name='phoneNumber'
            register={register}
            errors={errors}
            text='Phone Number'
          />
        </div>
        <div className='w-full lg:w-[70%] flex items-center flex-col lg:flex-row gap-[60px]'>
          <SelectInput
            control={control}
            Controller={Controller}
            data={[
              { value: 'administrator', label: 'Administrator' },
              { value: 'approver', label: 'Approver' }
            ]}
            title='Role'
            name='roleInput'
            placeholder='Assign role'
          />
          <TextInput
            name='password'
            register={register}
            errors={errors}
            text='Password'
            value={''}
          />
        </div>
        <div className='flex flex-col lg:flex-row items-center gap-5'>
          <div
            onClick={handleDeactivate}
            className='w-full flex items-center justify-center lg:max-w-fit lg:py-4 cursor-pointer p-2 lg:px-5 border border-[#D92534] bg-[#FDF4F5] rounded-lg'
          >
            <p className='text-[#D92534] font-[500] text-[10px] lg:text-[14px]'>
              {userData?.status === 'active' ? 'Deactivate' : 'Activate'}
            </p>
          </div>
          <button
            type='submit'
            className={`w-full lg:w-[224px] h-[30px] lg:h-[50px] ${
              isValid
                ? 'proceed-btn bg-darkGreen'
                : 'rounded-[8px] bg-darkash text-black'
            }`}
            disabled={!isValid}
          >
            Save Changes
          </button>
        </div>
      </form>
      <Modal
        type={modalData.type}
        title={modalData.title}
        desc={modalData.desc}
        link={modalData.link}
      />
    </section>
  )
}

export default UserDetails
